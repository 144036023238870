.fc-state-default {
  background-color: $white;
  background-image: none;
  background-repeat: repeat-x;
  box-shadow: none;
  color: #333333;
  text-shadow: none;
}

.fc-state-default {
  border: 1px solid;
}

.fc-button {
  color: inherit;
  border: 1px solid $white70;
  cursor: pointer;
  display: inline-block;
  height: 1.9em;
  line-height: 1.9em;
  overflow: hidden;
  padding: 0 0.6em;
  position: relative;
  white-space: nowrap;
}

.fc-state-active {
  background-color: $green;
  border-color: $green;
  color: $white;
}

.fc-header-title h2 {
  font-size: 16px;
  font-weight: 600;
  color: inherit;
}

.fc-content .fc-widget-header,
.fc-content .fc-widget-content {
  border-color: $white70;
  font-weight: normal;
}

.fc-border-separate tbody {
  background-color: #f8f8f8;
}

.fc-state-highlight {
  background: none repeat scroll 0 0 #fcf8e3;
}

.external-event {
  padding: 5px 10px;
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 5px;
}

.fc-ltr .fc-event-hori.fc-event-end,
.fc-rtl .fc-event-hori.fc-event-start {
  border-radius: 2px;
}

.fc-event,
.fc-agenda .fc-event-time,
.fc-event a {
  padding: 4px 6px;
  background-color: $green;
  border-color: $green;
}

.fc-event-time,
.fc-event-title {
  color: #717171;
  padding: 0 1px;
}

.ui-calendar .fc-event-time,
.ui-calendar .fc-event-title {
  color: $white;
}

.rdrDefinedRangesWrapper {
  background: transparent;
  color: white70;
  border-right: $darkBorder;
}

.rdrStaticRange {
  background: transparent;
  color: white30;
  border-bottom: $darkBorder;

  &:hover,
  &:focus {
    background: transparent;
    color: white70;

    .rdrStaticRangeLabel {
      background: transparent;
      color: white70;
    }
  }
}

.rdrDayNumber span {
  color: $white70;
}

.rdrMonthPicker,
.rdrYearPicker {
  select {
    border: $darkBorder;
    color: $white70;

    option {
      background-color: #292f3e;
    }
  }
}

.rdrDayPassive .rdrDayNumber span {
  color: $white30;
}

.rdrCalendarWrapper {
  color: white70;
  background: transparent;
}

.rdrDateDisplay,
.rdrDateDisplayItem,
.rdrDateDisplayItem input {
  background: transparent;
  color: $white70;
}

.rdrDateDisplayWrapper {
  background-color: transparent;
}

.rdrNextPrevButton,
.rdrInputRangeInput,
.rdrInputRangeInput:hover {
  color: $white;
  background: $white30;
}
