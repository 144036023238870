.ui-jqgrid {
  -moz-box-sizing: content-box;
}

.ui-jqgrid-btable {
  border-collapse: separate;
}

.ui-jqgrid-htable {
  border-collapse: separate;
}

.ui-jqgrid-titlebar {
  height: 40px;
  line-height: 15px;
  color: #676a6c;
  background-color: #f9f9f9;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

.ui-jqgrid .ui-jqgrid-title {
  float: left;
  margin: 1.1em 1em 0.2em;
}

.ui-jqgrid .ui-jqgrid-titlebar {
  position: relative;
  border-left: 0px solid;
  border-right: 0px solid;
  border-top: 0px solid;
}

.ui-widget-header {
  background: none;
  background-image: none;
  background-color: #f5f5f6;
  text-transform: uppercase;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.ui-jqgrid tr.ui-row-ltr td {
  border-right-color: inherit;
  border-right-style: solid;
  border-right-width: 1px;
  text-align: left;
  border-color: #dddddd;
  background-color: inherit;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background: #f9f9f9;
  border: 1px solid #dddddd;
  line-height: 15px;
  font-weight: bold;
  color: #676a6c;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

.ui-widget-content {
  box-sizing: content-box;
}

.ui-icon-triangle-1-n {
  background-position: 1px -16px;
}

.ui-jqgrid tr.ui-search-toolbar th {
  border-top-width: 0px !important;
  border-top-color: inherit !important;
  border-top-style: ridge !important;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  background: #f5f5f5;
  border-collapse: separate;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  background: #f2fbff;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  border: 1px solid #dddddd;
  background: $white;
  font-weight: normal;
  color: #212121;
}

.ui-jqgrid .ui-pg-selbox {
  display: block;
  font-size: 1em;
  height: 25px;
  line-height: 18px;
  margin: 0;
  width: auto;
}

.ui-jqgrid .ui-pager-control {
  position: relative;
}

.ui-jqgrid .ui-jqgrid-pager {
  height: 32px;
  position: relative;
}

.ui-pg-table .navtable .ui-corner-all {
  border-radius: 0px;
}

.ui-jqgrid .ui-pg-button:hover {
  padding: 1px;
  border: 0px;
}

.ui-jqgrid .loading {
  position: absolute;
  top: 45%;
  left: 45%;
  width: auto;
  height: auto;
  z-index: 101;
  padding: 6px;
  margin: 5px;
  text-align: center;
  font-weight: bold;
  display: none;
  border-width: 2px !important;
  font-size: 11px;
}

.ui-jqgrid-pager {
  height: 32px;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  border-top-left-radius: 0;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  border-top-right-radius: 0;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  border-bottom-left-radius: 0;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-bottom-right-radius: 0;
}

.ui-widget-content {
  border: 1px solid #ddd;
}

.ui-jqgrid .ui-jqgrid-titlebar {
  padding: 0;
}

.ui-jqgrid .ui-jqgrid-titlebar {
  border-bottom: 1px solid #ddd;
}

.ui-jqgrid tr.jqgrow td {
  padding: 6px;
}

.ui-jqdialog .ui-jqdialog-titlebar {
  padding: 10px 10px;
}

.ui-jqdialog .ui-jqdialog-title {
  float: none !important;
}

.ui-jqdialog > .ui-resizable-se {
  position: absolute;
}

.gridStyle {
  border: 1px solid #d4d4d4;
  width: 100%;
  height: 400px;
}

.gridStyle2 {
  border: 1px solid #d4d4d4;
  width: 500px;
  height: 300px;
}

.ngHeaderCell {
  border-right: none;
  border-bottom: 1px solid $white70;
}

.ngCell {
  border-right: none;
}

.ngTopPanel {
  background: #f5f5f6;
}

.ngRow.even {
  background: #f9f9f9;
}

.ngRow.selected {
  background: #ebf2f1;
}

.ngRow {
  border-bottom: 1px solid $white70;
}

.ngCell {
  background-color: transparent;
}

.ngHeaderCell {
  border-right: none;
}
