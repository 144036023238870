.customer-details {
  &__notarizations {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    justify-content: center;
    padding: 1rem;
    padding-bottom: 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;

    &__header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &__title {
        text-transform: uppercase;
        flex-grow: 1;
        margin-bottom: 0;
        padding-right: $kbaseRem;
      }

      .button {
        margin-top: -25px;
        margin-right: -5px;

        &__text {
          .loader {
            max-height: 100%;
          }
        }
      }
    }

    &__item {
      width: 100%;
    }

    &__block {
      padding-top: 3px;
      display: inline-block;

      &-title {
        padding: 0 10px;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  &__notarization-settings {
    &__item {
      border-bottom: 1px solid rgb(83, 89, 104);
      margin: 0px 15px;
      margin-bottom: 10px;
      display: flex;

      &__checkbox-column {
        padding-left: 0;
        width: 10%;
      }
      &__name {
        width: 20%;
      }
      &__description {
        color: rgba(255, 255, 255, 0.5);
        width: 40%;
      }
    }
  }
}
