@use "sass:math";

&__text {
  display: flex;
  align-items: center;
  position: relative;
  font-weight: inherit;
  border-radius: $borderRadius;
  border: 1px solid;
  border-color: $white10;

  &:hover {
    border-color: $white30;
    transition: border-color 0.125s ease-in-out;
  }

  &:focus-within {
    border-color: $blue;
    transition: border-color 0.125s ease-in-out;
  }

  &--disabled {
    background-color: $white10;
    color: $white30;
    &:hover {
      border-color: $white10;
    }
    .input__text__label {
      color: $white30;
    }
  }

  &__label {
    font-size: math.div($kbaseRem, 1.2);
    text-transform: capitalize;
    line-height: 1em;
    color: $white30;
    display: block;
    position: absolute;
    left: $kbaseRem * 0.5;
    top: 50%;
    pointer-events: none;
    transform: translateY(-50%);
    transition: font-size 0.125s ease-in-out, color 0.125s ease-in-out,
      top 0.125s ease-in-out;

    &--required {
      color: $blue;
    }
  }

  &__input {
    font-size: math.div($kbaseRem, 1.2);
    color: $white;
    background: transparent;
    border: none;
    height: $kbaseRem * 2.25;
    width: 100%;
    padding: $kbaseRem $kbaseRem * 0.5 math.div($kbaseRem, 3);
    font-weight: inherit;
    appearance: none;
    outline: none;

    &:focus ~ .input__text__label,
    &:not(:placeholder-shown) ~ .input__text__label {
      color: $white70;
      // px so that it can be calculated correctly
      font-size: 12px;
      top: calc((#{math.div($kbaseRem, 3)}) + 6px);
    }

    &:disabled {
      color: $white30;
      ~ .input__text__label {
        color: $white30;
      }
    }

    &--inValid {
      color: $white30;
    }
  }
}
