.dark-background {
  background-image: $darkGradient;
}

.navy-bg {
  background-color: $darkThree;
  color: $white;
}

.blue-bg {
  background-color: $blue;
  color: $white;
}

.lazur-bg {
  background-color: #23c6c8;
  color: $white;
}

.yellow-bg {
  background-color: $orange;
  color: $white;
}

.red-bg {
  background-color: $red;
  color: $white;
}

.black-bg {
  background-color: $black;
}

.panel-primary {
  border-color: $green;
}

.panel-primary>.panel-heading {
  background-color: $green;
  border-color: $green;
}

.panel-success {
  border-color: $blue;
}

.panel-success>.panel-heading {
  background-color: $blue;
  border-color: $blue;
  color: $white;
}

.panel-info {
  border-color: #23c6c8;
}

.panel-info>.panel-heading {
  background-color: #23c6c8;
  border-color: #23c6c8;
  color: $white;
}

.panel-warning {
  border-color: $orange;
}

.panel-warning>.panel-heading {
  background-color: $orange;
  border-color: $orange;
  color: $white;
}

.panel-danger {
  border-color: $red;
}

.panel-danger>.panel-heading {
  background-color: $red;
  border-color: $red;
  color: $white;
}

.panel-default>.panel-heading {
  color: $white70;
  padding: $kbaseRem*0.5;
}

.progress {
  padding: 0;
  height: 20px;
}

.progress-bar {
  background-color: $green !important;
}

.progress-small,
.progress-small .progress-bar {
  height: 10px;
}

.progress-small,
.progress-mini {
  margin-top: 5px;
}

.progress-mini,
.progress-mini .progress-bar {
  height: 5px;
  margin-bottom: 0px;
}

.progress-bar {
  &.navy-light {
    background-color: #3dc7ab !important;
  }
}

.progress-bar {
  &.bg-success {
    background-color: $blue !important;
  }
}

.progress-bar {
  &.bg-info {
    background-color: #23c6c8 !important;
  }
}

.progress-bar {
  &.bg-warning {
    background-color: $orange !important;
  }
}

.progress-bar {
  &.bg-danger {
    background-color: $red !important;
  }
}

.panel-title {
  font-size: inherit;

  @at-root #{&}--with-arrow {
    &.collapsed {
      .icon {
        will-change: transform;
        transform: rotate(0);
        transition: transform 0.225s ease-out;
      }
    }

    .icon {
      transform: rotate(180deg);
      transition: transform 0.225s ease-out;
    }
  }
}

.jumbotron {
  border-radius: 6px;
  padding: 40px;
}

.jumbotron h1 {
  margin-top: 0;
}