.product-box {
  padding: 0;
  border: 1px solid $white70;
}

.product-box:hover,
.product-box.active {
  border: 1px solid transparent;
  box-shadow: $blockShadow;
}

.product-imitation {
  text-align: center;
  padding: 90px 0;
  background-color: #f8f8f9;
  color: #bebec3;
  font-weight: 600;
}

.product-imitation.xl {
  padding: 120px 0;
}

.product-desc {
  padding: 20px;
  position: relative;
}

.ecommerce .tag-list {
  padding: 0;
}

.ecommerce .fa-star {
  color: #d1dade;
}

.ecommerce .fa-star.active {
  color: $orange;
}

.ecommerce .note-editor {
  border: 1px solid $white70;
}

.product-name {
  font-size: 16px;
  font-weight: 600;
  color: #676a6c;
  display: block;
  margin: 2px 0 5px 0;
}

.product-name:hover,
.product-name:focus {
  color: $green;
}

.product-price {
  font-size: 14px;
  font-weight: 600;
  color: $white;
  background-color: $green;
  padding: 6px 12px;
  position: absolute;
  top: -32px;
  right: 0;
}

.product-detail .ibox-content {
  padding: 30px 30px 50px 30px;
}

.image-imitation {
  background-color: #f8f8f9;
  text-align: center;
  padding: 200px 0;
}

.product-main-price small {
  font-size: 10px;
}

.product-images {
  margin: 0 20px;
}
