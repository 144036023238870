.floatLeft {
    float: left;
}
.floatRight {
    float: right;
}

.textCenter {
    text-align: center;
}
.textEnd {
    text-align: end;
}
.textStart {
    text-align: start;
}
.textLeft {
    text-align: left;
}
.textRight {
    text-align: right;
}
  
//
.margin0 {
    margin: 0;
}
.marginLeft0 {
    margin-left: 0;
}
.marginLeft5 {
    margin-left: 5px;
}
.marginLeft10 {
    margin-left: 10px;
}
.marginLeft15 {
    margin-left: 15px;
}


.marginRight0 {
    margin-right: 0;
}
.marginRight5 {
    margin-right: 5px;
}
.marginRight10 {
    margin-right: 10px;
}
.marginRight15 {
    margin-right: 15px;
}

.margin5 {
    margin: 5px;
}
.marginTB5 {
    margin: 5px 0;
}
.marginLR5 {
    margin: 0 5px;
}

.margin10 {
    margin: 10px;
}
.marginTB10 {
    margin: 10px 0;
}
.marginLR10 {
    margin: 0 10px;
}



.marginTop0 {
    margin-top: 0;
}
.marginTop5 {
    margin-top: 5px;
}
.marginTop10 {
    margin-top: 10px;
}
.marginTop15 {
    margin-top: 15px;
}
.marginTop20 {
    margin-top: 20px;
}
.marginTop30 {
    margin-top: 30px;
}
.marginTop40 {
    margin-top: 40px;
}

.marginBottom0 {
    margin-bottom: 0;
}
.marginBottom5 {
    margin-bottom: 5px;
}
.marginBottom10 {
    margin-bottom: 10px;
}
.marginBottom15 {
    margin-bottom: 15px;
}
.marginBottom20 {
    margin-bottom: 20px;
}
.marginBottom30 {
    margin-bottom: 30px;
}
.marginBottom40 {
    margin-bottom: 40px;
}





//
.padding0 {
    padding: 0
  }
.paddingLeft0 {
    padding-left: 0;
}
.paddingRight0 {
    padding-right: 0;
}
.paddingLeft5 {
    padding-left: 5px;
}
.paddingRight5 {
    padding-right: 5px;
}
.paddingLeft10 {
    padding-left: 10px;
}
.paddingRight10 {
    padding-right: 10px;
}

.padding5 {
    padding: 5px;
}
.paddingTB5 {
    padding: 5px 0;
}
.paddingLR5 {
    padding: 0 5px;
}

.padding10 {
    padding: 10px;
}
.paddingTB10 {
    padding: 10px 0;
}
.paddingLR10 {
    padding: 0 10px;
}

.paddingTop0 {
    padding-top: 0px;
}
.paddingTop5 {
    padding-top: 5px;
}
.paddingTop10 {
    padding-top: 10px;
}
.paddingTop20 {
    padding-top: 20px;
}
.paddingTop30 {
    padding-top: 30px;
}
.paddingTop40 {
    padding-top: 40px;
}
.paddingBottom0 {
    padding-bottom: 0px;
}
.paddingBottom5 {
    padding-bottom: 5px;
}
.paddingBottom10 {
    padding-bottom: 10px;
}
.paddingBottom20 {
    padding-bottom: 20px;
}
.paddingBottom30 {
    padding-bottom: 30px;
}
.paddingBottom40 {
    padding-bottom: 40px;
}
.padding2010 {
    padding: 20px 10px;
}

//Random Bits
.cursorPointer {
    cursor: pointer;
}

//text
.capitalize {
    text-transform: capitalize;
}
.lowercase {
    text-transform: lowercase;
}
.uppercase {
    text-transform: uppercase;
}
