.table-wrapper {
  overflow: scroll;
}

.table {
  background: transparent;
  color: $white70;
  padding: 0;
  border: none;

  thead,
  tbody,
  tfoot {
    background: inherit;
    padding: inherit;
    border: inherit;
    margin: inherit;

    tr {
      background: inherit !important;
      border-bottom: 1px solid rgba($white, 0.1);
      padding: inherit;

      &.selected{
        background: $darkTwo !important;
      }

      &:last-child {
        border: none;
      }

      th {
        color: $white40;
        font-weight: 100;
      }

      th,
      td {
        background: inherit;
        border: none;
        vertical-align: top;

        .button {
          margin: $kbaseRem*0.25;
        }

        .button--small {
          margin: $kbaseRem*0.125 $kbaseRem*0.25;
        }
      }
    }

    td {
      color: $white70;
    }
  }

  thead {
    border-bottom: 1px solid rgba($white, 0.1);

    tr th {
      font-weight: 500;
      color: $white;
    }
  }

  tfoot {
    border-top: $darkBorder;
    tr {
      border: none;
    }
  }

  @at-root #{&}--compressed{
    tbody, thead, tfoot {
      tr {
        td, th{
          padding: $kbaseRem*0.5;
        }
      }
    }
  }

  @at-root #{&}--no-borders {
    tbody{
      tr{
        border-bottom: none;
      }
    }
  }

  @at-root #{&}--hover {
    tbody {
      tr:hover{
        &.clickable-row {
          cursor: pointer;
        }

        td{
          background: $darkTwo;
        }

        .clickable-td:hover {
          cursor: pointer;
        }
      }
    }
  }

  @at-root #{&}--striped {
    tbody{
      tr:nth-child(even) {
        td {
          background: $dark;
        }
      }
    }
  }
}

.table--wide {
  min-width: 1120px;
  overflow: auto;

  @media screen and (min-width: 1279px) {
    min-width: 100%;
  }
}

.table--striped,
.table--hover {
  tr {
    th:first-child,
    td:first-child {
      padding-left: $kbaseRem*0.5;
    }

    th:last-child,
    td:last-child {
      padding-right: $kbaseRem*0.5;
    }
  }
}
