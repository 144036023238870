@import './autofill';

.input {
  line-height: 1rem;
  position: relative;
  margin-bottom: $kbaseRem*0.5;

  // Bootstrap Reset
  label {
    margin-bottom: 0;
    font: inherit;
    font-weight: initial;
    font-size: 100%;
    max-width: initial;
    vertical-align: middle;
  }

  input[type='radio'],
  input[type='checkbox'] {
    margin: 0;
    margin-top: 0;
    line-height: inherit;
  }

  @at-root #{&}--no-margin {
    margin-bottom: 0;
  }

  @import './text';

  &__top-margin {
    margin-top: 2rem;
  }

  &__tel {
    @extend .input__text;
  }

  &__email {
    @extend .input__text;
  }

  &__password {
    @extend .input__text;
  }

  &__number {
    @extend .input__text;

    &--disabled {
      background-color: $white10;
      color: $white30;
      &:hover {
        border-color: $white10;
      }
      .input__text__label {
        color: $white30;
      }
    }
  }

  @import './file';
  @import './checkbox';
  @import './radio';
  @import './textarea';
  @import './search';
}

.fa-eye {
  margin: auto 10px;
  cursor: pointer;
  color: #6fc6d9;
}

.fa-eye-slash {
  margin: auto 10px;
  cursor: pointer;
}

@import './other';
