@mixin sans() {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
}

@mixin console() {
  font-family: Monaco, 'Courier New', Courier, monospace;
}

// Custom Font Stacks
@mixin fontfamily1 {
  @include sans();

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

// Button Color
@mixin buttonColor($selectedColor) {

  // Check if color exists, else output warning
  @if map-has-key($buttonColors, $selectedColor) {
    $selectedColorObj: map-get($buttonColors, $selectedColor);

    // base colors
    color: map-get($selectedColorObj, 'color');
    border-color: map-get($selectedColorObj, 'color');

    .icon {
      color: map-get($selectedColorObj, 'color');

      .icon--fill {
        fill: map-get($selectedColorObj, 'color');
      }
    }

    // hover colors
    &:hover,
    &:active,
    &.hover,
    &.active {
      border-color: transparent;
      color: map-get($selectedColorObj, 'hoverColor');
      background-image: map-get($selectedColorObj, 'hoverGradient');

      .icon {
        color: map-get($selectedColorObj, 'hoverColor');

        .icon--fill {
          fill: map-get($selectedColorObj, 'hoverColor');
        }
      }
    }
  }

  @else {
    @warn "Unfortunately, `#{$selectedColor}` could not be retrieved from `$buttonColors` map. "
    + "Please make sure it is defined.";
  }
}