.dropdown-button {
  display: inline-block;
  margin: 0 $kbaseRem*0.25 $kbaseRem*0.5;
  position: relative;

  &--no-margin {
    margin-bottom: 0;
  }

  // Remove the button margin and add it to .dropdown-button
  .button {
    margin: 0;
  }

  &__list {
    cursor: pointer;
    color: $white70;
    background-color: $dark;
    margin: $kbaseRem * 0.75 0 0 0;
    border-radius: $borderRadius;
    border: $blueBorder;
    min-width: 240px;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;

    &__item {
      display: flex;
      align-items: center;
      margin: 0;
      padding: $kbaseRem * 0.75 $kbaseRem*0.5;
      border-bottom: $darkBorder;

      &:first-child {
        border-radius: $borderRadius $borderRadius 0 0;
      }

      &:last-child {
        border-bottom: none;
        border-radius: 0 0 $borderRadius $borderRadius;
      }

      &__arrow {
        margin-left: auto;
        transition: transform 0.225s ease-out;

        &--open {
          transform: rotate(90deg);
        }
      }

      &:hover {
        background-color: $darkTwo;
        color: $blue;

        .icon {
          color: $blue;

          .icon--fill {
            fill: $blue;
          }
        }
      }

      &--alert {
        color: $red;

        &.icon {
          color: $red;

          &.icon--fill {
            fill: $red;
          }
        }

        &:hover {
          background-color: $darkTwo;
          color: $red;

          .icon {
            color: $blue;

            .icon--fill {
              fill: $red;
            }
          }
        }
      }
    }

    &__sub-menu {
      visibility: hidden;
      overflow: hidden;
      color: $white70;
      background-color: $darkTwo;
      border-bottom: none;
      padding: 0;
      margin: 0;
      max-height: 0px;
      transition: max-height 0.225s ease-out, visibility 0s linear 0.225s;

      &:last-child {
        border-radius: 0 0 $borderRadius $borderRadius;
      }

      &--open {
        visibility: visible;
        height: auto;
        border-bottom: $darkBorder;
        max-height: 200px;
        transition: max-height 0.225s ease-out, visibility 0s linear;
      }

      &__item {
        padding: $kbaseRem * 0.75 $kbaseRem*0.5;

        &:hover {
          background: $darkFour;
          color: $blue;

          .icon {
            color: $blue;

            .icon--fill {
              fill: $blue;
            }
          }
        }
      }
    }
  }
}

.dropdown {
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
}