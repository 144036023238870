.mail-box {
  background-color: $white;
  border: 1px solid $white70;
  border-top: 0;
  padding: 0px;
  margin-bottom: 20px;
}

.mail-box-header {
  background-color: $white;
  border: 1px solid $white70;
  border-bottom: 0;
  padding: 30px 20px 20px 20px;
}

.mail-box-header h2 {
  margin-top: 0px;
}

.mailbox-content .tag-list li a {
  background: $white;
}

.mail-body {
  border-top: 1px solid $white70;
  padding: 20px;
}

.mail-text {
  border-top: 1px solid $white70;
}

.mail-text .note-toolbar {
  padding: 10px 15px;
}

.mail-body .form-group {
  margin-bottom: 5px;
}

.mail-text .note-editor .note-toolbar {
  background-color: #f9f8f8;
}

.mail-attachment {
  border-top: 1px solid $white70;
  padding: 20px;
  font-size: 12px;
}

.mailbox-content {
  background: none;
  border: none;
  padding: 10px;
}

.mail-ontact {
  width: 23%;
}
