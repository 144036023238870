.calendar {
  background: transparent;
  color: $white70;

  .task-header,
  .task-list {
    display: table;

    background: inherit;
    padding: inherit;
    border: none;
    margin: inherit;

    min-width: 100%;
  }

  .header-row,
  .table-row {
    display: table-row;
    list-style: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    background: $dark;
    border: $darkBorder;
  }

  .table-row:nth-child(even) {
    background: $darkTwo;
  }

  .header-item {
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    align-items: center;
    height: 50px;
    border: $darkBorder;
  }

  .cell {
    display: table-cell;
    vertical-align: middle;
    cursor: pointer;

    align-items: center;
    height: 40px;
    border: $darkBorder;

    .name-wrapper {
      display: block;
      padding-left: 10%;
      padding-right: 2px;
      align-items: center;
      
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &--muted {
        color: $white60;
        font-size: 13px;
      }
    }
  }

  .expander {
    color: rgb(86 86 86);
    font-size: 0.6rem;
    padding: 0.15rem 0.2rem 0rem 0.2rem;
    user-select: none;
    cursor: pointer;
  }

  .empty-expander {
    font-size: 0.6rem;
    padding-left: 1rem;
    user-select: none;
  }

  .task-header {
    text-align: center;

    div {
      font-weight: 500;
      color: $white;
    }
  }

}