.chat {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;

  @at-root #{&}__heading {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media screen and (min-width: $tablet) {
      flex-flow: row nowrap;
    }

    @at-root #{&}--title {
      width: 100%;

      @media screen and (min-width: $tablet) {
        width: 68%;
      }
    }

    @at-root #{&}--search {
      width: 100%;

      @media screen and (min-width: $tablet) {
        width: 28%;
      }
    }
  }

  @at-root #{&}__container {
    padding: $kbaseRem*0.5;
    box-shadow: $blockShadow;
    display: inline-block;
    width: 100%;
    max-height: 75vh;
    border-radius: 4px;
    position: relative;

    @media screen and (min-width: $tablet) {
      width: 68%;
    }
  }

  @at-root #{&}__discussion {
    overflow: hidden;
    overflow-y: auto;
    height: 73vh;
    padding-bottom: $kbaseRem*3.5;

    .load-more-messages {
      margin: 0 auto $kbaseRem;
      display: block;
    }

    @at-root #{&}--no-chat {
      width: 100%;
      display: block;
      overflow: hidden;
      text-align: center;
      position: absolute;
      top: 50%;
    }
  }

  @at-root #{&}__message {
    display: inline-block;
    width: 70%;
    border-radius: 4px;
    padding: $kbaseRem $kbaseRem*7 $kbaseRem $kbaseRem;
    margin: 0 0 $kbaseRem;
    position: relative;
    hyphens: auto;
    word-break: break-word;

    @at-root #{&}--received {
      margin-left: 10%;
      background: $darkTwo;
      color: $white70;
    }

    @at-root #{&}--sent {
      margin-left: 25%;
      background: $white70;
      color: $dark;
    }

    @at-root #{&}--with-user {
      margin-top: $kbaseRem*1.5;
    }

    @at-root #{&}--sender {
      position: absolute;
      left: -4rem;
      top: -$kbaseRem*1.25;
      display: flex;
    }

    @at-root #{&}--sender-avatar {
      width: $kbaseRem*2.5;
      height: $kbaseRem*2.5;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-around;

      img {
        max-width: 100%;
      }

      .icon {
        width: 100%;
        height: 100%;
        background: $darkTwo;
      }
    }

    @at-root #{&}--sender-name {
      font-weight: 600;
      margin-left: $kbaseRem*0.5;
      color: $white;
    }

    @at-root #{&}--date {
      position: absolute;
      right: $kbaseRem;
      top: $kbaseRem;
    }

    @at-root #{&}--attchment {
      max-width: 100%;
      margin: 1rem 0;
    }
  }

  @at-root #{&}__form {
    width: 100%;
    display: inline-block;
    overflow: visible;
    padding: $kbaseRem*0.5;
    border-top: 1px solid $white10;
    background: $dark;
    position: absolute;
    bottom: 0;
    left: 0;

    @at-root #{&}--input-group {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .input {
        margin: 0 $kbaseRem*0.25;
      }

      .button {
        min-width: $kbaseRem*2.5;
        height: $kbaseRem*2.5;
        margin-bottom: 0;
      }

      &.open .actions-menu {
        display: block;
      }

      .actions-menu {
        position: absolute;
        display: none;
        overflow: hidden;
        bottom: 120%;
        right: $kbaseRem*0.25;
        list-style-type: none;
        padding: $kbaseRem;
        background: $darkTwo;
        border-radius: 4px;
        box-shadow: $blockShadow;
        margin: 0;
        text-align: right;

        .item {
          border-bottom: $darkBorder;

          &:last-child {
            border: none;
            margin: 0;
            padding: 0;
          }

          .link {
            width: 100%;
            color: $white70;
            cursor: pointer;

            &:hover {
              color: $white;
            }
          }
        }
      }
    }
  }

  @at-root #{&}__users {
    width: 100%;
    display: inline-block;
    max-height: 75vh;
    overflow: hidden;
    overflow-y: auto;
    padding: $kbaseRem*0.5;
    box-shadow: $blockShadow;
    border-radius: 4px;

    @media screen and (min-width: $tablet) {
      width: 28%;
    }

    @at-root #{&}--list {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }
  }

  @at-root #{&}__user {
    margin: 0;
    padding: $kbaseRem*0.5 $kbaseRem*0.5;
    cursor: pointer;
    border-bottom: $darkBorder;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 4px;

    &:last-child {
      border: none;
      margin-bottom: 0;
    }

    &:hover {
      background: $dark50;
    }

    .icon__custom--user {
      width: $kbaseRem*3.5;
      height: $kbaseRem*3.5;
      background: $darkTwo;
      border-radius: 50%;
      margin: $kbaseRem*0.5 $kbaseRem*0.5 0 0;


      svg {
        width: $kbaseRem*3.5;
        height: $kbaseRem*3.5;
      }
    }

    @at-root #{&}--avatar {
      width: $kbaseRem*3.5;
      height: $kbaseRem*3.5;
      border-radius: 50%;
      margin: $kbaseRem*0.5 $kbaseRem*0.5 0 0;
      object-fit: cover;

      .icon {
        width: 100%;
        height: 100%;
        background: $darkTwo;
      }
    }

    @at-root #{&}--container {
      flex: 1;
      max-width: calc(100% - 5.25rem);
    }

    @at-root #{&}--labels {
      display: inline-block;
      float: right;
    }

    @at-root #{&}--name {
      display: inline-block;
      font-weight: 500;
      margin: $kbaseRem*0.25;
      text-transform: capitalize;
      margin-left: 0;
    }

    @at-root #{&}__last-response {
      border-radius: 4px;
      padding: $kbaseRem*0.5;
      display: flex;
      align-items: flex-start;
      max-width: 100%;
      overflow: hidden;
      background: $darkTwo;
      color: $white70;

      @at-root #{&}--message {
        flex: 1;
        font-style: italic;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        margin-right: 10px;
        line-height: $body-line-height;
      }

      @at-root #{&}--date {
        line-height: 15px;
        color: $white30;
      }
    }

    .online-status {

    }
  }
}
