html {
  @include khtml(0); // 14px
}

body {
  @include kbody();
  @include fontfamily1();

  color: $white70;
}

/* Typography */
%heading {
  @include fontfamily1();

  color: inherit;
  position: relative;
  display: inline-block;
}

h1 {
  @extend %heading;
  @include ktype(2, 0, 1, -1);

  font-weight: 300;

  @media screen and (min-width: $tablet) {
    @include ktype(3, 0, 1);
  }
}

h2 {
  @extend %heading;
  @include ktype(1, 0, 1, 1);

  font-weight: normal;

  @media screen and (min-width: $tablet) {
    @include ktype(2, 0, 1);
  }
}

h3 {
  @extend %heading;
  @include ktype(1, 0, 1, 1);

  font-weight: 500;
  text-transform: uppercase;
}

h4,
h5,
h6 {
  @extend %heading;
  @include ktype(0, 0, 1, 0.5);

  font-weight: 500;
  text-transform: uppercase;
}

p,
ul,
ol,
dl {
  @include ktype(0, 0, 1);

  color: inherit;
  font-weight: 300;
  word-break: break-all;

  &.dl-horizontal {
    display: grid;
    grid-template-columns: 50% 50%;

    dt {
      text-align: right;
      padding-right: 20px
    }
  }
}

small {
  @include ktype(-1, 0, 0);

  display: inline-block;
  font-weight: 300;
}

li {
  @include ktype(0, 0, 0, 1);
}

blockquote {
  @include ktype(1, 0, 1, 1);

  color: inherit;
  margin: 0 $kbaseRem;
  padding: $kbaseRem;
  font-style: italic;
  border-left: 2px solid $white30;
  font-weight: 300;

  cite {
    @include ktype(-1, 0, 1, 1);

    display: block;
    text-align: right;
    font-weight: 300;
  }
}

a {
  color: $blue;
  text-decoration: none;
  outline: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: 0;
    color: $white;
    outline: none;
  }
}

.text-orange {
  td {
    color: $orange !important;
  }
}