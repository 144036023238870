@use "sass:math";

.MuiDialog-root {
  z-index: 3000 !important;
}

.form-input__datepicker {
  position: relative;

  .form-input__calendar,
  .MuiFormControl-root {

    .form-control {
      color: inherit;
      background: transparent;
      border-radius: 4px;
      border: 1px solid;
      border-color: $white10;
      height: $kbaseRem*2.25;
      width: 100%;
      padding: $kbaseRem*0.25;
      font-weight: inherit;
      appearance: none;
      outline: none;
      transition: padding 0.125s linear, border-color 0.125s linear;

      &:disabled{
        background-color: $white10;
        color: $white30;
      }

      &:hover{
        border-color: $white30;
        transition: padding 0.125s linear, border-color 0.125s linear;
      }

      &:focus {
        padding: $kbaseRem $kbaseRem*0.25 math.div($kbaseRem, 3);
        border-color: $blue;
        transition: padding 0.125s linear, border-color 0.125s linear;
      }

      &:valid {
        padding: $kbaseRem $kbaseRem*0.25 math.div($kbaseRem, 3);
      }
    }
  }

  .form-input__label {
    @include ktype(-1, 0, 0);

    color: $white70;
    display: block;
    position: absolute;
    left: 5px;
    top: 4px;
    font-weight: inherit;
    pointer-events: none;
  }

  .icon {
    position: absolute;
    right: $kbaseRem*0.25;
    top: $kbaseRem*0.5;
    pointer-events: none;
  }
}

// UI Datepicker override
.form-input__datepicker {
  position: relative;
  margin-bottom: $kbaseRem*0.5;

  .MuiFormControl-root.form-input__calendar {
    color: inherit;
    background: transparent;
    border-radius: 4px;
    border: 1px solid;
    border-color: $white10;
    height: $kbaseRem*2.25;
    width: 100%;
    padding: $kbaseRem*0.25;
    font-weight: inherit;
    appearance: none;
    outline: none;
    transition: padding 0.125s linear, border-color 0.125s linear;
    position: relative;

    .MuiInputLabel-root {
      display: block;
      left: $kbaseRem*0.25;
      top: -25%;
      will-change: color;
      transition: color 0.125s linear;
      color: $white70;

      &.MuiInputLabel-shrink {
        top: math.div($kbaseRem, 6);
      }
    }

    .MuiInput-root {
      @include ktype(0, 0, 0, 0);

      color: $white;
      padding: $kbaseRem*0.66 0 math.div($kbaseRem, 3);
    }

    .MuiInput-underline::before,
    .MuiInput-underline::after {
      display: none;
    }

    .MuiInputBase-input {
      height: initial;
      padding: 0;
    }
  }

  .icon {
    position: absolute;
    right: $kbaseRem*0.25;
    top: $kbaseRem*0.5;
    pointer-events: none;
  }
}

// Calendar popup
.MuiPaper-root.MuiPopover-paper,
.MuiPaper-root.MuiDialog-paper {
  color: $white;
  background-color: $dark;
  border: $blueBorder;

  .MuiPickersModal-dialog {
    overflow-y: auto;
  }

  .MuiTypography-root {
    text-transform: uppercase;
  }

  .MuiPickersToolbar-toolbar {
    background-color: $dark;
    height: calculateRem(60px);
    padding: 0 $kbaseRem*0.5;
    border-radius: 4px;

    .MuiPickersToolbarButton-toolbarBtn {
      padding: 0 math.div($kbaseRem, 3);
    }

    .MuiButton-root:hover {
      background-color: rgba($blue, 0.5);
    }
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .MuiPickerDTTabs-tabs {
    background-color: $dark;
    border-bottom: $darkBorder;

    .MuiTabs-indicator {
      background-color: $blue;
    }
  }

  .MuiPickersYear-yearSelected {
    color: $blue;
  }

  .MuiPickersCalendarHeader-switchHeader {
    border-bottom: $darkBorder;
  }

  .MuiIconButton-root {
    color: $white;

    &:hover {
      color: $blue;
    }


    &.Mui-disabled {
      color: $white30;
      pointer-events: none;
    }

    > .MuiIconButton-label,
    &.MuiPickersCalendarHeader-iconButton,
    > .MuiTouchRipple-root {
      background-color: transparent;
    }
  }

  .MuiPickersCalendarHeader-dayLabel {
    color: $white70;
  }

  .MuiPickersDay-dayDisabled {
    color: $white50;
  }

  .MuiPickersDay-hidden {
    opacity: 1;
    color: $white30;
  }

  .MuiPickersDay-current {
    border: 1px solid $white;
    background-color: transparent;
  }

  .MuiPickersDay-day {
    border-radius: 4px;

    &:hover,
    &.MuiPickersDay-daySelected {
      border: $blueBorder;
      background-color: transparent;
      background-image: $blueGradient;
      color: $white;
    }
  }

  .MuiDialogActions-root {
    border-top: $darkBorder;

    .MuiButton-root {
      color: $blue;
      background-color: transparent;

      &:hover {
        color: $white;
        background-color: transparent;
      }
    }
  }

  .MuiPickersClock-clock {
    background-color: $white10;

    .MuiPickersClock-pin,
    .MuiPickersClockPointer-pointer,
    .MuiPickersClockPointer-noPoint,
    .MuiPickersClockPointer-thumb {
      background-color: $blue;
    }

    .MuiPickersClockPointer-noPoint,
    .MuiPickersClockPointer-thumb {
      border-color: $blue;
    }

    .MuiTypography-body1.MuiPickersClockNumber-clockNumber {
      color: $white;
    }

    .MuiTypography-body2.MuiPickersClockNumber-clockNumber {
      color: $white70;
    }
  }
}
