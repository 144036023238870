.driver-approval {
  width: 80%;
  max-width: 460px;
  position: relative;
  margin: 0 auto;
  margin-top: $kbaseRem*3;
  padding-top: $kbaseRem*3;

  @media screen and (min-width: $mobilePlus) {
    padding-top: $kbaseRem*5;
  }

  p {
    font-size: 18px;
    text-align: center;
    margin: $kbaseRem 0;

    @media screen and (min-width: $mobilePlus) {
      font-size: 24px;
      margin: $kbaseRem*2 0;
    }

    &:first-of-type{
      margin: $kbaseRem 0;
    }
  }

  @at-root #{&}__title {
    width: 100%;
    border-bottom: 1px solid $white10;
    padding: 0 $kbaseRem;
    padding-bottom: $kbaseRem*1.5;

    @media screen and (min-width: $mobilePlus) {
      padding-bottom: $kbaseRem*2;
    }
  }

  @at-root #{&}__logo {
    width: 80px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -24px;

    @media screen and (min-width: $mobilePlus) {
      width: 100px;
      top: -30px;
    }
  }
}
