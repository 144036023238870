.onoffswitch {
  position: relative;
  width: 64px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid $green;
  border-radius: 2px;
}

.onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner::before,
.onoffswitch-inner::after {
  float: left;
  width: 50%;
  height: 20px;
  padding: 0;
  line-height: 20px;
  font-size: 12px;
  color: $white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-inner::before {
  content: 'ON';
  padding-left: 10px;
  background-color: $green;
  color: $white;
}

.onoffswitch-inner::after {
  content: 'OFF';
  padding-right: 10px;
  background-color: $white;
  color: $lightGrey;
  text-align: right;
}

.onoffswitch-switch {
  width: 20px;
  margin: 0px;
  background: $white;
  border: 2px solid $green;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 44px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

.onoffswitch {
  position: relative;
  width: 54px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid $green;
  border-radius: 3px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner::before,
.onoffswitch-inner::after {
  display: block;
  float: left;
  width: 50%;
  height: 16px;
  padding: 0;
  line-height: 16px;
  font-size: 10px;
  color: $white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-inner::before {
  content: 'ON';
  padding-left: 7px;
  background-color: $green;
  color: $white;
}

.onoffswitch-inner::after {
  content: 'OFF';
  padding-right: 7px;
  background-color: $white;
  color: #919191;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 18px;
  margin: 0px;
  background: $white;
  border: 2px solid $green;
  border-radius: 3px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 36px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

.text-label {
  font-weight: bold;
  color: rgba(128, 128, 128, 0.7);
  display: inline-block;
}

.text-label.activeLabel {
  color: $green;
  font-style: italic;
}

.switch-container {
  width: 225px;
  padding: 0;
  text-align: right;
}

.switch-container .icon.fa.active,
.switch-container .icon.fa.inactive {
  cursor: pointer;
  width: auto;
  padding: 0 7px;
}

.switch-container .active {
  color: $green;
}

.border-switch {
  border: 1px solid rgb(231, 234, 236);
  border-top: none;
  border-right: none;
  padding-left: 10px;
  border-bottom-left-radius: 10px;
  height: 37px;
}

.switch-row {
  clear: both;
  height: 20px;
}
