.file-viewer {
  &__container {
    position: relative;
    overflow: hidden;
    border: $darkBorder;
    border-radius: $borderRadius;
    margin-bottom: $kbaseRem;
  }

  &__draggable {
    cursor: move;
  }

  &__image {
    -webkit-user-drag: none;
    width: 100%;
    display: block;
    transition: rotate 0.5s;
  }

  .button {
    text-transform: none;
    font-weight: 400;
    font-size: inherit;
    margin-bottom: 0;

    .icon {
      margin-right: $kbaseRem * 0.25;

      .icon--fill {
        fill: $white;
      }
    }

    &:hover {
      .icon {
        .icon--fill {
          fill: $blue;
        }
      }
    }
  }

  &__slider {
    display: flex;
    align-items: center;

    .icon {
      width: $kbaseRem * 1.5;
      height: $kbaseRem * 1.3;
      margin: 0 $kbaseRem;
    }
  }
}
