.sandsiteContainer {
  max-height: 625px;
  overflow-y: scroll;
}

.footerSandSite {
  margin-top: 4px;
}

.ibox-content-customer-well {
  clear: both;
  padding: 5px 20px 5px 20px;
  color: inherit;
  border-color: $white70;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0px;
}

.add-well-customer {
  margin-top: -12px;
  margin-bottom: 3px;
  margin-right: -16px;
}

.demurrage-edit-list div,
.demurrage-edit-list span {
  padding: 0px;
}

.demurrage-edit-list div {
  margin-top: 5px;
}

.ibox-content-customer-well div {
  padding-left: 0px;
}

.del-new-rate {
  margin-top: 20px;
  padding-left: 0px;
}

.del-new-rate button {
  background: transparent;
  padding-left: 0px;
  color: $black;
}

.del-new-rate button:hover,
.del-table-rate:hover {
  color: #676767;
}

.del-table-rate {
  background: transparent;
  color: $black;
  margin-top: -4px;
}

.active-table-rate,
.unactive-table-rate,
.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border: none;
  margin: 12px;
  padding-bottom: 15px;
}

.no-margins {
  margin: 0 !important;
}

.sandco-item {
  border-left: 2px solid $green;
}

.busy-sandco {
  border-left: 2px solid $red;
}

.sandco-job:hover {
  text-decoration: underline;
}

.alert-locked-driver {
  background-color: #f3f3f4;
  border-color: #f1f1f1;
}

.driver-profile-photo {
  margin: auto;
  padding-top: 25px;
  padding-bottom: 10px;
  max-width: 220px;
}

.block-list-orders {
  max-height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.header-order-list {
  padding: $kbaseRem*0.5 0;
  border-bottom: $darkBorder;
  margin-right: 0px;
  margin-left: 0px;
}

.item-order-list {
  padding: $kbaseRem*0.5 0;
  border-bottom: none;
}

.item-order-list-with-bottom-border {
  padding: $kbaseRem*0.5 0;
  border-bottom: $darkBorder;
}

.item-order-list:hover {
  background: $darkTwo;
}

.driver-order-id-link {
  cursor: pointer;
}

.comment-driver-element {
  padding-bottom: 0px;
  padding-right: 10px;
}

.driver-pencil-edit {
  color: #c4c3c3;
  margin-left: 5px;
  cursor: pointer;
}

.driver-pencil-edit:hover {
  color: #a2a1a1;
}

.switch-driver {
  width: 225px;
  margin-top: -3px;
  margin-left: 15px;
  font-size: 14px;
}

.content-comments-order {
  max-height: 200px;
  overflow-y: scroll;
}

.del-comment-order {
  cursor: pointer;

  &:hover {
    color: $white30;
  }
}

.order-comments-footer {
  padding-top: 10px;
  padding-bottom: 30px;
}

.carrier-profile-photo {
  margin: auto;
  max-width: 200px;
  max-height: 160px;
}

.cluster-info-sub * {
  width: 100%;
}

.remit-wrapper {
  display: inline-flex;
  width: 100%;
  .remit-container {
    width: 100%;
  }
}

.flex-container {
  display: flex;
}

.remit-container {
  padding: 5px;
  background: $darkThree;
  border-radius: 3px;
  margin-top: 5px;
}

.no-data-tab {
  padding: 15px;
}

.districts-box-container{
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.district-item {
  border: $darkBorder;
  padding-top: 5px;
  margin: 5px 5px 0 0px;
}

.min-height-container {
  min-height: 200px;
}

.edit-info-carrier {
  margin-right: 30px;
  float: right;
  color: #a3a6ab;
  border-bottom: 1px solid #a3a6ab;
  cursor: pointer;
}

.edit-info-carrier:hover {
  color: #797a7d;
}

.del-icon {
  color: #a3a6ab;
  cursor: pointer;
}

.del-icon:hover {
  color: #797a7d;
}

.del-district {
  color: #dbdbdb;
  cursor: pointer;
}

.del-district:hover {
  color: #a3a6ab;
}

.driver-table .assigned-pending {
  > td {
    border-top: 1px solid $orange !important;
    border-bottom: 1px solid $orange !important;

    &:first-of-type {
      border-left: 1px solid $orange;
    }

    &:last-of-type {
      border-right: 2px solid $orange;
    }
  }
}

.warning-date {
  background: rgba(248, 223, 165, 0.84);
}

.danger-date {
  background: rgba(255, 185, 178, 0.83);
}

.login-page .tabs-container .nav-tabs > li > a,
.login-page .tabs-container .nav-tabs > li > a:hover,
.login-page .tabs-container .nav-tabs > .login-page li > a:focus {
  max-height: 30px;
  font-size: 11px;
  padding-top: 5px;
}
