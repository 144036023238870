$width: 40px;
$height: 40px;
$bounce_height: 60px;

body {
  position: relative;
  width: 100%;
  height: 100vh;
}

.wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text {
  color: #7a8b9f;
  display: inline-block;
  margin-left: 20px;
  font-size: 30px;
}

.bounceball {
  position: relative;
  display: inline-block;
  height: 75px;
  width: $width;
  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    width: $width;
    height: $height;
    border-radius: 50%;
    background-color: #6d839f;
    transform-origin: 50%;
    animation: bounce 500ms alternate infinite ease;
  }
}

.loadingContent {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 7px;
}

@keyframes bounce {
  0% {
    top: $bounce_height;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: $height;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
}

#app {
  height: 100%;
}

.loading {
  width: 400px;
}
