.vote-item {
  padding: 20px 25px;
  border-top: $lightBorder;
}

.vote-item:last-child {
  border-bottom: $lightBorder;
}

.vote-actions {
  float: left;
  width: 30px;
  margin-right: 15px;
  text-align: center;
}

.vote-actions a {
  color: $green;
  font-weight: 600;
}

.vote-actions {
  font-weight: 600;
}

.vote-title {
  display: block;
  color: inherit;
  font-size: 18px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 2px;
}

.vote-title:hover,
.vote-title:focus {
  color: inherit;
}

.vote-info,
.vote-title {
  margin-left: 45px;
}

.vote-info,
.vote-info a {
  color: #b4b6b8;
  font-size: 12px;
}

.vote-info a {
  margin-right: 10px;
}

.vote-info a:hover {
  color: $green;
}

.vote-icon {
  text-align: right;
  font-size: 38px;
  display: block;
  color: #e8e9ea;
}

.vote-icon.active {
  color: $green;
}

body.body-small .vote-icon {
  display: none;
}

#small-chat {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}

#small-chat .badge {
  position: absolute;
  top: -3px;
  right: -4px;
}

.open-small-chat {
  height: 38px;
  width: 38px;
  display: block;
  background: $green;
  padding: 9px 8px;
  text-align: center;
  color: $white;
  border-radius: 50%;
}

.open-small-chat:hover {
  color: $white;
  background: $green;
}

.small-chat-box {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 75px;
  background: $white;
  border: 1px solid $white70;
  width: 230px;
  height: 320px;
  border-radius: 4px;
}

.small-chat-box.ng-small-chat {
  display: block;
}

.body-small .small-chat-box {
  bottom: 70px;
  right: 20px;
}

.small-chat-box.active {
  display: block;
}

.small-chat-box .heading {
  background: #2f4050;
  padding: 8px 15px;
  font-weight: bold;
  color: $white;
}

.small-chat-box .chat-date {
  opacity: 0.6;
  font-size: 10px;
  font-weight: normal;
}

.small-chat-box .content {
  padding: 15px 15px;
}

.small-chat-box .content .author-name {
  font-weight: bold;
  margin-bottom: 3px;
  font-size: 11px;
}

.small-chat-box .content > div {
  padding-bottom: 20px;
}

.small-chat-box .content .chat-message {
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 11px;
  line-height: 14px;
  max-width: 80%;
  background: #f3f3f4;
  margin-bottom: 10px;
}

.small-chat-box .content .chat-message.active {
  background: $green;
  color: $white;
}

.small-chat-box .content .left {
  text-align: left;
  clear: both;
}

.small-chat-box .content .left .chat-message {
  float: left;
}

.small-chat-box .content .right {
  text-align: right;
  clear: both;
}

.small-chat-box .content .right .chat-message {
  float: right;
}

.small-chat-box .form-chat {
  padding: 10px 10px;
}
