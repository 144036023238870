.card {
  background: transparent;
  border: none;
  box-shadow: none;

  &.blank-panel {
    background: none;
    margin: 0;
  }

  .card-header {
    padding-bottom: 10;
    padding-left: 0;
    background: transparent;
    border: none;
    color: $white70;
  }

  .card-body {
    background-image: $darkGradient;
    color: $white70;
    padding: 15px 20px 20px;
    border-radius: 4px;
    box-shadow: $blockShadow;
    min-height: 110px;
    clear: both;
    overflow: visible;
  }
}