// some inline styles are still being set by react-select
// using & specificity forces them to be overwritten. Example: & &__label
@use "sass:math";

.input__select {
  color: $white;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-width: 80px;
  margin-bottom: $kbaseRem*0.5;

  &--no-margin {
    margin-bottom: 0;
  }

  &__input {
    font-size: math.div($kbaseRem, 1.2);
    color: $white;
    // React select still adds in inline style, used pixels to align correct place when typing
    margin-right: $kbaseRem*0.125;
    margin-bottom: math.div($kbaseRem, 3);
    position: absolute;
    bottom: 0;
    caret-color: transparent;
  }

  &__value-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
    min-height: $kbaseRem*2.25;
    height: 100%;
    padding-left: $kbaseRem*0.5;
    -webkit-overflow-scrolling: touch;
    position: relative;
    overflow: hidden;

    &--is-multi {
      padding-top: $kbaseRem;
      align-items: flex-start;
    }
  }

  &__placeholder {
    font-size: math.div($kbaseRem, 1.2);
    text-transform: capitalize;
    line-height: 1em;
    color: $white30;
    display: block;
    position: absolute;
    top: 50%;
    pointer-events: none;
    transform: translateY(-50%);
    transition: font-size 0.125s ease-in-out, color 0.125s ease-in-out,
      top 0.125s ease-in-out;

    &--resize {
      color: $white70;
      // px so that it can be calculated correctly
      font-size: 12px;
      top: calc((#{math.div($kbaseRem, 3)}) + 6px);
    }

    &--menu-is-open {
      color: $white70;
    }

    &--required {
      color: $blue;
    }
  }

  &__single-value {
    font-size: math.div($kbaseRem, 1.2);
    color: $white;
    margin-bottom: math.div($kbaseRem, 3);
    max-width: calc(100% - 8px);
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: normal;
    bottom: 0;
  }

  &__multi-value {
    background-color: $darkTwo;
    border-radius: $borderRadius;
    display: flex;
    margin: $kbaseRem*0.125 $kbaseRem*0.125 $kbaseRem*0.125 0px;
    min-width: 0;
    overflow: hidden;

    & &__label {
      color: $white70;
      font-size: $kbaseRem*0.75;
      line-height: 1em;
      display: flex;
      align-items: center;
      overflow: hidden;
      padding: $kbaseRem*0.125 $kbaseRem*0.5;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & &__remove {
      align-items: center;
      border-radius: 0;
      display: flex;
      padding-left: 0;
      padding-right: $kbaseRem*0.25;
      background-color: transparent;

      &:hover {
        color: $white;
        background-color: transparent;
      }
    }

    &--animation-exit {
      opacity: 1;
      max-height: 30px;
      max-width: 200px;
    }
    &--animation-exit-active {
      opacity: 0;
      max-height: 0px;
      max-width: 0px;
      transition: max-width 0.25s ease-in-out,
        max-height 0.125s ease-in-out 0.125s, opacity 0.125s ease-in-out 0.125s;
    }
  }

  /** Indicators (X V) */
  &__indicators {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    height: $kbaseRem*2.25;

    &-separator {
      display: none;
    }
  }
  & &__dropdown-indicator {
    color: $white;
    display: flex;
    padding: $kbaseRem*0.5;
    box-sizing: border-box;
    transform: rotate(0deg);
    transition: transform 0.125s ease-in-out;
  }

  & &__clear-indicator {
    color: $white;
    display: flex;
    padding: $kbaseRem*0.5 0px $kbaseRem*0.5 $kbaseRem*0.125;
  }

  /** Control */
  &__control {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: transparent;
    border-color: $white10;
    border-radius: $borderRadius;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    justify-content: space-between;
    outline: 0 !important;
    position: relative;
    height: 100%;
    width: 100%;
    transition: border-color 0.125s ease-in-out;

    &:hover {
      border-color: $white30;
    }

    &--menu-is-open {
      border-color: $blue;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 1px solid $white10;
      transition: border-radius 0.125s ease-in-out,
        border-bottom 0.125s ease-in-out;

      &:hover {
        border-left-color: $blue;
        border-top-color: $blue;
        border-right-color: $blue;
        border-bottom-color: $white10;
      }

      .input__select__indicators {
        .input__select__dropdown-indicator {
          transform: rotate(180deg);
        }
      }
    }

    &--is-disabled {
      background-color: $white10;
      .input__select__value-container {
        .input__select__placeholder {
          color: $white30;
        }

        .input__select__single-value {
          color: $white30;
        }

        .input__select__multi-value {
          background-color: $white10;
          .input__select__multi-value__label {
            color: $white30;
          }

          .input__select__multi-value__remove {
            color: $white30;
            &:hover {
              color: $white30;
            }
          }
        }
      }

      &:hover {
        border-color: $white10;
      }
    }
  }

  /** Menu */
  &__menu {
    top: 100%;
    background-color: $dark;
    position: absolute;
    width: 100%;
    z-index: 1;

    &-list {
      max-height: 300px;
      overflow-y: auto;
      position: relative;
      -webkit-overflow-scrolling: touch;
      border-top: none;
      border-left: 1px solid $blue;
      border-right: 1px solid $blue;
      border-bottom: 1px solid $blue;
      border-bottom-left-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
    }
  }

  /** Menu Option */
  // can use menuIsOpen prop to force open for development
  &__option {
    color: $white70;
    cursor: default;
    display: block;
    font-size: inherit;
    padding: $kbaseRem*0.75 $kbaseRem*0.5;
    width: 100%;
    user-select: none;
    -webkit-tap-highlight-color: $darkTwo;
    border-bottom: 1px solid $white10;

    &--is-disabled {
      background-color: $white10;
      color: $white30;
    }

    &:last-child {
      border-bottom: none;
      border-bottom-left-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
    }

    &:not(&--is-disabled):hover {
      color: $white;
      background-color: $darkTwo;
    }
  }
}
