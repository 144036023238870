/* Only demo */
@media (max-width: 1000px) {
  .welcome-message {
    display: none;
  }
}

body #printableArea {
  background-color: $white !important;
  margin-bottom: 40px;
}
body #printableArea .row {
  background-color: $white !important;
}

#calendar {
  display: block;
  float: left;
  width: 7.2%;
  margin-right: 10px;
  height: 34px;
  margin-left: 0;
  background-repeat: no-repeat;
  border-right: 1px solid $white70;
}

#calendar_2 {
  display: block;
  float: left;
  width: 7.2%;
  margin-right: 10px;
  height: 34px;
  margin-left: 0;
  background-repeat: no-repeat;
  border-right: 1px solid $white70;
}

.has-feedback #calendar {
  display: inline-block;
  width: 7.2%;
  margin-right: 0px;
  height: 34px;
  margin-left: 0;
  border-right: 1px solid $white70;
}

.row #d-event-wrap {
  margin: 0;
  padding: 0;
  background-color: $activeDarkBackground;
  border-left: 2px solid #626a6f;
  border-top: 5px solid #626a6f;
}

#d-event-wrap .retreaved {
  width: 45%;
  display: block;
  float: left;
  text-align: center;
  margin-left: 5%;
}

#d-event-wrap .awating {
  width: 45%;
  display: block;
  float: left;
  text-align: center;
  margin-right: 5%;
}

#d-event-wrap .response-time {
  width: 100%;
  display: block;
  text-align: center;
  border-bottom: 2px solid #626a6f;
}

#d-event-wrap .descr {
  padding-top: 10%;
  text-align: center;
  display: block;
  color: $lightGrey;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

#d-event-wrap .descr .inner-descr {
  margin-top: 40px;
  width: 100px;
  margin: auto;
}

#d-event-wrap .events {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 4%;
}

#d-event-wrap .num {
  display: inline;
  color: #626a6f;
  font-size: 80px;
  font-weight: 800;
  line-height: 80px;
}

#d-event-wrap .event-news {
  width: 100%;
  max-height: 455px;
  overflow: scroll;
}

@media (min-width: 992px) {
  #d-event-wrap .event-news {
  }
}

#d-event-wrap .event-news .event {
  border-bottom: 2px solid #626a6f;
  padding-left: 20px;
  padding-top: 11px;
  padding-bottom: 11px;
  line-height: 24px;
  padding-right: 20px;
}

#d-event-wrap .event-news .event .type {
  color: $white;
  padding: 0 5px;
  border-radius: 2px;
  margin-right: 10px;
}

#d-event-wrap .event-news i {
  padding: 2px;
  padding-right: 4px;
}

#d-event-wrap .event-news .event .violet {
  background-color: #5b3155;
}

#d-event-wrap .event-news .event .order {
  color: $activeDarkBackground;
}

#d-event-wrap .event-news .event .blue {
  background-color: #4c6e77;
}

#d-event-wrap .event-news .event .red {
  background-color: #b3433e;
}

#d-event-wrap .event-news .event .orange {
  background-color: #b9784f;
}

#d-event-wrap .event-news .event .green {
  background-color: #8e9b53;
}

#d-event-wrap .event-news .event .grey {
  background-color: #81898d;
}

#d-event-wrap .event-news .event .addr {
  font-weight: 900;
  color: $white;
}

.busyMarker,
.freeMarker,
.sandMarker,
.wellMarker {
  background-size: cover;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
}

.busyMarker {
  background-image: url('/public/img/icons/busy.png');
}
.freeMarker {
  background-image: url('/public/img/icons/free.png');
}
.sandMarker {
  background-image: url('/public/img/icons/from.png');
}
.wellMarker {
  background-image: url('/public/img/icons/to.png');
}
