.img-circle {
  border-radius: 50%;
  max-width: 100%;
  height: auto;
}

.show-grid [class^='col-'] {
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #ddd;
  background-color: #eee !important;
}

.show-grid {
  margin: 15px 0;
}
