.dd,
.dt {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list .dd-list {
  padding-left: 30px;
}

.dd-collapsed .dd-list {
  display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}

.dd-handle {
  display: block;
  margin: 5px 0;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
  border: 1px solid $white70;
  background: #f5f5f5;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-handle span {
  font-weight: bold;
}

.dd-handle:hover {
  background: #f0f0f0;
  cursor: pointer;
  font-weight: bold;
}

.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
}

.dd-item > button::before {
  content: '+';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}

.dd-item > button[data-action='collapse']::before {
  content: '-';
}

#nestable2 .dd-item > button {
  font-family: FontAwesome;
  height: 34px;
  width: 33px;
  color: #c1c1c1;
}

#nestable2 .dd-item > button::before {
  content: '\f067';
}

#nestable2 .dd-item > button[data-action='collapse']::before {
  content: '\f068';
}

.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f2fbff;
  border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(
      45deg,
      $white 25%,
      transparent 25%,
      transparent 75%,
      $white 75%,
      $white
    ),
    -webkit-linear-gradient(45deg, $white 25%, transparent 25%, transparent 75%, $white
          75%, $white);
  background-image: -moz-linear-gradient(
      45deg,
      $white 25%,
      transparent 25%,
      transparent 75%,
      $white 75%,
      $white
    ),
    -moz-linear-gradient(45deg, $white 25%, transparent 25%, transparent 75%, $white
          75%, $white);
  background-image: linear-gradient(
      45deg,
      $white 25%,
      transparent 25%,
      transparent 75%,
      $white 75%,
      $white
    ),
    linear-gradient(
      45deg,
      $white 25%,
      transparent 25%,
      transparent 75%,
      $white 75%,
      $white
    );
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}

.dd-dragel {
  position: absolute;
  z-index: 9999;
  pointer-events: none;
}

.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}

.dd-dragel .dd-handle {
  box-shadow: $blockShadow;
}

.nestable-lists {
  display: block;
  clear: both;
  padding: 30px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
}

#nestable-menu {
  padding: 0;
  margin: 10px 0 20px 0;
}

#nestable-output,
#nestable2-output {
  width: 100%;
  font-size: 0.75em;
  line-height: 1.333333em;
  font-family: open sans, lucida grande, lucida sans unicode, helvetica, arial, sans-serif;
  padding: 5px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

#nestable2 .dd-handle {
  color: inherit;
  border: 1px dashed $white70;
  background: #f3f3f4;
  padding: 10px;
}

#nestable2 .dd-handle:hover {
  /*background: #bbb;*/
}

#nestable2 span.label {
  margin-right: 10px;
}

#nestable-output,
#nestable2-output {
  font-size: 12px;
  padding: 25px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
