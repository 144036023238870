.job-details {
  &__options {
    margin-left: auto;
    display: flex;
    width: auto;
    justify-content: flex-end;
    align-items: flex-start;
  }

  @media screen and (min-width: $mobilePlus) {
    .page-heading {
      display: flex;
    }
  }

  &__today-info {
    display: flex;
    justify-content: space-around;
  }
}
.unit-info {
  &__title-item {
    width: 20%;
  }
}

//onsite storage live
.greenFlash {
  color: #00b100;
  animation: blinker 3s linear infinite;
  font-size: 15px;
}

.redStatic {
  color: red;
  font-size: 15px;
}

.yellowStatic {
  color: #d3b100;
  font-size: 15px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

//
.alert-input-msg {
  color: red;
  text-align: center;
  margin-bottom: 10px;
}
