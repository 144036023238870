.reference-ids {

  @at-root #{&}__import {

    .input__select,
    .input {
      margin-bottom: 0;
    }

    @at-root #{&}__button {
      margin: 0 !important;
      margin-top: $kbaseRem*0.5 !important;
    }

    @at-root #{&}-row {
      display: flex;
      flex-wrap: wrap;
    }

    @at-root #{&}-item {
      width: 20%;
      padding: 0 $kbaseRem*0.5;

      &:first-child {
        padding-left:0;
      }

      &:last-child {
        padding-right:0;
      }
    }
  }

  @at-root #{&}__table {
    margin-top: $kbaseRem;
    padding: $kbaseRem;
    border-top: $darkBorder;

    .table-list__header-row,
    .table-list__body-block {
      grid-template-columns: none !important;

      @media screen and (min-width: $tablet) {
        grid-template-columns: 5% 30% 25% 25% 15% !important;
      }
    }
  }

  .page {
    @at-root #{&}__content {
      display: flex;
      align-items: flex-start
    }
  }

  .block {
    flex-grow: 1;
  }

  @at-root #{&}--preview-mode {
    .page__content {

      & > .block {

        &:first-child {
          margin-right: $kbaseRem*2;
        }
      }
    }

    .reference-ids__import {
      .reference-ids__import-row > .reference-ids__import-item {
        width: calc(100%/3);
        padding: $kbaseRem*0.5 0 $kbaseRem*0.5 $kbaseRem*0.5;

        &:nth-child(3),
        &:last-child {
          padding-right:0;
        }

        &:nth-child(4),
        &:first-child {
          padding-left:0;
        }

        &:nth-child(4),
        &:nth-child(5) {
          width: 50%;
          padding-bottom:0;
        }
      }
    }
  }
}
