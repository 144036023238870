.flot-chart {
  display: block;
  height: 200px;
}

.widget .flot-chart.dashboard-chart {
  display: block;
  height: 120px;
  margin-top: 40px;
}

.flot-chart.dashboard-chart {
  display: block;
  height: 180px;
  margin-top: 40px;
}

.flot-chart-content {
  width: 100%;
  height: 100%;
}

.flot-chart-pie-content {
  width: 200px;
  height: 200px;
  margin: auto;
}

.jqstooltip {
  position: absolute;
  display: block;
  left: 0px;
  top: 0px;
  visibility: hidden;
  background: #2b303a;
  background-color: rgba(43, 48, 58, 0.8);
  color: $white;
  text-align: left;
  white-space: nowrap;
  z-index: 10000;
  padding: 5px 5px 5px 5px;
  min-height: 22px;
  border-radius: 3px;
}

.jqsfield {
  color: $white;
  text-align: left;
}

.h-200 {
  min-height: 200px;
}

.legendLabel {
  padding-left: 5px;
}

.stat-list li:first-child {
  margin-top: 0;
}

.stat-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.stat-percent {
  float: right;
}

.stat-list li {
  margin-top: 15px;
  position: relative;
}

.level-text {
  font-weight: 500;

  @at-root #{&}--low {
    color: $dark;
  }
}
