@use "sass:math";

.header-container {
  min-width: 100%;
}

.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  height: 80px;
  background: $dark;
  border-bottom: $darkBorder;
  padding: $kbaseRem * 0.75;

  @at-root #{&}__nav-toggle {
    font-size: 1.3571428571428572rem;
    margin: 0 math.div($kbaseRem, 3);

    @media screen and (min-width: $tablet) {
      display: none;
    }
  }

  .button {
    margin: 0;
  }

  @at-root #{&}__title {
    margin: 0 math.div($kbaseRem, 3);
    flex-grow: 2;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    height: 100%;

    @at-root #{&}--name {
      @include ktype(0, 0, 0, -1);

      color: $white;
      font-weight: 500;
      display: contents;
    }

    @at-root #{&}--small {
      @include ktype(-1, 0, 0, -1);

      color: $white70;
      font-weight: 300;
    }

    @media screen and (min-width: $tablet) {
      display: none;
    }
  }

  @at-root #{&}__search {
    margin: 0 math.div($kbaseRem, 3);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: space-between;

    @at-root #{&}--mobile-toggle {
      display: none;
      @media screen and (max-width: $mobilePlus) {
        display: block;
      }
    }

    @at-root #{&}--form {
      display: none;

      @media screen and (min-width: $mobilePlus) {
        display: block;
      }
    }

    @media screen and (min-width: $tablet) {
      flex-grow: 2;
    }
  }

  @at-root #{&}__user-widget {
    display: none;

    @media screen and (min-width: $tablet) {
      display: block;
    }
  }

  @at-root #{&}__event-toggle {
    margin: 0 $kbaseRem*0.25;
  }
}
