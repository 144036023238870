// Mobile Styles
.navigation {
  width: 68vw;
  height: 100%;
  border-right: $darkBorder;
  background: $darkBackground;
  position: fixed;
  overflow: hidden;
  overflow-y: auto;
  top: 0;
  left: 0;
  z-index: 102;
  color: $white70;
  font-weight: 500;
  will-change: transform;
  transform: translateX(0);
  transition: transform 0.225s ease-out;
  display: inline-block;

  .icon.fa-chevron-left {
    display: none;
  }

  @at-root #{&}--hidden {
    transform: translateX(-68vw);
    transition: transform 0.225s ease-out;
  }

  @at-root #{&}--hidden ~ .overlay {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.225s ease-out;
  }

  @at-root #{&}__toggle-container {
    background: $darkTwo;
    position: relative;
  }

  @at-root #{&}__toggle.button {
    font-size: $kbaseRem * 1.5;
    position: absolute;
    right: 0;
    top: $kbaseRem*0.5;
    margin: 0;

    .icon__custom--close {
      .icon--fill {
        fill: $white;
      }
    }

    .icon__custom--bars {
      display: none;
    }
  }

  @at-root #{&}__branding {
    display: none;
  }

  @at-root #{&}__menu {
    padding: 0;
    list-style-type: none;
    color: inherit;
    font-weight: inherit;
    text-transform: uppercase;
  }

  @at-root #{&}__item {
    padding: $kbaseRem*0.5;
    background-color: transparent;
    padding-left: $kbaseRem;
    color: inherit;
    font-weight: inherit;
    text-transform: inherit;
    overflow: hidden;
    display: block;
    will-change: background-color;
    transition: background-color 0.225s ease-out;
    cursor: pointer;

    .icon__custom {
      .icon--fill {
        fill: $white;
      }
    }

    &:hover {
      .navigation__link {
        color: $blue;
      }

      .icon__custom {
        .icon--fill {
          fill: $blue;
        }
      }
    }

    .navigation__link--text {
      height: $kbaseRem;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;
      line-height: 1.5;
      margin-left: $kbaseRem*0.5;
    }

    @at-root #{&}--active {
      background: $darkTwo;
      border-left: 4px solid $blue;

      .navigation__link {
        color: $white70;
        position: relative;
        left: -4px;
      }

      .icon__custom {
        .icon--fill {
          fill: $white;
        }
      }

      .navigation__link--text {
        opacity: 1;
        color: $blue;
      }

      &.navigation__item--has-children .icon__custom--down {
        right: -4px;
        top: 0;
      }
    }

    @at-root #{&}--open {
      background: $darkTwo;
      transition: background-color 0.225s ease-out;

      &.navigation__item--has-children .icon__custom--down {
        transform: rotate(-180deg);
        transition: transform 0.225s ease-out;
      }
    }

    @at-root #{&}--has-children {
      position: relative;

      .icon__custom--down {
        width: $kbaseRem * 1.5;
        height: $kbaseRem * 1.5;
        position: absolute;
        right: $kbaseRem*0.5;
        top: $kbaseRem*0.5;
        will-change: transform;
        transform: rotate(0deg);
        transition: transform 0.225s ease-out;
        pointer-events: none;
      }
    }
  }

  @at-root #{&}__link {
    overflow: hidden;
    display: block;
    color: inherit;
    font-weight: inherit;
    text-transform: inherit;

    &:hover {
      color: $blue;

      .navigation__link--text {
        vertical-align: middle;
        opacity: 1;
      }
    }
  }

  @at-root #{&}__submenu {
    max-height: 0;
    opacity: 0;
    will-change: max-height opacity;
    overflow: hidden;
    list-style-type: none;
    padding: 0;
    font-weight: inherit;
    transition: max-height 0.225s ease-out, opacity 0.225s ease-out;

    @at-root #{&}--open {
      height: auto;
      max-height: 200px;
      opacity: 1;
      transition: max-height 0.225s ease-out, opacity 0.225s ease-out;
    }

    .navigation__item {
      font-weight: inherit;
      border-left: none;
      text-transform: capitalize;
      padding-left: $kbaseRem * 1.3;

      &:nth-child(odd) {
        padding-bottom: 0;
      }

      &:nth-child(even) {
        padding-bottom: 0;
      }

      .navigation__link--text {
        color: $white70;
        margin-left: $kbaseRem*0.25;

        &:hover {
          color: $blue;
        }
      }

      @at-root #{&}--active {
        .navigation__link--text {
          color: $blue;
        }
      }
    }

    .navigation__link {
      margin: 0;
      color: $white70;
      position: relative;
    }
  }
}

// Tablet styles
@media screen and (min-width: $tablet) {
  // Narrow Navigation
  .navigation.navigation--narrow {
    will-change: width, min-width;
    transform: translateX(0);
    min-width: 84px;
    width: 84px;
    transition: width 0.225s ease-out, min-width 0.225s ease-out;
    overflow: visible;
    min-height: 100%;
    height: auto;
    position: relative;

    .user-widget,
    ~ .overlay,
    .icon__custom--close {
      display: none;
    }

    .navigation__toggle-container {
      display: block;
      overflow: hidden;
      width: 83px;
      height: 80px;
      border-bottom: $darkBorder;
    }

    .navigation__toggle.button {
      display: block;
      overflow: hidden;
      width: 100%;
      position: relative;
      right: initial;
      top: initial;
      height: 80px;

      .icon__custom--bars {
        display: inline-block;
        width: 100%;
        padding: 25%;
        height: auto;

        .icon--fill {
          fill: $white;
        }
      }

      &:hover {
        .icon__custom--bars {
          .icon--fill {
            fill: $blue;
          }
        }
      }
    }

    .navigation__link {
      @include ktype(-1, 0, 0);

      .icon__custom {
        width: 100%;
        height: auto;
        padding: 0 25%;
      }

      .navigation__link--text {
        vertical-align: middle;
        opacity: 0;
        color: $white70;
        margin: 0;
      }

      .icon__custom--down {
        display: none;
      }
    }

    .navigation__item {
      text-align: center;
      padding: $kbaseRem*0.25 $kbaseRem*0.5;
      overflow: visible;
      position: relative;

      &:hover {
        .navigation__link--text {
          vertical-align: middle;
          opacity: 1;
          color: $blue;
        }

        .icon__custom .icon--fill {
          fill: $white;
        }
      }

      &.navigation__item--active {
        .navigation__link {
          position: relative;
          left: -2px;
        }

        .navigation__link--text {
          vertical-align: middle;
          opacity: 1;
          color: $blue;
        }

        .icon__custom .icon--fill {
          fill: $white;
        }
      }

      &.navigation__item--has-children {
        &:hover {
          .navigation__submenu {
            pointer-events: all;
            opacity: 1;
            left: 83px;
            transition: opacity 0.225s ease-out, left 0.225s ease-out;
          }
        }

        &.navigation__item--active:hover {
          .navigation__submenu {
            left: 79px;
          }
        }

        .navigation__submenu {
          max-height: initial;
          will-change: opacity, left;
          transition: opacity 0.225s ease-out, left 0.225s ease-out;
          position: absolute;
          opacity: 0;
          top: 0;
          left: -100%;
          display: block;
          background: $darkTwo;
          z-index: 103;
          width: 220px;
          border-right: 4px solid $blue;
          border-left: $darkBorder;
          pointer-events: none;

          .navigation__item {
            padding: $kbaseRem;
            text-align: left;

            &:nth-child(odd) {
              padding-bottom: $kbaseRem*0.5;
              padding-top: $kbaseRem*0.5;
            }

            &:nth-child(even) {
              padding-top: $kbaseRem*0.5;
              padding-bottom: $kbaseRem*0.5;
            }

            .navigation__link {
              @include ktype(0, 0, 0);

              .navigation__link--text {
                vertical-align: middle;
                color: $white70;
                opacity: 1;
              }

              &:hover {
                .navigation__link--text {
                  vertical-align: middle;
                  color: $blue;
                }
              }
            }

            &.navigation__item--active {
              background: transparent;

              .navigation__link {
                left: 0;

                .navigation__link--text {
                  vertical-align: middle;
                  color: $blue;
                }
              }
            }
          }
        }
      }
    }
  }

  // Wide Navigation
  .navigation.navigation--hidden {
    will-change: width, min-width;
    min-width: 228px;
    width: 228px;
    transition: width 0.225s ease-out, min-width 0.225s ease-out;
    overflow: visible;
    min-height: 100%;
    height: auto;
    position: relative;
    transform: translateX(0);

    .icon__custom--close,
    .user-widget {
      display: none;
    }

    .navigation__toggle-container {
      height: 80px;
      position: absolute;
      background-color: transparent;
    }

    .navigation__toggle.button {
      right: initial;
      font-size: $kbaseRem * 2;
      left: 0;
      top: 50%;
      z-index: 3;
      display: initial;
      transform: translateY(-50%);
      padding: $kbaseRem * 0.25;

      .icon__custom--left {
        width: $kbaseRem * 2;

        .icon--fill {
          fill: $white;
          width: 100%;
        }
      }

      &:hover {
        .icon__custom--left {
          .icon--fill {
            fill: $blue;
          }
        }
      }
    }

    .navigation__branding {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding: $kbaseRem;
      padding-left: $kbaseRem * 2;
      width: 100%;
      height: 80px;
      border-bottom: $darkBorder;
    }

    .navigation__branding--logo {
      min-width: 44px;
      width: 44px;
      margin-right: $kbaseRem*0.5;

      @at-root #{&}-image {
        width: 100%;
      }
    }

    .navigation__branding--title-name {
      @include ktype(1, 0, 0);

      color: $white;
      font-weight: 500;
    }

    .navigation__branding--title-small {
      @include ktype(0, 0, 0);

      color: $white;
      font-weight: 300;
    }
  }
}
