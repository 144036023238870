.events-wrapper {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  display: block;
  max-width: 0;
}

.events-sidebar {
  background: $dark;
  position: fixed;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  top: 0;
  right: -100%;
  transition: right 0.225s ease-in-out;
  z-index: 10000;

  &--open {
    right: 0;
    transition: right 0.225s ease-in-out;
  }

  @media screen and (min-width: $tablet) {
    width: 450px;
    top: 0;
    border-left: $darkBorder;
    opacity: 0;
    transition: right 0.225s ease-in-out, opacity 0.225s ease-in-out;
    min-height: 100%;

    &--open {
      right: 0;
      opacity: 1;
      transition: right 0.225s ease-in-out, opacity 0.225s ease-in-out;
    }
  }

  &__container {
    .tabs {
      height: $kbaseRem * 2.5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      padding: 0;
      margin: 0;
      background-color: $darkTwo;
      color: $white50;
      text-transform: uppercase;
      font-weight: 500;
      padding: 0 $kbaseRem;

      &__tab {
        height: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        text-decoration: none;
        cursor: pointer;

        &--title {
          height: 100%;
          display: flex;
          align-items: center;

          &:hover {
            color: $white;
          }
        }

        &--active {
          .tabs__tab--title {
            border-bottom: solid 2px $blue;
            color: $blue;
          }
        }
      }
    }
  }

  &__close {
    position: absolute;
    right: $kbaseRem;
    top: $kbaseRem;
    width: $kbaseRem * 1.5;
    height: $kbaseRem * 1.5;
  }

  &__header {
    padding: $kbaseRem;
    position: relative;

    .notification-toggle {
      margin-bottom: 0;
      padding: 0;

      &:hover {
        padding: 0;
      }
    }
  }
}
