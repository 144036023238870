// Bootstrap helpers and modifiers

// Removes gutters from children that have bootstrap columns
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

// Removes outside gutters from children that have bootstrap columns
.no-outer-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;

  @media screen and (min-width: 992px) {
    &:first-child {
      padding-left: 0;
      padding-right: 15px;
    }
    &:last-child:not(:only-child) {
      padding-left: 15px;
      padding-right: 0;
    }
  }
}

// Removes outside gutters from nested children that have bootstrap columns
.nested-no-outer-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;

  @media screen and (min-width: 768px) {
    &:nth-child(odd) {
      padding-left: 0;
      padding-right: 15px;
    }
    &:nth-child(even) {
      padding-left: 15px;
      padding-right: 0;
    }
  }
}
