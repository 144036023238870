@use "sass:math";

&__file {
  height: $kbaseRem * 2.25;
  font-size: 1rem;
  position: relative;
  font-weight: inherit;
  border-radius: $borderRadius;
  border: 1px solid;
  border-color: $white10;
  padding: $kbaseRem*0.25 $kbaseRem*0.25 $kbaseRem*0.25 $kbaseRem*0.5;
  display: flex;
  align-items: flex-end;

  &:focus-within {
    border-color: $blue;
    transition: border-color 0.125s ease-in-out;
  }

  &--disabled {
    background-color: $white10;
    color: $white30;
    border-color: $white10;
    .input__text__label {
      color: $white30;
    }
    .input__file__button {
      color: $darkBlue30;
      border-color: $darkBlue30;
    }
  }

  &:not(&--disabled):hover {
    border-color: $white30;
  }

  &__button {
    display: flex;
    align-items: center;
    font-size: math.div($kbaseRem, 1.2);
    color: $blue;
    text-transform: uppercase;
    font-weight: 500;
    height: 100%;
    border: solid 1px $blue;
    border-radius: $borderRadius;
    padding: 0 $kbaseRem*0.25;
    margin-left: auto;
  }

  &__text {
    font-size: math.div($kbaseRem, 1.2);
    color: $white;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__label {
    font-size: math.div($kbaseRem, 1.2);
    text-transform: capitalize;
    line-height: 1em;
    color: $white30;
    display: block;
    position: absolute;
    left: $kbaseRem * 0.5;
    top: 50%;
    pointer-events: none;
    transform: translateY(-50%);
    transition: font-size 0.125s ease-in-out, color 0.125s ease-in-out,
      top 0.125s ease-in-out;

    &--required {
      color: $blue;
    }

    &--has-file {
      color: $white70;
      font-size: 12px;
      top: calc((#{math.div($kbaseRem, 3)}) + 6px);
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
  }
}
