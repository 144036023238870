.text-navy {
  color: $blue;
}
.text-navy-bold-start {
  color: $blue;
  font-weight: bold;
  text-align: start;
}
.text-navy-bold-centered {
  color: $blue;
  font-weight: bold;
  text-align: center;
}

.text-primary {
  color: inherit;
}
.text-primary-bold-start {
  color: inherit;
  font-weight: bold;
  text-align: start;
}
.text-primary-bold-centered {
  color: inherit;
  font-weight: bold;
  text-align: center;
}

.text-success {
  color: $green;
}
.text-success-bold-start {
  color: $green;
  font-weight: bold;
  text-align: start;
}
.text-success-bold-centered {
  color: $green;
  font-weight: bold;
  text-align: center;
}

.text-info {
  color: $yellow;
}
.text-info-bold-start {
  color: $yellow;
  font-weight: bold;
  text-align: start;
}
.text-info-bold-centered {
  color: $yellow;
  font-weight: bold;
  text-align: center;
}

.text-warning {
  color: $orange;
}
.text-warning-bold-start {
  color: $orange;
  font-weight: bold;
  text-align: start;
}
.text-warning-bold-centered {
  color: $orange;
  font-weight: bold;
  text-align: center;
}

.text-danger {
  color: $red;
}
.text-danger-bold-start {
  color: $red;
  font-weight: bold;
  text-align: start;
}
.text-danger-bold-centered {
  color: $red;
  font-weight: bold;
  text-align: center;
}

.text-muted {
  color: $white30 !important;
}
.text-muted-bold-start {
  color: $white30;
  font-weight: bold;
  text-align: start;
}
.text-muted-bold-centered {
  color: $white30;
  font-weight: bold;
  text-align: center;
}

.text-subtitle {
  color: $white60 !important;
  font-weight: bold;
}
.text-white {
  color: $white;
}
.text-white-bold-start {
  color: $white;
  font-weight: bold;
  text-align: start;
}
.text-white-bold-centered {
  color: $white;
  font-weight: bold;
  text-align: center;
}

.textTransformCapitalize {
  text-transform: capitalize;
}

.textTransformLowercase {
  text-transform: lowercase;
}

.textTransformUppercase {
  text-transform: uppercase;
}

.simple_tag {
  background-color: #f3f3f4;
  border: 1px solid $white70;
  border-radius: 2px;
  color: inherit;
  font-size: 10px;
  margin-right: 5px;
  margin-top: 5px;
  padding: 5px 12px;
  display: inline-block;
}

.img-shadow {
  box-shadow: $blockShadow;
}

/* For handle diferent bg color in AngularJS version */
.dashboards\.dashboard_2 nav.navbar,
.dashboards\.dashboard_3 nav.navbar,
.mailbox\.inbox nav.navbar,
.mailbox\.email_view nav.navbar,
.mailbox\.email_compose nav.navbar,
.dashboards\.dashboard_4_1 nav.navbar,
.metrics nav.navbar,
.metrics\.index nav.navbar,
.dashboards\.dashboard_5 nav.navbar {
  background: $white;
}

/* For handle diferent bg color in MVC version */
.Dashboard_2 .navbar.navbar-static-top,
.Dashboard_3 .navbar.navbar-static-top,
.Dashboard_4_1 .navbar.navbar-static-top,
.ComposeEmail .navbar.navbar-static-top,
.EmailView .navbar.navbar-static-top,
.Inbox .navbar.navbar-static-top,
.Metrics .navbar.navbar-static-top,
.Dashboard_5 .navbar.navbar-static-top {
  background: $white;
}

a.close-canvas-menu {
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 1011;
  color: #a7b1c2;
}

a.close-canvas-menu:hover {
  color: $white;
}

// Margin and Padding
.p-xxs {
  padding: 5px;
}

.p-xs {
  padding: 10px;
}

.p-sm {
  padding: 15px;
}

.p-m {
  padding: 20px;
}

.p-md {
  padding: 25px;
}

.p-lg {
  padding: 30px;
}

.p-xl {
  padding: 40px;
}

.p-w-xxs {
  padding: 0 5px;
}

.p-w-xs {
  padding: 0 10px;
}

.p-w-sm {
  padding: 0 15px;
}

.p-w-m {
  padding: 0 20px;
}

.p-w-md {
  padding: 0 25px;
}

.p-w-lg {
  padding: 0 30px;
}

.p-w-xl {
  padding: 0 40px;
}

.m-xxs {
  margin: 2px 4px;
}

.m-xs {
  margin: 5px;
}

.m-sm {
  margin: 10px;
}

.m {
  margin: 15px;
}

.m-md {
  margin: 20px;
}

.m-lg {
  margin: 30px;
}

.m-xl {
  margin: 50px;
}

.m-n {
  margin: 0 !important;
}

.m-l-none {
  margin-left: 0;
}

.m-l-xs {
  margin-left: 5px;
}

.m-l-sm {
  margin-left: 10px;
}

.m-l {
  margin-left: 15px;
}

.m-l-md {
  margin-left: 20px;
}

.m-l-lg {
  margin-left: 30px;
}

.m-l-xl {
  margin-left: 40px;
}

.m-l-n-xxs {
  margin-left: -1px;
}

.m-l-n-xs {
  margin-left: -5px;
}

.m-l-n-sm {
  margin-left: -10px;
}

.m-l-n {
  margin-left: -15px;
}

.m-l-n-md {
  margin-left: -20px;
}

.m-l-n-lg {
  margin-left: -30px;
}

.m-l-n-xl {
  margin-left: -40px;
}

.m-t-none {
  margin-top: 0;
}

.m-t-xxs {
  margin-top: 1px;
}

.m-t-xs {
  margin-top: 5px;
}

.m-t-sm {
  margin-top: 10px;
}

.m-t {
  margin-top: 15px;
}

.m-t-md {
  margin-top: 20px;
}

.m-t-lg {
  margin-top: 30px;
}

.m-t-xl {
  margin-top: 40px;
}

.m-t-n-xxs {
  margin-top: -1px;
}

.m-t-n-xs {
  margin-top: -5px;
}

.m-t-n-sm {
  margin-top: -10px;
}

.m-t-n {
  margin-top: -15px;
}

.m-t-n-md {
  margin-top: -20px;
}

.m-t-n-lg {
  margin-top: -30px;
}

.m-t-n-xl {
  margin-top: -40px;
}

.m-r-none {
  margin-right: 0;
}

.m-r-xxs {
  margin-right: 1px;
}

.m-r-xs {
  margin-right: 5px;
}

.m-r-sm {
  margin-right: 10px;
}

.m-r {
  margin-right: 15px;
}

.m-r-md {
  margin-right: 20px;
}

.m-r-lg {
  margin-right: 30px;
}

.m-r-xl {
  margin-right: 40px;
}

.m-r-n-xxs {
  margin-right: -1px;
}

.m-r-n-xs {
  margin-right: -5px;
}

.m-r-n-sm {
  margin-right: -10px;
}

.m-r-n {
  margin-right: -15px;
}

.m-r-n-md {
  margin-right: -20px;
}

.m-r-n-lg {
  margin-right: -30px;
}

.m-r-n-xl {
  margin-right: -40px;
}

.m-b-none {
  margin-bottom: 0;
}

.m-b-xxs {
  margin-bottom: 1px;
}

.m-b-xs {
  margin-bottom: 5px;
}

.m-b-sm {
  margin-bottom: 10px;
}

.m-b {
  margin-bottom: 15px;
}

.m-b-md {
  margin-bottom: 20px;
}

.m-b-lg {
  margin-bottom: 30px;
}

.m-b-xl {
  margin-bottom: 40px;
}

.m-b-n-xxs {
  margin-bottom: -1px;
}

.m-b-n-xs {
  margin-bottom: -5px;
}

.m-b-n-sm {
  margin-bottom: -10px;
}

.m-b-n {
  margin-bottom: -15px;
}

.m-b-n-md {
  margin-bottom: -20px;
}

.m-b-n-lg {
  margin-bottom: -30px;
}

.m-b-n-xl {
  margin-bottom: -40px;
}

.space-15 {
  margin: 15px 0;
}

.space-20 {
  margin: 20px 0;
}

.space-25 {
  margin: 25px 0;
}

.space-30 {
  margin: 30px 0;
}

.border-bottom-table {
  border-bottom: 1px solid #e3e3e3;
}

.border-bottom-light {
  border-bottom: 1px solid rgba($white, 0.1);
}

.cropArea {
  background: #e4e4e4;
  overflow: hidden;
  width: 350px;
  height: 450px;
  margin-bottom: 20px;
}

.allHistoryColumns {
  max-width: 1132px;
  margin: 0 auto;
  padding: 10px 40px 20px 20px;
}

.rotate {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.modal-w90 {
  min-width: 90% !important;
}

.modal-w40 {
  min-width: 40% !important;
}

// .show {
//   display: block;
//   visibility: visible;
//   opacity: 1;
//   transition: all 0.3s ease;
// }

.displayInline {
  display: inline;
}

.displayInlineBlock {
  display: inline-block;
}

.displayNone {
  display: none;
}

.displayNoneImportant {
  display: none !important;
}

