a.list-group-item.active,
a.list-group-item.active:hover,
a.list-group-item.active:focus {
  background-color: $green;
  border-color: $green;
  color: $white;
  z-index: 2;
}

.list-group-item-heading {
  margin-top: 10px;
}

.list-group-item-text {
  margin: 0 0 10px;
  color: inherit;
  font-size: 12px;
  line-height: inherit;
}

.no-padding .list-group-item {
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.no-padding .list-group-item:first-child {
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: none;
}

.no-padding .list-group {
  margin-bottom: 0;
}

.list-group-item {
  background-color: $darkTwo;
  display: block;
  padding: $kbaseRem*0.5;
  position: relative;
  border: none;
  border-radius: 4px;
  margin-bottom: $kbaseRem*0.5;
}

.elements-list .list-group-item {
  border-left: none;
  border-right: none;
  padding: 15px 25px;
}

.elements-list .list-group-item:first-child {
  border-left: none;
  border-right: none;
  border-top: none !important;
}

.elements-list .list-group {
  margin-bottom: 0;
}

.elements-list a {
  color: inherit;
}

.elements-list .list-group-item.active,
.elements-list .list-group-item:hover {
  background: #f3f3f4;
  color: inherit;
  border-color: $white70;
  /*border-bottom: 1px solid $white70;*/
  /*border-top: 1px solid $white70;*/
  border-radius: 0;
}

.elements-list li.active {
  transition: none;
}

.element-detail-box {
  padding: 25px;
}
