@use "sass:math";

.reconcile {
  .tabs__list {
    padding: $kbaseRem;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .tabs__content {
    padding: 0;
  }

  .table-list--action-items {
    .table-list__header-row--desktop,
    .table-list__body-block {
      grid-template-columns: none !important;

      @media screen and (min-width: $tablet) {
        grid-template-columns: 3% 7% 6% 10% 8% 10% 8% 13% 5% 7% 6% 10% 7% !important;
      }
    }
  }

  .table-list--order-status {
    .table-list__header-row--desktop,
    .table-list__body-block {
      grid-template-columns: none !important;

      @media screen and (min-width: $tablet) {
        grid-template-columns: 4% 6% 11% 10% 11% 11% 8% 12% 5% 9% 6% 7% !important;
      }
    }
  }

  .table-list--action-items,
  .table-list--order-status {
    .badge {
      margin-bottom: $kbaseRem*0.25;
      margin-left: 0;

      // status = New
      &.badge--status-0 {
        color: $yellow;
      }

      // status = Reviewed
      &.badge--status-1 {
        color: $green;
      }

      // status = Confirmed
      &.badge--status-2 {
        color: $green;
      }

      // status = Unconfirmed
      &.badge--status-3 {
        color: $yellow;
      }

      // status = Disputed
      &.badge--status-4 {
        color: $red;
      }

      // status = Ready to bill
      &.badge--status-20 {
        color: $green;
      }

      // status = Billed
      &.badge--status-21 {
        color: $blue;
      }

      // status = Paid/Settled
      &.badge--status-22,
      &.badge--status-23 {
        color: $purple;
      }
    }
  }

  .slide-panel__content {
    .tabs__list {
      padding: 0 $kbaseRem * 0.5;
      margin-bottom: $kbaseRem;

      @media screen and (min-width: $tablet) {
        padding: 0 $kbaseRem;
      }
    }

    .tabs__content {
      padding: 0 $kbaseRem * 0.5;

      @media screen and (min-width: $tablet) {
        padding: 0 $kbaseRem;
      }
    }

    .order-info {
      &__header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        border-bottom: $darkBorder;
        padding-bottom: $kbaseRem * 0.5;
        margin-bottom: $kbaseRem * 0.5;

        @media screen and (min-width: $tablet) {
          padding-bottom: $kbaseRem;
          margin-bottom: $kbaseRem;
        }
      }

      &__title {
        margin-bottom: 0;
        color: $white;
        text-transform: capitalize;
        flex-grow: 1;

        .icon {
          width: $kbaseRem * 1.5;
          height: $kbaseRem * 1.5;
        }

        &__text {
          padding-left: math.div($kbaseRem, 6);

          @media screen and (min-width: $tablet) {
            padding-left: math.div($kbaseRem, 3);
          }
        }
      }

      .dropdown-button-container .button {
        margin-right: 0;
      }

      .button {
        margin-bottom: 0;
      }

      &__heading {
        width: 100%;
        display: block;
        margin-bottom: $kbaseRem;
      }

      &__tags {
        margin-bottom: $kbaseRem;
      }

      &__price {
        margin-bottom: $kbaseRem;
        border-bottom: $darkBorder;
      }

      &__tags-list {
        .badge {
          text-transform: capitalize;

          &:first-of-type {
            margin-left: 0;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      &__info {
        border-bottom: $darkBorder;
        margin-bottom: $kbaseRem * 0.5;

        @media screen and (min-width: $tablet) {
          border-bottom: none;
        }

        &__row {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          align-items: flex-start;
          border-bottom: none;
          padding-bottom: $kbaseRem * 0.5;
          margin-bottom: $kbaseRem * 0.5;

          @media screen and (min-width: $tablet) {
            flex-flow: row nowrap;
            border-bottom: $darkBorder;
            padding-bottom: $kbaseRem;
            margin-bottom: $kbaseRem;
          }
        }

        .info-column {
          width: 100%;

          @media screen and (min-width: $tablet) {
            width: 50%;
            padding-right: $kbaseRem * 0.5;

            &:last-of-type {
              padding-right: 0;
            }
          }

          &__item {
            width: 100%;
            display: block;
            line-height: $kbaseRem;
            margin-bottom: $kbaseRem * 0.25;

            .white {
              color: $white;
              font-weight: 500;
            }
          }
        }
      }

      &__documents-timeline {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-start;

        @media screen and  (min-width: $tablet) {
          flex-flow: row nowrap;
        }
      }

      &__documents {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        justify-content: center;
        padding-bottom: $kbaseRem;

        @media screen and  (min-width: $tablet) {
          width: 50%;
        }

        @media screen and  (min-width: $desktop) {
          width: 60%;
        }

        &__header {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-bottom: $kbaseRem;

          .button {
            margin: 0;

            &__text {
              .loader {
                max-height: 100%;
              }
            }
          }
        }

        &__title {
          text-transform: uppercase;
          flex-grow: 1;
          margin-bottom: 0;
          padding-right: $kbaseRem;
        }

        &__tickets {
          width: 100%;

          .ticket {
            border: $darkBorder;
            border-radius: $borderRadius;
            padding: $kbaseRem * 0.5;
            margin-bottom: $kbaseRem * 0.5;
            display: flex;
            flex-flow: column;

            &__header {
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              justify-content: space-between;
              width: 100%;

              &__text {
                flex-grow: 1;
                padding-right: $kbaseRem * 0.5;

                .white {
                  color: $white;
                  font-weight: 500;
                }
              }

              &__buttons {
                .button {
                  margin-bottom: 0;

                  &:last-of-type {
                    margin-right: 0;
                  }
                }
              }
            }

            &__preview {
              padding: $kbaseRem;
              padding-bottom: $kbaseRem * 0.5;
              text-align: center;
              width: 100%;

              .image {
                max-width: 100%;
              }
            }
          }
        }
      }

      &__timeline {
        width: 100%;
        padding-bottom: $kbaseRem;

        @media screen and  (min-width: $tablet) {
          width: 50%;
          margin-left: $kbaseRem;
        }

        @media screen and  (min-width: $desktop) {
          width: 40%;
        }
      }
    }

    .price {
      &__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-bottom: $darkBorder;
        padding-bottom: $kbaseRem;
        margin-bottom: $kbaseRem;
      }

      &__breakdown-list {
        display: flex;
        flex-flow: column nowrap;
        padding-bottom: $kbaseRem;
        margin-bottom: $kbaseRem;
        border-bottom: $darkBorder;

        &__item {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: flex-start;
          padding-bottom: $kbaseRem;
          margin-bottom: $kbaseRem * 0.5;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &__info {
          padding-right: $kbaseRem * 0.25;
          flex-grow: 1;
        }

        &__buttons {
          min-width: $kbaseRem * 4;
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-end;
          align-items: flex-start;
        }
      }

      &__heading {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $kbaseRem * 0.5;
        text-transform: none;
        font-weight: normal;
      }

      &__description {
        padding-left: $kbaseRem;
        padding-right: $kbaseRem * 3;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        font-weight: lighter;

        .white {
          padding-left: $kbaseRem * 0.25;
        }
      }

      .white {
        color: $white;
      }

      .blue {
        color: $blue;
        padding-left: $kbaseRem * 0.25;
      }

      &__subtotal,
      &__total {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $kbaseRem;
        padding-bottom: $kbaseRem;
        border-bottom: $darkBorder;
        font-weight: 300;
        text-transform: capitalize;
        padding-right: $kbaseRem * 4.25;
      }

      &__total {
        text-transform: uppercase;
        border-bottom: none;
        font-weight: 500;
      }
    }

    .timeline {
      align-items: center;
      padding-bottom: $kbaseRem * 0.5;
      margin-bottom: $kbaseRem * 0.5;

      &__title {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;
        margin-bottom: $kbaseRem * 0.75;
        font-weight: 500;

        &__value {
          padding-left: $kbaseRem * 0.5;
        }

        .white {
          color: $white;
        }
      }

      &__list {
        margin-bottom: $kbaseRem * 2;
        border-radius: $borderRadius;
        border: $darkBorder;

        &__item {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          padding: $kbaseRem * 0.5 $kbaseRem * 0.75;
        }

        &__value {
          color: $green;
        }

        &__border {
          border-top: $darkBorder;
        }

        &__title {
          color: $white;
          font-weight: 500;

          &__warning {
            margin-left: $kbaseRem;
            font-weight: initial;
            color: $white70;
          }
          &__danger {
            color: $red;
          }
        }
      }
    }

    .reconcile-activity {
      border-bottom: $darkBorder;
      margin-bottom: $kbaseRem;
      max-height: 82vh;
      height: 100%;
      overflow-y: auto;

      &__timeline {
        margin-left: $kbaseRem;
        padding-left: $kbaseRem * 5;
        border-left: $darkBorder;
        position: relative;
      }

      .date-block {
        padding-bottom: $kbaseRem;

        &__title {
          text-transform: uppercase;
          text-align: center;
          color: $white40;
          position: relative;
          margin-bottom: $kbaseRem*0.5;

          &::before,
          &::after {
            content: '';
            display: block;
            overflow: hidden;
            max-width: $kbaseRem * 8;
            width: 30%;
            height: 1px;
            position: absolute;
            top: 50%;
            background: $white10;
          }

          &::before {
            left: 0;
          }

          &::after {
            right: 0;
          }
        }

        &__reconcile-activity-list {
          padding: 0;
          margin: 0;
          list-style-type: none;
        }

        &__reconcile-activity-item {
          margin-bottom: $kbaseRem*0.5;
          position: relative;

          .reconcile-activity-item__dot,
          .reconcile-activity-item__time,
          .reconcile-activity-item__message {
            display: inline-block;
          }

          .reconcile-activity-item__dot {
            width: 40px;
            height: 40px;
            position: absolute;
            left: -$kbaseRem * 5;
            margin-left: -20px;
            top: 50%;
            margin-top: -20px;
            border-radius: 50%;
            background-image: $blueGradient30;

            &--inner {
              width: 20px;
              height: 20px;
              position: absolute;
              left: 10px;
              top: 10px;
              border-radius: 50%;
              background-image: $blueGradient;
            }
          }

          .reconcile-activity-item__time {
            position: absolute;
            left: -$kbaseRem * 3.5;
            margin-left: 10px;
            top: 50%;
            margin-top: -$body-font-size;
            color: $white;
          }

          .reconcile-activity-item__message {
            position: relative;
            padding: $kbaseRem*0.5;
            border: $darkBorder;
            border-radius: $borderRadius;
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: stretch;
            align-items: flex-start;

            &--image {
              min-width: 40px;
              max-width: 40px;
              min-height: 40px;
              max-height: 40px;
              border-radius: 50%;
              overflow: hidden;
              position: relative;

              .image {
                width: 100%;
                height: auto;
              }

              &.system {
                background-color: $white;

                .image {
                  width: 30px;
                  height: 30px;
                  position: absolute;
                  top: 5px;
                  left: 5px;
                }
              }
            }

            &--details {
              padding-left: $kbaseRem;
              line-height: $body-line-height;
              max-width: 89%;

              .intro {
                color: $white;
                font-weight: 500;
                margin-bottom: $kbaseRem * 0.25;
                text-transform: capitalize;
              }

              .content {
                font-style: italic;
                word-break: normal;
              }
            }
          }
        }
      }
    }
  }
}

.activity-comment-form {
  display: flex;

  .input {
    flex-grow: 1;
    margin-right: $kbaseRem * 0.5;
  }

  .button {
    margin: 0;
    min-width: 160px;
  }
}

.history-content {
  min-height: 300px;

  .history-list-item {
    width: 95%;
    display: inline-block;

    .badge-margin {
      margin-left: 5px;
    }
  }
}

.audit-status {
  & > *:not(:last-child) {
    margin-bottom: 1px;
  }

  &__circle {
    // Used pixels to prevent browser issues with fractional pixels.
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: $darkBorder;

    &--pending {
      border: solid 1px $white70;
    }

    &--approved {
      background-color: $white70;
      border: none;
    }

    &--tooltip {
      // Used pixels to prevent browser issues with fractional pixels.
      width: 10px;
      height: 10px;
      margin-right: 10px;
    }
  }

  &__tooltip {
    margin: 10px;

    &__list {
      display: flex;
      align-items: center;
    }

    &__text {
      margin: 5px;

      &--bold {
        font-weight: 500;
      }
    }
  }
}

.reconcile__status {

  @at-root #{&}__tooltip {
    float: right;
  }

  @at-root #{&}__badge {
    &.badge {
      flex: auto;
      overflow: hidden;
    }
  }
}