.table-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: stretch;

  @at-root #{&}--fixed {
    width: 100%;
  }

  @at-root #{&}__header-row {
    justify-content: space-around;
    flex-flow: column nowrap;
    padding: $kbaseRem*0.5 0;
    border-bottom: none;

    @media screen and (min-width: $tablet) {
      display: grid;
      grid-template-columns: auto;
      padding: $kbaseRem*0.5;
      width: 100%;
      border-bottom: $darkBorder;
    }

    @at-root #{&}--desktop {
      display: none;

      @media screen and (min-width: $tablet) {
        display: grid;
      }
    }

    @at-root #{&}--mobile {
      display: flex;
      width: 50%;
      padding: 0 !important;

      .table-list__header-item--checkbox {
        opacity: 0;
        pointer-events: none;
      }

      @media screen and (min-width: $tablet) {
        display: none;
      }
    }
  }

  @at-root #{&}__header-item {
    color: $white30;
    text-transform: uppercase;
    padding: $kbaseRem*0.5 0;

    @media screen and (min-width: $tablet) {
      align-self: center;
      padding: 0;
      padding-right: $kbaseRem*0.25;
    }

    @at-root #{&}__sort-arrow {
      display: none;
      width: $kbaseRem * 0.8;
      height: $kbaseRem * 0.8;
      position: relative;
      top: -2px;

      @at-root #{&}--fill {
        fill: $white30;
      }
    }

    &:hover {
      color: $white70;
      cursor: pointer;

      @media screen and (min-width: $tablet) {
        .table-list__header-item__sort-arrow {
          display: inline-block;

          .icon--fill {
            fill: $white;
          }
        }
      }
    }

    @at-root #{&}--sorted {
      .table-list__header-item__sort-arrow {
        display: inline-block;
      }
    }
  }

  @at-root #{&}__body-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    padding: $kbaseRem*0.5;
    border-bottom: $darkBorder;

    &:last-of-type {
      border-bottom: none;
    }

    .table-list__body-block {
      width: 50%;
      display: inline-block;

      @media screen and (min-width: $tablet) {
        display: grid;
        grid-template-columns: auto;
        width: 100%;
      }

      > .table-list__body-item {
        display: block;
        padding: $kbaseRem*0.5 0;

        @media screen and (min-width: $tablet) {
          padding: 0;
          padding-right: $kbaseRem*0.25;
        }
      }
    }

    @at-root #{&}--clickable {
      &:hover,
      &.table-list__body-row--selected {
        cursor: pointer;
        background: $darkTwo;

        .table-list__body-item {
          .table-list__body--clickable--arrow {
            display: block;
          }
        }
      }

      .table-list__body-item {
        position: relative;
        padding-right: $kbaseRem*0.25;

        @media screen and (min-width: $tablet) {
          width: 100%;
        }

        &:last-of-type {
          padding-right: $kbaseRem;
        }

        .table-list__body--clickable--arrow {
          position: absolute;
          top: 50%;
          margin-top: -$kbaseRem*0.5;
          right: 0;
          display: none;

          .icon--fill {
            fill: $white;
          }
        }
      }
    }
  }
}
