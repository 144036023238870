.audit-details-slide-panel {
  display: block;
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  width: 90%;
  max-width: 90%;
  background: $dark;
  box-shadow: $blockShadow;
  border-left: $darkBorder;
  height: 100vh;
  padding: $kbaseRem*0.5 0;
  will-change: transform;
  transform: translateX(110%);
  transition: transform 0.225s ease-out;

  @media screen and (min-width: $tablet) {
    width: 670px;
    max-width: 65%;
    padding: $kbaseRem 0;
  }

  @media screen and (min-width: $desktop) {
    width: auto;
    max-width: 80%;
    min-width: 650px;
  }

  @at-root #{&}--visible {
    transform: translateX(0);
    transition: transform 0.225s ease-out;

    .audit-details-slide-panel__content {
      width: 90%;
      opacity: 1;
      transition: transform 0.225s ease-out;
    }
  }

  @at-root #{&}__content {
    margin: auto;
    width: 100%;
    max-height: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    overflow-x: auto;
    overflow-y: auto;
    will-change: opacity;
    opacity: 0;
    transition: opacity 0.225s ease-out;
    background-color: none;
  }

  @at-root #{&}__close {
    position: absolute;
    z-index: 1;
    left: -$kbaseRem * 1.5;
    top: 50%;
    margin-top: -$kbaseRem * 0.625;

    .button {
      background: $dark;
      border: $darkBorder;
      border-right: 1px solid $dark;
      border-radius: 0;

      .icon--fill {
        fill: $white;
      }

      &:hover,
      &:active {
        background: $dark;
        border: $darkBorder;
        border-right: 1px solid $dark;

        .icon--fill {
          fill: $blue;
        }
      }
    }

  }
}

