.do-action-wrapper {
  overflow: hidden;
}

.do-action {
  width: 90%;
  max-width: 460px;
  margin: 25% auto;
  padding: $kbaseRem*2 $kbaseRem $kbaseRem;
  text-align: center;
  box-shadow: $blockShadow;
  border-radius: 4px;

  @media screen and (min-width: $tablet) {
    width: 80%;
    margin: 10% auto;
    padding: $kbaseRem*3 $kbaseRem*2 $kbaseRem*2;
  }

  @at-root #{&}__container {
    width: 100%;

    @media screen and (min-width: $tablet) {
      width: 100%;
      margin: 0 auto;
    }

    @at-root #{&}--branding {
      position: relative;
      padding-bottom: $kbaseRem*2;
      border-bottom: $darkBorder;
      margin-bottom: $kbaseRem*2;
    }

    @at-root #{&}--branding-logo {
      position: absolute;
      top: -$kbaseRem*3;
      left: 50%;
      margin-left: -25px;
      width: 50px;

      @media screen and (min-width: $tablet) {
        width: 100px;
        margin-left: -50px;
        top: -$kbaseRem*6;
      }
    }

    @at-root #{&}--branding-welcome {
      @include ktype(2, 0, 1);

      width: 100%;
      text-transform: none;
      font-weight: 300;
    }

    @at-root #{&}--actions {
      .button {
        margin-bottom: $kbaseRem;
      }
    }

    @at-root #{&}--dashboard-link {
      width: 100%;
      text-align: center;
    }
  }
}
