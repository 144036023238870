.nav-tabs {
  border-bottom: $darkBorder;
  text-transform: uppercase;
  margin-bottom: $kbaseRem;

  >.nav-item {
    >.nav-link {
      color: $white70;
      border: none;
      font-weight: 500;
      text-transform: uppercase;
      background-color: transparent;
      border-radius: 0;
      padding: $kbaseRem*0.5 0;
      margin: 0 $kbaseRem*0.5;

      &:hover {
        color: $white;
        border-bottom: 2px solid $white;
        background-color: transparent;
      }

      &.active {
        color: $blue;
        border: none;
        border-radius: 0;
        border-bottom: 2px solid $blue;
        background-color: transparent;
      }
    }

    &:first-child>.nav-link {
      margin-left: 0;
    }

    &:last-child>.nav-link {
      margin-right: 0;
    }
  }
}