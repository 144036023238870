.ui-sortable .ibox-title {
  cursor: move;
}

.ui-sortable-placeholder {
  border: 1px dashed #cecece !important;
  visibility: visible !important;
  background: $white70;
}

.ibox.ui-sortable-placeholder {
  margin: 0px 0px 23px !important;
}
