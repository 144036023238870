.pagination {
  text-align: center;
  width: auto;
  padding: 0;
  display: block;
  overflow: hidden;
  margin: $kbaseRem auto;

  @at-root #{&}__item {
    display: inline-block;

    .button {
      margin-bottom: 0;
    }

    @at-root #{&}--active {
      .button {
        color: $white;
        background-image: $blueGradient;
        border: none;
        padding: 0 15px;
        -webkit-appearance: none;
        outline: none;
        cursor: default;
      }
    }
  }
}

// New Pagination styling
.pagination-new {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin: $kbaseRem 0;
  width: 100%;

  @at-root #{&}__pages {
    width: 50%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    .button {
      margin-right: $kbaseRem*0.5;
      margin-bottom: 0;
    }

    .input__select {
      margin: 0 $kbaseRem*0.5;
      width: $kbaseRem*5;
      margin-bottom: 0;
    }
  }

  @at-root #{&}__limit {
    width: 50%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;

    .input__select {
      width: $kbaseRem*5;
      margin-left: $kbaseRem*0.5;
      margin-bottom: 0;
    }
  }
}