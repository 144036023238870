// Plugins
@import '~react-datetime/css/react-datetime.css';
@import '~react-big-calendar/lib/css/react-big-calendar.css';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles.css';
@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css';
@import '~bootstrap/dist/css/bootstrap.css';
@import '~font-awesome/css/font-awesome.css';
@import '~react-image-crop/dist/ReactCrop.css';

// Components/layout
@import './setup/_index';
@import './partials/_index';
@import './plugins/_index';
@import './modules/_index';
@import './sharedComponents/_index';
@import './base/_index';
@import './views/_index';
