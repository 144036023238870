.box {
  background-image: $darkGradient;
  padding: $kbaseRem*1.5;
  box-shadow: $blockShadow;
  border-radius: $borderRadius;

  @at-root #{&}--no-radius {
    border-radius: 0;
  }
}
