@use "sass:math";

&__checkbox {
  display: inline-flex;
  align-items: center;
  position: relative;

  &__custom-checkbox {
    content: '';
    position: relative;
    top: 0;
    left: 0;
    height: $kbaseRem;
    width: $kbaseRem;
    color: $white60;
    background-color: transparent;
    border: 1px solid;
    border-radius: 2px;
    display: inline-block;
    transition: border-color 0.125s ease-in-out;

    .input__checkbox__check-mark,
    .input__checkbox__indeterminate {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.125s ease-in-out;
      svg {
        fill: $blue;
      }
    }
  }

  &.hover,
  &:hover {
    .input__checkbox__custom-checkbox {
      border-color: $blue;
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    z-index: 1;

    &:checked ~ .input__checkbox__custom-checkbox {
      color: $blue;
      .input__checkbox__check-mark {
        opacity: 1;
      }
    }

    &:indeterminate ~ .input__checkbox__custom-checkbox {
      color: $blue;
      .input__checkbox__indeterminate {
        opacity: 1;
      }
    }

    &:disabled ~ .input__checkbox__custom-checkbox {
      border-color: $white40;
      background-color: $white20;
      color: $white30;
      .input__checkbox__check-mark,
      .input__checkbox__indeterminate {
        svg {
          fill: $white30;
        }
      }
    }
  }

  &__label {
    flex: 0 0 auto;
    color: $white70;
    padding-left: $kbaseRem * 0.5;
    font-size: math.div($kbaseRem, 1.2);
    line-height: 1em;
    font-weight: 400;
    text-transform: capitalize;
    vertical-align: middle;
  }
}
