.rbc-day-slot .rbc-event {
  width: 100% !important;
  left: 0 !important;
}

/* CHOSEN PLUGIN */
.chosen-container-single .chosen-single {
  background: $white;
  box-shadow: none;
  -moz-box-sizing: border-box;
  background-color: $white;
  border: 1px solid #cbd5dd;
  border-radius: 2px;
  cursor: text;
  height: auto !important;
  margin: 0;
  min-height: 30px;
  overflow: hidden;
  padding: 4px 12px;
  position: relative;
  width: 100%;
}

.chosen-container-multi .chosen-choices li.search-choice {
  background: #f1f1f1;
  border: 1px solid #ededed;
  border-radius: 2px;
  box-shadow: none;
  color: #333333;
  cursor: default;
  line-height: 13px;
  margin: 3px 0 3px 5px;
  padding: 3px 20px 3px 5px;
  position: relative;
}

// Tabs List
.steps ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin: $kbaseRem 0 0;

  li {
    border: 1px solid;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 $kbaseRem*0.5;
    color: $white70;

    a {
      font-weight: 500;
      color: $white70;

      &:hover {
        color: $blue;
      }
    }

    &:hover {
      color: $blue;
    }

    &.current {
      color: $white;
      cursor: text;
      pointer-events: none;

      a {
        color: $white;
      }
    }

    &.disabled {
      color: $white30;
      cursor: not-allowed;
      pointer-events: none;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
