.tooltip--container {
  background-color: $darkFive;
  border-radius: $borderRadius;
  display: flex;
  flex-direction: column;
  padding: $kbaseRem*0.25;
  transition: opacity 0.3s;
  z-index: 99999999;
  position: relative;

  &.right {
    margin-left: $kbaseRem * 0.75;
  }
  &.left {
    margin-right: $kbaseRem * 0.75;
  }
  &.bottom {
    margin-top: $kbaseRem * 0.75;
  }
  &.top {
    margin-bottom: $kbaseRem * 0.75;
  }

  .tooltip--arrow {
    height: 1rem;
    width: 1rem;

    &::after {
      content: '';
      width: 0;
      height: 0;
      display: block;
      border-style: solid;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-width: 0.5rem;
    }

    &[data-placement*='right'] {
      align-self: flex-start;
      margin-left: -18px;
      &::after {
        border-right-color: $darkFive;
      }
    }

    &[data-placement*='left'] {
      align-self: flex-end;
      margin-right: -18px;
      &::after {
        border-left-color: $darkFive;
      }
    }

    &[data-placement*='top'] {
      // Annoying overrides that have to be here for some reason
      position: relative !important;
      left: auto !important;
      transform: none !important;

      align-self: center;
      margin-bottom: -18px;
      &::after {
        border-top-color: $darkFive;
      }
    }

    &[data-placement*='bottom'] {
      margin-top: -18px;
      &::after {
        border-bottom-color: $darkFive;
      }
    }
  }
}
