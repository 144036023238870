.profile-content {
  border-top: none !important;
}

.profile-stats {
  margin-right: 10px;
}

.profile-image {
  padding-left: 130px;
  margin-bottom: $kbaseRem;
}

.profile-image img {
  max-width: 50%;
}

.profile-info {
  margin: 0 auto $kbaseRem;
}

.feed-activity-list .feed-element {
  border-bottom: 1px solid $white70;
}

.feed-element:first-child {
  margin-top: 0;
}

.feed-element {
  padding-bottom: 15px;
}

.feed-element,
.feed-element .media {
  margin-top: 15px;
}

.feed-element,
.media-body {
  overflow: hidden;
}

.feed-element > .float-start {
  margin-right: 10px;
}

.feed-element img.img-circle,
.dropdown-messages-box img.img-circle {
  width: 38px;
  height: 38px;
}

.feed-element .well {
  background: transparent;
  border: 1px solid $white70;
  box-shadow: none;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px 20px;
  font-size: 11px;
  line-height: 16px;
}

.feed-element .actions {
  margin-top: 10px;
}

.feed-element .photos {
  margin: 10px 0;
}

.feed-photo {
  max-height: 180px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;
}

.file-list li {
  padding: 5px 10px;
  font-size: 11px;
  border-radius: 2px;
  border: 1px solid $white70;
  margin-bottom: 5px;
}

.file-list li a {
  color: inherit;
}

.file-list li a:hover {
  color: $green;
}

.user-friends img {
  width: 42px;
  height: 42px;
  margin-bottom: 5px;
  margin-right: 5px;
}
