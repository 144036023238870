@use "sass:math";

.info-window {
  border-radius: 2px;
  border: solid 1px $white30;
  background-color: $darkTwo;
  padding: $kbaseRem*0.5;

  &:hover {
    z-index: 1;
  }

  @at-root #{&}__header {
    display: flex;
    align-items: flex-start;
    flex-flow: row nowrap;
    margin-bottom: $kbaseRem;
  }

  @at-root #{&}__title {
    @include ktype(1, 0, 0);

    line-height: $kbaseRem * 1.4;
    font-weight: 500;
    flex: 1;
    color: white;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    .icon {
      width: $kbaseRem * 1.5;
      height: $kbaseRem * 1.5;
    }

    .info-window__wellsite {
      display: flex;
      align-items: flex-start;
      flex-flow: row nowrap;

      .icon {
        width: $kbaseRem * 1.2;
        height: $kbaseRem * 1.2;
      }
    }

    @at-root #{&}--text {
      width: calc(100% - 2rem);
    }
  }

  @at-root #{&}__pin-icon {
    opacity: 0.7;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  @at-root #{&}__close-icon {
    opacity: 0.7;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  &::after {
    position: absolute;
    content: '';
    width: 14px;
    height: 14px;
    transform: rotate(45deg);
    border: solid 1px $white30;
    background-color: $darkTwo;
    top: calc(100% - 7px);
    left: calc(50% - 7px);
    border-top: 0;
    border-left: 0;
    border-bottom-right-radius: 2px;
  }

  .pinned {
    opacity: 1;
  }

  @at-root #{&}__status-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: $kbaseRem*0.5 0;

    .status-block {
      border: $darkBorder;
      border-radius: 4px;
      padding: $kbaseRem * 0.5;
      display: flex;
      align-items: center;
      width: 48%;

      .icon {
        width: 30px;
        height: 30px;
        margin-right: $kbaseRem * 0.25;
      }

      @at-root #{&}__text {
        font-size: 12px;

        @at-root #{&}__values {
          color: $white;
        }
      }
    }
  }

  @at-root #{&}__warning-container {
    width: 100%;
    padding: 1px;
    padding-left: 4px;
    border-radius: 4px;
    background-image: $redGradient;
    margin: $kbaseRem*0.5 0;

    @at-root #{&}--message {
      background: $dark;
      border-radius: 0 4px 4px 0;
      line-height: 1.666rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .icon {
        margin: 0 $kbaseRem*0.25;
      }

      .white {
        font-weight: 500;
        color: $white;
      }
    }
  }

  .driver-content {
    height: auto;
    line-height: 1em;

    @at-root #{&}__email {
      margin-bottom: math.div($kbaseRem, 3);
      display: flex;
      align-items: center;
    }

    @at-root #{&}__columns {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: flex-start;

      @at-root #{&}--item {
        width: 50%;
        padding-right: $kbaseRem * 0.5;
        margin-bottom: math.div($kbaseRem, 3);
        color: $white50;
        display: flex;
        align-items: center;

        &:nth-child(2n) {
          padding-right: 0;
        }
      }
    }

    .icon {
      width: $kbaseRem * 1.25;
      height: $kbaseRem * 1.25;
      font-size: $kbaseRem;
    }

    @at-root #{&}--white {
      color: $white;
      font-weight: 500;
    }
  }

  .wellsite-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: $kbaseRem*0.25;

    .button {
      margin-top: $kbaseRem;
    }
  }
}
