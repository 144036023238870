.tabs {

  @at-root #{&}__list {
    list-style-type: none;
    padding: 0 $kbaseRem*0.5;
    border-bottom: $darkBorder;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    @media screen and (min-width: $tablet) {
      padding: 0 $kbaseRem;
    }

    @at-root #{&}--item {
      margin: 0 $kbaseRem*0.5;
      padding-bottom: $kbaseRem;
      text-transform: uppercase;
      font-weight: 500;
      position: relative;

      @media screen and (min-width: $tablet) {
        @include ktype(1, 0, 0);
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover,
      &.active {
        color: $blue;
        cursor: pointer;

        &::after {
          content: '';
          display: block;
          overflow: hidden;
          width: 100%;
          height: 2px;
          background: $blue;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
  }

  @at-root #{&}__content {
    padding: 0 $kbaseRem*0.5;

    @media screen and (min-width: $tablet) {
      padding: 0 $kbaseRem;
    }
  }
}
