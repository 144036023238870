.queue {
  @at-root #{&}__tables-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    margin-top: $kbaseRem;
  }

  @at-root #{&}__table {
    flex-grow:1;
  }

  @at-root #{&}__drivers-list {
    margin:0 0 0 $kbaseRem;
  }

  @at-root #{&}__orders-list {
    margin:0 $kbaseRem 0 0;
  }
}


// OLD QUEUE - MUST BE REMOVED AS PART OF NEW QUEUE EPIC
.queue-tables-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;

  .queue-table-list {
    padding: 0 $kbaseRem;
    width: 100%;

    @media screen and (min-width: $tablet) {
      width: 50%;
    }
  }
}

.table-responsive {
  border: none;
}
