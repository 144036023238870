.block {
  background: $darkGradient;
  border-radius: $borderRadius;
  border: $darkBorder;

  &__title {
    font-size: $kbaseRem;
    font-weight: 500;
    text-transform: uppercase;
    padding: $kbaseRem;
    border-bottom: $darkBorder;
    color: $white;
    width: 100%;
    margin-bottom: 0;
  }

  &__content {
    padding: $kbaseRem;
  }

  &__block-break {
    border-top: solid 1px $white10;
    margin: $kbaseRem 0 0 0;
    padding: 0;

    &--no-margin {
      // must be the exact amount of the padding from the modal * 2
      width: calc(100% + 1.35714rem * 2);
      margin-left: -$kbaseRem;
      margin-right: -$kbaseRem;
    }
  }

  // float
  &--float {
    box-shadow: $blockShadow;
    border: none;
  }

  // Themes
  &--dark {
    background: $black10;
  }

  &--none {
    background: transparent;
  }

  // Modifiers
  &--no-padding {
    & > .block__content {
      padding: 0;
    }
  }

  &--margin {
    margin: $kbaseRem;
  }

  &--no-border {
    border: 0;
  }

  &--small-title {
    .block__title {
      padding-bottom: 0;
      font-size: 14px;
      border-bottom: 0;
    }
  }
}
