@use "sass:math";

&__radio {
  display: inline-flex;
  align-items: center;
  position: relative;

  &__custom-radio {
    display: block;
    content: '';
    position: relative;
    top: 0;
    left: 0;
    height: $kbaseRem;
    width: $kbaseRem;
    border: 1px solid $white70;
    border-radius: 50%;

    &__circle {
      opacity: 0;
      display: block;
      content: '';
      height: $kbaseRem * 0.5;
      width: $kbaseRem * 0.5;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $blue;
      border-radius: 50%;
    }
  }

  &:hover,
  &.hover {
    .input__radio__custom-radio {
      border-color: $blue;
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    z-index: 1;

    &:checked ~ .input__radio__custom-radio {
      border-color: $blue;
      .input__radio__custom-radio__circle {
        opacity: 1;
      }
    }

    &:disabled ~ .input__radio__custom-radio {
      border-color: $white40;
      background-color: $white20;
      .input__radio__custom-radio__circle {
        background-color: $white30;
      }
    }
  }

  &__label {
    flex: 0 0 auto;
    color: $white70;
    font-size: math.div($kbaseRem, 1.2);
    line-height: 1em;
    padding-left: $kbaseRem * 0.5;
    font-weight: 400;
    text-transform: capitalize;
    vertical-align: middle;
  }
}
