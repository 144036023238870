.test {
  color: $purple;
}
.dashboard-events {
  margin: 0 0 25px 0;
  overflow: scroll;
  color: $lightGrey;
  background-color: $activeDarkBackground;

  h2 {
    margin: 10px 0;
    font-weight: 600;
    margin: 20px 0;
    font-size: 40px;
  }

  h5 {
    margin: 10px 0;
    font-weight: 600;
    text-transform: uppercase;
  }
}
.dashboard-events-block-events {
  max-height: 400px;
  overflow: scroll;
}
.dashboard-events-info {
  padding: 30px 15px 51px !important;
  border-right: 2px $lightGrey solid;
  text-align: center;
}
.dashboard-events-list {
  overflow-y: auto;
  margin: 0 -16px;
  padding: 5px 0;
}
.dashboard-events__item {
  padding: $kbaseRem*0.5 $kbaseRem $kbaseRem;
}
.dashboard-events__badge {
  display: inline-block;
  padding: $kbaseRem*0.25;
  border-radius: 4px;
  background: $white30;
  color: $white;
  text-transform: uppercase;
  font-weight: 700;

  .fa {
    padding-right: 2px;
  }
}
.dashboard-events__badge--green {
  background-color: $green;
}
.dashboard-events__badge--red {
  background-color: $red;
}
.dashboard-events__badge--blue {
  background: $blue;
}
.dashboard-events__badge--purple {
  background: $purple;
}
.dashboard-events__badge--orange {
  background: $orange;
}
.dashboard-events-content-container {
  overflow: scroll;
}
@media screen and (max-width: 991px) {
  .dashboard-events {
    max-height: none;
  }
  .dashboard-events-info {
    padding: 20px 15px 40px !important;
    border-right: 0;
  }
}
@media screen and (max-width: 430px) {
  .dashboard-events {
    h2 {
      font-size: 24px;
    }
    h5 {
      font-size: 10px;
    }
  }
  .dashboard-events-info {
    padding: 5px 15px 15px;
    .col-xs-4 {
      padding: 0 5px;
    }
  }
}
