.login-wrapper {
  overflow: hidden;
}

.login {
  width: 90%;
  max-width: 600px;
  margin: 25% auto;
  padding: $kbaseRem * 2 $kbaseRem $kbaseRem;
  text-align: center;
  box-shadow: $blockShadow;
  border-radius: 4px;

  @media screen and (min-width: $tablet) {
    width: 80%;
    margin: 10% auto;
    padding: $kbaseRem * 3 $kbaseRem * 2 $kbaseRem * 2;
  }

  @at-root #{&}__container {
    width: 100%;

    @media screen and (min-width: $tablet) {
      width: 100%;
      margin: 0 auto;
    }

    @at-root #{&}--branding {
      position: relative;
    }

    @at-root #{&}--branding-logo {
      position: absolute;
      top: -$kbaseRem * 3;
      left: 50%;
      margin-left: -25px;
      width: 50px;

      @media screen and (min-width: $tablet) {
        width: 100px;
        margin-left: -50px;
        top: -$kbaseRem * 6;
      }
    }

    @at-root #{&}--branding-welcome {
      @include ktype(1, 0, 0.5, -1);

      color: $white;
      text-transform: uppercase;
      font-weight: 500;
    }

    @at-root #{&}--incorrect-credentials {
      color: red;
      font-weight: 600;
      margin-bottom: 10px;
    }

    @at-root #{&}--role-buttons {
      @media screen and (min-width: $tablet) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: stretch;

        .button--tall {
          width: 48%;
        }
      }
    }

    .button__text {
      flex-flow: column nowrap;
      white-space: initial;
    }

    @at-root #{&}--button-text-big {
      @include ktype(1, 0, 0.5, 1);
    }

    @at-root #{&}--button-text-small {
      margin-bottom: 0;
    }
  }
}

.do-actiongin {
  &__email-support {
    color: $white70;

    &:hover,
    &:focus {
      color: $blue;
    }
  }

  &__forgot-password {
    cursor: pointer;
    color: $blue;

    &:hover,
    &:focus {
      color: $white70;
    }
  }
}
