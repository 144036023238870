#toast-container > .toast {
  background-image: none !important;
}

#toast-container > .toast::before {
  position: fixed;
  font-family: FontAwesome;
  font-size: 24px;
  line-height: 24px;
  float: left;
  color: $white;
  padding-right: 0.5em;
  margin: auto 0.5em auto -1.5em;
}

#toast-container > .toast-warning::before {
  content: '\f0e7';
}

#toast-container > .toast-error::before {
  content: '\f071';
}

#toast-container > .toast-info::before {
  content: '\f005';
}

#toast-container > .toast-success::before {
  content: '\f00C';
}

#toast-container > div {
  box-shadow: $blockShadow;
  opacity: 0.9;
}

#toast-container > :hover {
  box-shadow: $blockShadow;
  opacity: 1;
  cursor: pointer;
}

.toast {
  background-color: $green;
}

.toast-success {
  background-color: $green;
}

.toast-error {
  background-color: $red;
}

.toast-info {
  background-color: #23c6c8;
}

.toast-warning {
  background-color: $orange;
}

.toast-top-full-width {
  margin-top: 20px;
}

.toast-bottom-full-width {
  margin-bottom: 20px;
}
