.cg-notify-message.inspinia-notify {
  background: $white;
  padding: 0;
  box-shadow: $blockShadow;
  border: none;
  margin-top: 30px;
  color: inherit;
}

.inspinia-notify.alert-warning {
  border-left: 6px solid $orange;
}

.inspinia-notify.alert-success {
  border-left: 6px solid $blue;
}

.inspinia-notify.alert-danger {
  border-left: 6px solid $red;
}

.inspinia-notify.alert-info {
  border-left: 6px solid $green;
}
