.device-stats {
    &__body {
      background-image: $darkGradient;
      color: $white70;
      border-radius: $borderRadius;
      box-shadow: 4px 4px 20px 4px rgba(0, 0, 0, 0.2);
      min-height: 65px;
      clear: both;
      overflow: visible;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      justify-content: center;
      padding: $kbaseRem 0;
      margin-bottom: $kbaseRem;
  
      &__header {
        height: 37px;
        padding: 0 $kbaseRem;
        .icon {
          will-change: transform;
          transform: rotate(360deg);
          transition: transform 0.225s ease-out;
        }
        .down {
          .icon {
            transform: rotate(180deg);
            transition: transform 0.225s ease-out;
          }
        }
      }
  
      &__content {
        border-top: $darkBorder;
  
        &__progress-content {
          padding: 0px 15px;
          margin-top: 15px;
        }
  
        &__progress-labels {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;
  
          &--android {
            width: 10px;
            height: 10px;
            border-radius: $borderRadius;
            background-image: $greenGradientLeft;
            display: inline-block;
            margin-right: 10px;
          }
          &--ios {
            width: 10px;
            height: 10px;
            border-radius: $borderRadius;
            background-image: $blueGradientLeft;
            display: inline-block;
            margin-right: 10px;
          }
        }
      }
    }
  }
  
  .android-color {
    background-image: $greenGradientLeft !important;
  }
  .ios-color {
    background-image: $blueGradientLeft !important;
  }
  
  .driver-stats {
    &__body {
      background-image: $darkGradient;
      border-radius: $borderRadius;
      box-shadow: 4px 4px 20px 4px rgba(0, 0, 0, 0.2);
      min-height: 65px;
      clear: both;
      overflow: visible;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      justify-content: center;
      padding: $kbaseRem 0;
      margin-bottom: $kbaseRem;
      margin-right: $kbaseRem;
  
      &__header {
        height: 37px;
        padding: 0 $kbaseRem;
        .icon {
          will-change: transform;
          transform: rotate(360deg);
          transition: transform 0.225s ease-out;
        }
        .down {
          .icon {
            transform: rotate(180deg);
            transition: transform 0.225s ease-out;
          }
        }
      }
  
      &__content {
        border-top: $darkBorder;
        display: flex;
  
        @media (max-width: $mobilePlus) {
          flex-direction: column;
          align-items: center;
        }
  
        .pie-chart__total {
          div {
            span > .driver-stats-pie-icon {
              width: 70px;
              height: 60px;
            }
            .driver-stats-pie-count {
              font-size: 16px !important;
              font-weight: 600;
              margin: 0;
              color: $lightGrey;
            }
            .driver-stats-pie-text {
              font-size: 14px !important;
              color: $lightGrey;
              margin: 0;
            }
          }
        }
  
        &__chart {
          padding: $kbaseRem;
        }
  
        &__labels {
          width: 100%;
          padding: $kbaseRem;
          display: flex;
          align-items: center;
  
          &--wrapper {
            width: 100%;
          }
  
          &__item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
  
            &--active {
              width: 10px;
              height: 10px;
              border-radius: $borderRadius;
              background-image: $greenGradient;
              display: inline-block;
              margin-right: 10px;
            }
            &--inactive {
              width: 10px;
              height: 10px;
              border-radius: $borderRadius;
              background-image: $yellowGradient;
              display: inline-block;
              margin-right: 10px;
            }
            &--text {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  
  .driver-filters {
    padding-bottom: $kbaseRem;
    padding-top: $kbaseRem;
    border-bottom: $darkBorder;
    border-top: $darkBorder;
  
    &__top-row {
      justify-content: flex-end;
    }
  }
  
  .driver-list-wrapper {
    &__stats-block {
      display: flex;
      @media (max-width: $tabletPlus) {
        flex-direction: column;
      }
    }
  }