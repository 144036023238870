.icon {
  width: $kbaseRem;
  height: $kbaseRem;
  vertical-align: middle;
  display: inline-block;

  @at-root #{&}--margin-left {
    margin-left: $kbaseRem * 0.75;
  }

  @at-root #{&}--margin-right {
    margin-right: $kbaseRem * 0.75;
  }

  @at-root #{&}__custom {
    .icon--fill {
      fill: $white70;
    }

    &::before {
      display: none;
    }
  }

  // Colours
  @at-root #{&}--yellow {
    color: $yellow;

    .icon--fill {
      fill: $yellow;
    }
  }

  @at-root #{&}--green {
    color: $green;

    .icon--fill {
      fill: $green;
    }
  }

  @at-root #{&}--red {
    color: $red;

    .icon--fill {
      fill: $red;
    }
  }

  @at-root #{&}--purple {
    color: $purple;

    .icon--fill {
      fill: $purple;
    }
  }

  @at-root #{&}--orange {
    color: $orange;

    .icon--fill {
      fill: $orange;
    }
  }

  @at-root #{&}--grey {
    color: $lightGrey;

    .icon--fill {
      fill: $lightGrey;
    }
  }

  @at-root #{&}--white {
    color: $white;

    .icon--fill {
      fill: $white;
    }
  }

  @at-root #{&}--white50 {
    color: $white50;

    .icon--fill {
      fill: $white50;
    }
  }

  @at-root #{&}--blue {
    color: $blue;

    .icon--fill {
      fill: $blue;
    }
  }
}

// Fixes for existing codebase
.label .icon.fa {
  line-height: 1.6;
}

.icon.fa {
  vertical-align: baseline !important;
}