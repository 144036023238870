.invoice-activity {
  border-bottom: $darkBorder;
  margin-bottom: $kbaseRem;

  @at-root #{&}__timeline {
    margin-left: $kbaseRem;
    padding-left: $kbaseRem*5;
    border-left: $darkBorder;
    position: relative;
  }

  .date-block {
    padding-bottom: $kbaseRem;

    @at-root #{&}__title {
      text-transform: uppercase;
      text-align: center;
      color: $white40;
      position: relative;
      margin-bottom: $kbaseRem*0.5;

      &::before,
      &::after {
        content: '';
        display: block;
        overflow: hidden;
        width: $kbaseRem*8;
        height: 1px;
        position: absolute;
        top: 50%;
        background: $white10;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }

    @at-root #{&}__activity-list {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    @at-root #{&}__activity-item {
      margin-bottom: $kbaseRem*0.5;
      position: relative;

      .activity-item__dot,
      .activity-item__time,
      .activity-item__message {
        display: inline-block;
      }

      .activity-item__dot {
        width: 40px;
        height: 40px;
        position: absolute;
        left: -$kbaseRem*5;
        margin-left: -20px;
        top: 50%;
        margin-top: -20px;
        border-radius: 50%;
        background-image: $blueGradient30;

        @at-root #{&}--inner {
          width: 20px;
          height: 20px;
          position: absolute;
          left: 10px;
          top: 10px;
          border-radius: 50%;
          background-image: $blueGradient;
        }
      }

      .activity-item__time {
        position: absolute;
        left: -$kbaseRem*3.5;
        margin-left: 10px;
        top: 50%;
        margin-top: -$body-font-size;
        color: $white;
      }

      .activity-item__message {
        position: relative;
        padding: $kbaseRem*0.5;
        border: $darkBorder;
        border-radius: $borderRadius;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: stretch;
        align-items: flex-start;

        @at-root #{&}--image {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          position: relative;

          .image {
            width: 100%;
            height: auto;
          }
        }

        @at-root #{&}--details {
          padding-left: $kbaseRem;
          line-height: $body-line-height;
          max-width: 89%;

          .name,
          .content {
            color: $white;
            font-weight: 500;
          }
        }
      }
    }
  }
}
