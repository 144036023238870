// Leftover styling when Input was refactored
// Unsure if needed
.form-input__calendar {
  display: inline-block;
  width: 100%;
  color: $white;
  position: relative;
  font-weight: normal;
  cursor: pointer;
  margin-bottom: $kbaseRem*0.5;

  @at-root #{&} input:disabled {
    background-color: $white10;
    color: $white30;
  }

  .rdtPicker {
    background: $dark;
    border-color: $blue;
    border-radius: 4px;

    .rdtPrev:hover,
    .rdtSwitch:hover,
    .rdtNext:hover,
    td.rdtDay:hover,
    td.rdtHour:hover,
    td.rdtMinute:hover,
    td.rdtSecond:hover,
    .rdtTimeToggle:hover {
      background: $darkTwo;
    }

    th {
      border-bottom: 1px solid $white10;
    }

    tfoot {
      border-top: 1px solid $white10;
    }
  }
}
.form-input__calendar2 {
  display: block;
  width: 100%;
  color: $white;
  position: relative;
  font-weight: normal;
  cursor: pointer;
  margin-bottom: $kbaseRem*0.5;

  @at-root #{&} input:disabled {
    background-color: $white10;
    color: $white30;
  }

  .rdtPicker {
    background: $dark;
    border-color: $blue;
    border-radius: 4px;

    .rdtPrev:hover,
    .rdtSwitch:hover,
    .rdtNext:hover,
    td.rdtDay:hover,
    td.rdtHour:hover,
    td.rdtMinute:hover,
    td.rdtSecond:hover,
    .rdtTimeToggle:hover {
      background: $darkTwo;
    }

    th {
      border-bottom: 1px solid $white10;
    }

    tfoot {
      border-top: 1px solid $white10;
    }
  }
}

.red-border, .red-border input.form-control{
  border-color: red;
}

.red-border, .red-border select.input__select{
  border-color: red;
}

.form-control {
  position: relative;
  color: inherit;
  background: transparent;
  border-radius: 4px;
  border: 1px solid;
  border-color: $white10;
  height: $kbaseRem * 2.5;
  width: 100%;
  padding: $kbaseRem*0.25;
  font-weight: inherit;
  appearance: none;
  outline: none;
  transition: border-color 0.125s linear;

  &:hover {
    border-color: $white30;
    transition: border-color 0.125s linear;
  }

  &:focus {
    border-color: $blue;
    transition: border-color 0.125s linear;
    appearance: none;
    outline: none;
    box-shadow: none;
  }

  &:not(:placeholder-shown) {
    border-color: $white10;
    padding-right: 0;
    background: transparent;
  }

  &::placeholder {
    color: $white30;
    font-weight: inherit;
  }
}

