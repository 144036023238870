.comment-list {
  @at-root #{&}__comment {
    margin-top: $kbaseRem;

    @at-root #{&}--comment {
      margin: $kbaseRem*0.25 0;
      border-radius: 4px;
      background: $darkTwo;
      padding: $kbaseRem*0.5;
      white-space: pre-wrap;
    }
  }
}
