/* Media Queries */
$mobile: 321px !default;
$mobilePlus: 415px !default;
$tablet: 769px !default;
$tabletPlus: 1025px !default;
$desktop: 1281px !default;
$desktopPlus: 1441px !default;
$screen: 1601px !default;
$screenPlus: 1901px !default;

/* Type Setup */
$body-font-size: 14px;
$body-line-height: 1.333;
$scale-factor: 1.3; // check presets in knife.scss file
$ie8compatability: false;
$kbase: ceil($body-font-size * $body-line-height);
$kbaseRem: calculateRem($kbase);

// Common Size Values
$container-max-width: 50 * $kbaseRem;

/* Colours */
// Whites
$white: #ffffff;
$white70: rgba($white, 0.7);
$white60: rgba($white, 0.6);
$white50: rgba($white, 0.5);
$white40: rgba($white, 0.4);
$white30: rgba($white, 0.3);
$white20: rgba($white, 0.2);
$white10: rgba($white, 0.1);

// Blacks
$black: #000000;
$black50: rgba($black, 0.5);
$black20: rgba($black, 0.2);
$black10: rgba($black, 0.1);
$lightGrey: #cdcdce;
$darkGrey: #5c5f65;
$medGrey: #91949d;
$mapSeaBlack: #08090d;

// Blues
$dark: #23293a;
$dark50: rgba($dark, 0.5);
$dark60: rgba($dark, 0.6);
$darkTwo: #1d2332;
$darkTwo60: rgba($darkTwo, 0.6);
$darkThree: #3a4259;
$darkFour: #2f374a;
$darkFive: #151922;
$darkBlue: #22adcf;
$darkBlue30: rgba($darkBlue, 0.3);
$blue: #6fc6d9;
$blue30: rgba($blue, 0.3);

// Reds
$red: #f7245b;
$darkRed: #c81b74;
$pink: #f46cb3;
$darkPink: #c027c8;

// Bright
$orange: #ffa106;
$yellow: #f8e71c;
$purple: #a64aea;
$darkPurple: #5732b0;
$green: #98e456;
$darkGreen: #429321;

// Borders & Backgrounds
$darkBorder: 1px solid $white10;
$lightBorder: 1px solid $white30;
$blueBorder: 1px solid $blue;
$whiteBorder: 1px solid $white;
$darkBackground: $dark;
$activeDarkBackground: $darkTwo;
$borderRadius: 4px;

// Gradients
$darkGradient: linear-gradient(to top left, $dark, $darkFour);
$blueGradient: linear-gradient(to bottom, $blue, $darkBlue);
$blueGradient30: linear-gradient(to bottom, $blue30, $darkBlue30);
$blueGradientLeft: linear-gradient(to left, $blue, $darkBlue);
$greenGradient: linear-gradient(to bottom, $green, $darkGreen);
$greenGradientLeft: linear-gradient(to left, $green, $darkGreen);
$purpleGradient: linear-gradient(to bottom, $purple, $darkPurple);
$yellowGradient: linear-gradient(to bottom, $yellow, $orange);
$pinkGradient: linear-gradient(to bottom, $pink, $darkPink);
$redGradient: linear-gradient(to bottom, $red, $darkRed);
$greyGradient: linear-gradient(to bottom, $medGrey, $darkGrey);

// Shadows
$blockShadow: 4px 4px 20px 4px $black20;

// Buttons
$buttonColors: (
  yellow: (
    color: $yellow,
    hoverColor: $black,
    hoverGradient: $yellowGradient,
  ),
  green: (
    color: $green,
    hoverColor: $black,
    hoverGradient: $greenGradient,
  ),
  red: (
    color: $red,
    hoverColor: $white,
    hoverGradient: $redGradient,
  ),
  purple: (
    color: $purple,
    hoverColor: $white,
    hoverGradient: $purpleGradient,
  ),
  orange: (
    color: $orange,
    hoverColor: $black,
    hoverGradient: $yellowGradient,
  ),
  grey: (
    color: $white10,
    hoverColor: $white,
    hoverGradient: $blueGradient,
  ),
  white: (
    color: $white,
    hoverColor: $white,
    hoverGradient: $blueGradient,
  ),
);

:root {
  --bs-body-color: inherit;
}
