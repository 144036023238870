.modify{

    &__documents{
        width: 100%;
        overflow-y: auto;

        &__tickets{
            width: 100%;
        }
    }
    &-ticket{
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 4px;
        padding: 0.67857rem;
        margin-bottom: 0.67857rem;
        display: flex;
        flex-flow: column;

        &__header{
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }
}



