.ibox {
  clear: both;
  margin: $kbaseRem*0.5 0;
  padding: 0;
}

.ibox.collapsed .ibox-content {
  display: none;
}

.ibox.collapsed .fa.fa-chevron-up::before {
  content: '\f078';
}

.ibox.collapsed .fa.fa-chevron-down::before {
  content: '\f077';
}

.ibox::after,
.ibox::before {
  display: table;
}

.ibox-title {
  border: none;
  background-color: transparent;
  padding: $kbaseRem*0.5 0;
}

.ibox-content {
  background-image: $darkGradient;
  color: $white70;
  padding: 15px 20px 20px;
  border-radius: 4px;
  box-shadow: $blockShadow;
  min-height: 110px;
  clear: both;
  overflow: visible;
}

.ibox-content-no-shade {
  box-shadow: none;
}

.ibox-footer {
  color: inherit;
  border-top: 1px solid $white70;
  font-size: 90%;
  padding: 10px 15px;
}

.ui-draggable .ibox-title {
  cursor: move;
}

.ibox-heading {
  border-bottom: none;
}

.ibox-heading h3 {
  font-weight: 200;
  font-size: 24px;
}

.ibox-title h5 {
  display: inline-block;
  font-size: 14px;
  margin: 0 0 7px;
  padding: 0;
  text-overflow: ellipsis;
}

.ibox-tools {
  display: inline-block;
  margin-top: 0;
  position: relative;
  padding: 0;
}

.dropdown-menu {
  left: auto;
  right: 0;
  padding: 0;
  font-size: initial;
  background-color: $dark;
  border: $darkBorder;
  box-shadow: $blockShadow;

  .dropdown-item {
    padding: $kbaseRem*0.25 $kbaseRem*0.5;
    background: transparent;
    color: $white70;
    cursor: pointer;

    &:hover {
      background: $darkTwo;
      color: $blue;
    }
  }

  li {

    a {
      padding: $kbaseRem*0.25 $kbaseRem*0.5;
      background: transparent;
      color: $white70;
      cursor: pointer;

      &:hover {
        background: $darkTwo;
        color: $blue;
      }
    }
  }
}

.ibox-content h1,
.ibox-content h2,
.ibox-content h3,
.ibox-content h4,
.ibox-content h5,
.ibox-title h1,
.ibox-title h2,
.ibox-title h3,
.ibox-title h4,
.ibox-title h5 {
  margin-top: 5px;
}

.fullscreen-ibox-mode .animated {
  animation: none;
}

body.fullscreen-ibox-mode {
  overflow-y: hidden;
}

.ibox.fullscreen {
  z-index: 2030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin-bottom: 0;
}

.ibox.fullscreen .collapse-link {
  display: none;
}

.ibox.fullscreen .ibox-content {
  min-height: calc(100% - 48px);
}

.ibox-content.text-box {
  padding-bottom: 0px;
  padding-top: 15px;
}