input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0px solid transparent;
  -webkit-text-fill-color: $white;
  box-shadow: 0 0 0px 1000px $darkFour inset;
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent;
  border-radius: $borderRadius;
}
