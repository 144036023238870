.full-height {
  height: 100%;
}

.fh-breadcrumb {
  height: calc(100% - 196px);
  margin: 0 -15px;
  position: relative;
}

.fh-no-breadcrumb {
  height: calc(100% - 99px);
  margin: 0 -15px;
  position: relative;
}

.fh-column {
  background: $white;
  height: 100%;
  width: 240px;
  float: left;
}

.modal-backdrop {
  z-index: 2040 !important;
}

.modal {
  z-index: 2050 !important;
}

.spiner-example {
  height: 200px;
  padding-top: 70px;
}
