.file-box {
  float: left;
  width: 220px;
}

.file-manager h5 {
  text-transform: uppercase;
}

.file-manager {
  list-style: none outside none;
  margin: 0;
  padding: 0;
}

.folder-list li a {
  color: #666666;
  display: block;
  padding: 5px 0;
}

.folder-list li {
  border-bottom: 1px solid $white70;
  display: block;
}

.folder-list li i {
  margin-right: 8px;
  color: #3d4d5d;
}

.category-list li a {
  color: #666666;
  display: block;
  padding: 5px 0;
}

.category-list li {
  display: block;
}

.category-list li i {
  margin-right: 8px;
  color: #3d4d5d;
}

.category-list li a .text-navy {
  color: $green;
}

.category-list li a .text-primary {
  color: $blue;
}

.category-list li a .text-info {
  color: #23c6c8;
}

.category-list li a .text-danger {
  color: #ef5352;
}

.category-list li a .text-warning {
  color: $orange;
}

.file-manager h5.tag-title {
  margin-top: 20px;
}

.tag-list li {
  float: left;
}

.tag-list li a {
  font-size: 10px;
  background-color: #f3f3f4;
  padding: 5px 12px;
  color: inherit;
  border-radius: 2px;
  border: 1px solid $white70;
  margin-right: 5px;
  margin-top: 5px;
  display: block;
}

.file {
  border: 1px solid $white70;
  padding: 0;
  background-color: $white;
  position: relative;
  margin-bottom: 20px;
  margin-right: 20px;
}

.file .icon,
.file .image {
  height: 100px;
  overflow: hidden;
}

.file .icon {
  padding: 15px 10px;
  text-align: center;
}

.file-control {
  color: inherit;
  font-size: 11px;
  margin-right: 10px;
}

.file-control.active {
  text-decoration: underline;
}

.file .icon i {
  font-size: 70px;
  color: #dadada;
}

.file .file-name {
  padding: 10px;
  background-color: #f8f8f8;
  border-top: 1px solid $white70;
}

.file-name small {
  color: #676a6c;
}

.corner {
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  line-height: 0;
  border: 0.6em solid transparent;
  border-right: 0.6em solid #f1f1f1;
  border-bottom: 0.6em solid #f1f1f1;
  right: 0em;
  bottom: 0em;
}

a.compose-mail {
  padding: 8px 10px;
}

.mail-search {
  max-width: 300px;
}
