.notarization{
    &__block{
        padding-top: 3px;
        display: inline-block;

        &-title{
            padding-left: 10px;
        }
    }

    
}