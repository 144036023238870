.sweet-alert {
  position: fixed;
  z-index: 2101;
  background: $darkTwo;
  width: 80%;
  margin: 22% 10%;
  margin-top: initial !important;
  padding: 2rem;
  border-radius: 4px;
  box-shadow: $blockShadow;
  text-align: center;
  outline: none;
  border: $darkBorder;
  top : 45%;
  overflow-y: auto;
  max-height: 50vh;

  h2 {
    max-width: 100%;
    overflow-wrap: break-word;
  }

  @media screen and (min-width: $tablet) {
    width: 60%;
    margin: 22% 20%;
  }

  .form-group {
    display: none;
  }

  .sa-button-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-evenly;

    @media screen and (min-width: $tablet) {
      width: 50%;
      margin: 0 auto;
    }

    button {
      outline: none;
    }
  }
}

.sweet-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2100;
  background: $black50;
  width: 100vw;
  height: 100vh;
}
