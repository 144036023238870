.stream {
  position: relative;
  padding: 10px 0;
}

.stream:first-child .stream-badge::before {
  top: 10px;
}

.stream:last-child .stream-badge::before {
  height: 30px;
}

.stream .stream-badge {
  width: 50px;
}

.stream .stream-badge i {
  border: 1px solid $white70;
  border-radius: 50%;
  padding: 6px;
  color: #808486;
  position: absolute;
  background-color: $white;
  left: 8px;
}

.stream .stream-badge i.fa-circle {
  color: #ced0d1;
}

.stream .stream-badge i.bg-success {
  color: $white;
  background-color: $blue;
  border-color: $blue;
}

.stream .stream-badge i.bg-primary {
  color: $white;
  background-color: $green;
  border-color: $green;
}

.stream .stream-badge i.bg-warning {
  color: $white;
  background-color: $orange;
  border-color: $orange;
}

.stream .stream-badge i.bg-info {
  color: $white;
  background-color: #23c6c8;
  border-color: #23c6c8;
}

.stream .stream-badge i.bg-danger {
  color: $white;
  background-color: $red;
  border-color: $red;
}

.stream .stream-badge::before {
  content: '';
  width: 1px;
  background-color: $white70;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
}

.stream .stream-info {
  font-size: 12px;
  margin-bottom: 5px;
}

.stream .stream-info img {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin-right: 2px;
  margin-top: -4px;
}

.stream .stream-info .date {
  color: #9a9d9f;
  font-size: 80%;
}

.stream .stream-panel {
  margin-left: 55px;
}

.stream-small {
  margin: 10px 0;
}

.stream-small .label {
  padding: 2px 6px;
  margin-right: 2px;
}
