.sortable-list {
  padding: 10px 0;
}

.agile-list {
  list-style: none;
  margin: 0;
}

.agile-list li {
  border: 1px solid $white30;
  margin: 0px 0 10px 0;
  padding: 10px;
  border-radius: 2px;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.agile-list li:hover {
  cursor: grab;
}

.agile-list li.warning-element {
  border-left: 3px solid $orange;
}

.agile-list li.danger-element {
  border-left: 3px solid $red;
}

.agile-list li.info-element {
  border-left: 3px solid $blue;
}

.agile-list li.success-element {
  border-left: 3px solid $green;
}

.agile-detail {
  margin-top: 5px;
  font-size: 12px;
}