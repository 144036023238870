.button {
  font-size: 16px;
  color: $blue;
  appearance: none;
  outline: none;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  vertical-align: middle;
  background: transparent;
  border: 1px solid;
  border-radius: $borderRadius;
  padding: 0 $kbaseRem;
  margin: 0 $kbaseRem*0.25 $kbaseRem*0.5;
  height: $kbaseRem * 2.25;
  width: auto;
  position: relative;
  border-color: $blue;
  overflow: hidden;
  background-origin: border-box;
  flex: 0 0 auto;

  .icon {
    height: 100%;
    width: initial;
    min-width: 1em;
    vertical-align: initial;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $kbaseRem * 0.75;

    &--fill {
      display: inline-block;
      min-height: 1em;
      min-width: 1em;
    }
  }

  &:hover,
  &:active,
  &.button--active,
  &.hover,
  &.active {
    color: $white;
    background-image: $blueGradient;
    border-color: transparent;
    outline: none;

    .icon {
      color: $white;

      .icon--fill {
        fill: $white;
      }
    }
  }

  &__text {
    height: 100%;
    width: 100%;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    .icon {
      .icon--fill {
        fill: $blue;
      }
    }
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
  }

  &--inverse {
    border: 1px solid;
    border-color: transparent;
    color: $white;
    background-image: $blueGradient;

    &:hover,
    &:active,
    &.button--active {
      background-image: none;
      background-color: $blue;
    }

    .icon {
      color: $white;

      .icon--fill {
        fill: $white;
      }
    }

    .icon::before {
      width: $kbaseRem * 0.8;
      height: $kbaseRem * 0.8;
    }
  }

  // Colours
  &--yellow {
    @include buttonColor(yellow);
  }

  &--green {
    @include buttonColor(green);
  }

  &--red {
    @include buttonColor(red);
  }

  &--purple {
    @include buttonColor(purple);
  }

  &--orange {
    @include buttonColor(orange);
  }

  &--grey {
    @include buttonColor(grey);
  }

  &--white {
    @include buttonColor(white);
  }

  // Themes
  &--full-width {
    width: 100%;
    margin: 0 0 $kbaseRem*0.5;
  }

  &--small {
    font-size: 12px;
    padding: 0 $kbaseRem * 0.5;
    margin: 0 $kbaseRem*0.25 $kbaseRem*0.25;
    height: $kbaseRem * 1.25;

    .icon--margin-right {
      margin-right: $kbaseRem * 0.25;
    }

    .icon--margin-left {
      margin-left: $kbaseRem * 0.25;
    }

    &--square {
      padding: 0;
      width: $kbaseRem * 1.25;
      height: $kbaseRem * 1.25;
      margin: 0 $kbaseRem*0.25 $kbaseRem*0.25;

      .icon {
        &--fill {
          width: 75%;
        }
      }
    }
  }

  &--square {
    width: $kbaseRem * 2.25;
    height: $kbaseRem * 2.25;
    padding: $kbaseRem * 0.25;

    .icon {
      &--fill {
        width: 80%;
      }
    }
  }

  &--tall {
    width: 100%;
    min-height: $kbaseRem * 7;
  }

  &--rounded {
    border-radius: $kbaseRem * 10; //Oversized radius for the element results in perfect pill shape that's not reliant on width or height.
  }

  &--small-rounded {
    font-size: 12px;
    border-radius: $kbaseRem * 2; //Oversized radius for the element results in perfect pill shape that's not reliant on width or height.
    padding: 0 8px;
    margin: 0 $kbaseRem*0.25 $kbaseRem*0.25;
    height: $kbaseRem * 1.25;
    text-transform: none;

    .icon--margin-right {
      margin-right: $kbaseRem*0.25;
    }

    .icon--margin-left {
      margin-left: $kbaseRem*0.25;
    }
  }

  &--borderless {
    border-color: transparent;
    background-image: none;

    &:hover,
    &:active {
      background-image: none;
      color: $white;

      .icon {
        color: $white;

        .icon--fill {
          fill: $white;
        }
      }
    }

    &--square {
      @extend .button--borderless;
      width: $kbaseRem * 2.25;
      height: $kbaseRem * 2.25;
    }
  }

  &--label-type {
    font-size: 11px;
    // fixes issue with using button--label-type on spans
    line-height: 2;
    margin: 0 $kbaseRem*0.25;
    height: $kbaseRem * 1.25;
    text-transform: none;

    &:hover,
    &:active {
      color: inherit;
      border-color: $white;
      background-image: none;
    }

    .icon--margin-right {
      margin-right: $kbaseRem*0.25;
    }
    .icon--margin-left {
      margin-left: $kbaseRem*0.25;
    }
  }

  &--no-margin-bottom {
    margin-bottom: 0;
  }
}

// Bootstrap Button styling override
.btn {
  @include ktype(0, 0, 0, -1);

  color: $blue;
  -webkit-appearance: none;
  outline: none;
  display: inline-block;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  vertical-align: middle;
  background: transparent;
  border: 1px solid;
  border-radius: 4px;
  padding: 0 14px;
  margin: 0 $kbaseRem*0.25 $kbaseRem*0.5;
  height: $kbaseRem * 2.25;
  width: auto;

  &:hover,
  &:active {
    color: $white;
    background-image: $blueGradient;
    border: none;
    padding: 0 15px;
    -webkit-appearance: none;
    outline: none;
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
  }

  &-default {
    color: $white;
    background-image: $blueGradient;

    .icon {
      color: $white;

      .icon--fill {
        fill: $white;
      }
    }

    &:hover,
    &:active {
      background-image: none;
      background-color: $blue;

      .icon {
        color: $blue;

        .icon--fill {
          fill: $blue;
        }
      }
    }
  }

  &-primary {
    color: $blue;

    .icon {
      color: $blue;

      .icon--fill {
        fill: $blue;
      }
    }

    &:hover,
    &:active {
      background-image: $blueGradient;
      color: $white;

      .icon {
        color: $white;

        .icon--fill {
          fill: $white;
        }
      }
    }
  }

  &-success {
    color: $green;

    .icon {
      color: $green;

      .icon--fill {
        fill: $green;
      }
    }

    &:hover,
    &:active {
      color: $black;
      background-image: $greenGradient;

      .icon {
        color: $black;

        .icon--fill {
          fill: $black;
        }
      }
    }
  }

  &-info {
    color: $yellow;

    .icon {
      color: $yellow;

      .icon--fill {
        fill: $yellow;
      }
    }

    &:hover,
    &:active {
      color: $black;
      background-image: $yellowGradient;

      .icon {
        color: $black;

        .icon--fill {
          fill: $black;
        }
      }
    }
  }

  &-warning {
    color: $orange;

    .icon {
      color: $orange;

      .icon--fill {
        fill: $orange;
      }
    }

    &:hover,
    &:active {
      color: $black;
      background-image: $yellowGradient;

      .icon {
        color: $black;

        .icon--fill {
          fill: $black;
        }
      }
    }
  }

  &-danger {
    color: $red;

    .icon {
      color: $red;

      .icon--fill {
        fill: $red;
      }
    }

    &:hover,
    &:active {
      color: $black;
      background-image: $redGradient;

      .icon {
        color: $black;

        .icon--fill {
          fill: $black;
        }
      }
    }
  }

  &-xs {
    @include ktype(-1, 0, 0);

    border-radius: 3px;
    padding: 0 7px !important;
    margin: 0 $kbaseRem*0.25 $kbaseRem*0.25 !important;
    height: $kbaseRem * 1.25;
    text-transform: none;

    &:hover,
    &:active {
      padding: 0 7px !important;
    }

    .icon.fa {
      line-height: 1.8;
    }
  }
}

.infobutton {
  font-size: 16px;
  color: $blue;
  appearance: none;
  outline: none;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  vertical-align: middle;
  background: transparent;
  border: 1px solid;
  border-radius: $borderRadius;
  padding: 0 $kbaseRem;
  margin: 0 $kbaseRem*0.25 $kbaseRem*0.5;
  height: $kbaseRem * 2.25;
  width: auto;
  position: relative;
  border-color: $blue;
  overflow: visible;
  background-origin: border-box;
  flex: 0 0 auto;

  .icon {
    height: 100%;
    width: initial;
    min-width: 1em;
    vertical-align: initial;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $kbaseRem * 0.75;

    &--fill {
      display: inline-block;
      min-height: 1em;
      min-width: 1em;
    }
  }

  &:hover,
  &:active,
  &.infobutton--active,
  &.hover,
  &.active {
    color: $white;
    background-image: $blueGradient;
    border-color: transparent;
    outline: none;

    .icon {
      color: $white;

      .icon--fill {
        fill: $white;
      }
    }
  }

  &__text {
    height: 100%;
    width: 100%;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    .icon {
      .icon--fill {
        fill: $blue;
      }
    }
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
  }

  &--inverse {
    border: 1px solid;
    border-color: transparent;
    color: $white;
    background-image: $blueGradient;

    &:hover,
    &:active,
    &.infobutton--active {
      background-image: none;
      background-color: $blue;
    }

    .icon {
      color: $white;

      .icon--fill {
        fill: $white;
      }
    }

    .icon::before {
      width: $kbaseRem * 0.8;
      height: $kbaseRem * 0.8;
    }
  }

  // Colours
  &--yellow {
    @include buttonColor(yellow);
  }

  &--green {
    @include buttonColor(green);
  }

  &--red {
    @include buttonColor(red);
  }

  &--purple {
    @include buttonColor(purple);
  }

  &--orange {
    @include buttonColor(orange);
  }

  &--grey {
    @include buttonColor(grey);
  }

  &--white {
    @include buttonColor(white);
  }

  // Themes
  &--full-width {
    width: 100%;
    margin: 0 0 $kbaseRem*0.5;
  }

  &--small {
    font-size: 12px;
    padding: 0 $kbaseRem * 0.5;
    margin: 0 $kbaseRem*0.25 $kbaseRem*0.25;
    height: $kbaseRem * 1.25;

    .icon--margin-right {
      margin-right: $kbaseRem * 0.25;
    }

    .icon--margin-left {
      margin-left: $kbaseRem * 0.25;
    }

    &--square {
      padding: 0;
      width: $kbaseRem * 1.25;
      height: $kbaseRem * 1.25;
      margin: 0 $kbaseRem*0.25 $kbaseRem*0.25;

      .icon {
        &--fill {
          width: 75%;
        }
      }
    }
  }

  &--square {
    width: $kbaseRem * 2.25;
    height: $kbaseRem * 2.25;
    padding: $kbaseRem * 0.25;

    .icon {
      &--fill {
        width: 80%;
      }
    }
  }

  &--tall {
    width: 100%;
    min-height: $kbaseRem * 7;
  }

  &--rounded {
    border-radius: $kbaseRem * 10; //Oversized radius for the element results in perfect pill shape that's not reliant on width or height.
  }

  &--small-rounded {
    font-size: 12px;
    border-radius: $kbaseRem * 2; //Oversized radius for the element results in perfect pill shape that's not reliant on width or height.
    padding: 0 8px;
    margin: 0 $kbaseRem*0.25 $kbaseRem*0.25;
    height: $kbaseRem * 1.25;
    text-transform: none;

    .icon--margin-right {
      margin-right: $kbaseRem*0.25;
    }

    .icon--margin-left {
      margin-left: $kbaseRem*0.25;
    }
  }

  &--borderless {
    border-color: transparent;
    background-image: none;

    &:hover,
    &:active {
      background-image: none;
      color: $white;

      .icon {
        color: $white;

        .icon--fill {
          fill: $white;
        }
      }
    }

    &--square {
      @extend .infobutton--borderless;
      width: $kbaseRem * 2.25;
      height: $kbaseRem * 2.25;
    }
  }

  &--label-type {
    font-size: 11px;
    line-height: 2;
    margin: 0 $kbaseRem*0.25;
    height: $kbaseRem * 1.25;
    text-transform: none;

    &:hover,
    &:active {
      color: inherit;
      border-color: $white;
      background-image: none;
    }

    .icon--margin-right {
      margin-right: $kbaseRem*0.25;
    }
    .icon--margin-left {
      margin-left: $kbaseRem*0.25;
    }
  }

  &--no-margin-bottom {
    margin-bottom: 0;
  }
}

.infobutton-topLeft-circle {
  position: absolute;
  top: -10px;
  left: -10px;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infobutton-topRight-circle {
  position: absolute;
  top: -10px;
  right: -10px;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infobutton-bottomLeft-circle {
  position: absolute;
  bottom: -10px;
  left: -10px;
  background-color: red;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infobutton-bottomRight-circle {
  position: absolute;
  bottom: -10px; /* Adjusted to position at the bottom */
  right: -10px; /* Adjusted to position at the right */
  background-color: green;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.red-bgc {
  background-color: red;
}

.blue-bgc {
  background-color: blue;
}

.green-bgc {
  background-color: green;
}

.yellow-bgc {
  background-color: yellow;
  color: black;
}

.orange-bgc {
  background-color: orange;
}