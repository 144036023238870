.modal-form {
  display: grid;
  grid-template-columns: 1fr [end];
  grid-auto-rows: min-content;
  grid-column-gap: $kbaseRem;
  grid-row-gap: $kbaseRem;

  @media screen and (min-width: $mobilePlus) {
    grid-template-columns: 1fr [start] 1fr [end];
    grid-auto-rows: min-content;
  }

  &__full-width {
    width: 100%;
    grid-column-start: 1;
    grid-column-end: end;
  }

  &__spacer {
    &--hidden {
      content: '';
      display: none;
    }

    @for $i from 1 through 10 {
      &--height-#{$i} {
        grid-row-start: auto;
        grid-row-end: span #{$i};
      }
    }

    @media screen and (min-width: $mobilePlus) {
      display: block;

      &--hidden {
        display: block;
        visibility: hidden;
      }
    }
  }

  &__section-title {
    grid-column-start: 1;
    grid-column-end: end;
    color: $white;
    font-weight: 500;
    font-size: $kbaseRem * 0.75;
  }

  &__break {
    text-align: center;
    grid-column-start: 1;
    grid-column-end: end;
    width: 100%;
    border-top: solid 1px $white10;
    margin: 0;
    padding: 0;

    &--no-margin {
      // must be the exact amount of the padding from the modal * 2
      width: calc(100% + 1.35714rem * 2);
      margin-left: -$kbaseRem;
      margin-right: -$kbaseRem;

      @media screen and (min-width: $tablet) {
        width: calc(100% + 2.03571rem * 2);
        margin-left: -$kbaseRem * 1.5;
        margin-right: -$kbaseRem * 1.5;
      }
    }
  }
}

/** Bootstrap overrides */
.modal {
  &-dialog {
    z-index: 2200;

    @media screen and (min-width: $tablet) {
      width: 690px !important;
      margin: $kbaseRem auto;
    }
  }

  &-header {
    border: none;
    padding: $kbaseRem $kbaseRem 0 $kbaseRem;

    @media screen and (min-width: $tablet) {
      padding: $kbaseRem * 1.5 $kbaseRem * 1.5 0 $kbaseRem * 1.5;
    }
  }

  &-title {
    font-size: $kbaseRem;
    text-transform: uppercase;
    color: $white;
  }

  &-content {
    background-clip: padding-box;
    border: $darkBorder;
    border-radius: 4px;
    box-shadow: $blockShadow;
    outline: 0 none;
    position: relative;
    background-color: transparent;
    background-image: $darkGradient;
    padding: 0;
  }

  &-body {
    padding: $kbaseRem;

    @media screen and (min-width: $mobilePlus) {
      padding: $kbaseRem * 1.5;
    }
  }

  &-footer {
    margin-top: 0;
    padding: $kbaseRem;
    text-align: right;
    border-top: solid 1px $darkTwo;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    &::before,
    &::after {
      content: none;
    }

    &>* {
      width: 100%;
    }

    @media screen and (min-width: $mobilePlus) {
      padding: $kbaseRem * 1.5;
      justify-content: space-between;

      &>* {
        width: auto;
      }

      &>*:only-child {
        margin-left: auto;
      }

      &>*:nth-child(2) {
        margin-left: auto;
      }
    }
  }
}

.animated.modal.fade .modal-dialog {
  transform: none;
}

.modal-fullscreen-sm-down {
  height: auto !important;
}

.modal-dialog {
  min-width: 50%;
  overflow-y: visible;
}

// TODO: Uncomment if padding screwed up with modal somewhere
// body.modal-open {
//   padding-right: inherit !important;
// }

body.modal-open .animated {
  animation-fill-mode: initial;
}

/** Individual Modals */
// Eventually move away from these
.contest-modal {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;

  .input__select,
  .input {
    width: 48%;
  }
}

.payment-into-profile-modal {
  padding-bottom: $kbaseRem*5;
}

.create-invoice-modal {
  @at-root #{&}__form {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: $kbaseRem;

    &__select {
      flex-grow: 1;
      margin-right: $kbaseRem*0.5;
    }

    .input,
    .form-input__datepicker {
      width: 48%;
    }
  }

  .table-list__header-row--desktop,
  .table-list__body-block {
    grid-template-columns: 10% 15% 20% 20% 15% 10% 10% !important;
  }

  .badge {
    margin-bottom: $kbaseRem*0.25;
    margin-left: 0;

    // status = New
    &.badge--status-0 {
      color: $yellow;
    }

    // status = Reviewed
    &.badge--status-1 {
      color: $green;
    }

    // status = Confirmed
    &.badge--status-2 {
      color: $green;
    }

    // status = Unconfirmed
    &.badge--status-3 {
      color: $yellow;
    }

    // status = Disputed
    &.badge--status-4 {
      color: $red;
    }

    // status = Billed
    &.badge--status-21 {
      color: $blue;
    }

    // status = Paid/Settled
    &.badge--status-22,
    &.badge--status-23 {
      color: $purple;
    }
  }
}

.edit-billing-details-modal {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (min-width: $mobilePlus) {
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  @at-root #{&}__column {
    width: 100%;
    padding: 0;

    @media screen and (min-width: $mobilePlus) {
      width: 50%;
      padding-right: $kbaseRem * 2;

      &:last-of-type {
        padding-right: 0;
      }
    }
  }
}

.inmodal .modal-body {
  background: #f8fafb;
}

.inmodal .modal-header {
  text-align: center;
}

.inmodal .modal-title {
  font-size: 26px;
}

.inmodal .modal-icon {
  font-size: 84px;
  color: #e2e3e3;
}

.diversion {
  padding: $kbaseRem 0;
  font-weight: 300;
  border-top: $darkBorder;

  @at-root #{&}__title {
    font-weight: bold;
  }
}