.modify-modal {
  .modal-body {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .full-width-flex {
      display: flex;
      flex-basis: 100%;
      justify-content: space-between;
    }

    .input__select,
    .vertical-container,
    .input,
    .form-input__datepicker {
      flex: 0 0 48%;
      width: 48%;
      margin-bottom: $kbaseRem * 1.5;
    }

    .vertical-container {
      margin: unset;
      margin-bottom: 0;
    }
  }

  .modal-footer {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;

    .margin-right--none {
      margin-right: 0;
    }
  }
}
