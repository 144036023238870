.overlay {
  will-change: opacity;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 101;
  background: $black50;
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.325s ease-in-out;

  @media screen and (min-width: $tablet) {
    display: none;
  }
}
