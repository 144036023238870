.rbc-toolbar {
  button {
    @include ktype(0, 0, 0);

    text-transform: capitalize;
    color: $blue;
    border-color: $blue;

    &:hover,
    &:active,
    &:focus {
      color: $white;
      border-color: $white;
      background: transparent;
      outline: none;
    }
  }

  .rbc-toolbar-label {
    @include ktype(1, 0, 0);

    font-weight: 500;
    color: $white;
  }
}

.rbc-time-view {
  border: $darkBorder;

  .rbc-time-header-content {
    border-left: $darkBorder
  }

  .rbc-today {
    border-bottom: $darkBorder;
    background-color: transparent;
  }
}

.rbc-time-content {
  border-top: $darkBorder;

  > * + * > * {
    border-left: $darkBorder;
  }

  .rbc-timeslot-group {
    border-bottom: $darkBorder;

    .rbc-time-slot {
      border-top: $darkBorder;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
}

.rbc-header {
  border-bottom: $darkBorder;

  + .rbc-header {
    border-left: $darkBorder;
  }
}

.rbc-current-time-indicator {
  height: 2px;
  background-color: $blue;
}
