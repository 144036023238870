.filters {
  position: relative;
  z-index: 1;
  padding-bottom: $kbaseRem;
  margin-bottom: $kbaseRem;
  border-bottom: $darkBorder;

  @at-root #{&}__top-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  @at-root #{&}__form-row {
    background: $dark;
    border: $darkBorder;
    border-radius: 4px;
    padding: 0;
    
    max-height: 0;
    opacity: 0;
    transition: max-height 0.25s cubic-bezier(0, 1, 0, 1), opacity 0.25s ease;
    overflow: hidden;

    @at-root #{&}--open {
      padding: $kbaseRem*0.25 $kbaseRem*0.125 0;
      margin-top: $kbaseRem*0.5;
      
      max-height: 1000px;
      opacity: 1;
      transition: max-height 0.5s ease-in-out, opacity 0.5s ease;
      overflow: visible;

      @media screen and (min-width: $tablet) {
        padding: $kbaseRem*0.5 $kbaseRem*0.25 0;
      }
    }

    .button {
      margin-bottom: $kbaseRem*0.5;
    }
  }

  @at-root #{&}__form {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  @at-root #{&}__item {
    width: 100%;
    padding: 0 $kbaseRem*0.25;

    @media screen and (min-width: $tablet) {
      width: 20%;
    }

    @at-root #{&}--quarter {
      @media screen and (min-width: $tablet) {
        width: 25%;
      }
    }
  }

  @at-root #{&}__active {
    .button {
      margin-bottom: 0;
    }

    .label {
      height: $kbaseRem * 1.25;
    }
  }
}