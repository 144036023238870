.search-result h3 {
  margin-bottom: 0;
}

.search-result .search-link {
  color: #006621;
}

.search-result p {
  font-size: 12px;
  margin-top: 5px;
}
