.roles {
  @media screen and (min-width: $desktop) {
    .slide-panel {
      width: 670px;
      max-width: 65%;
      padding: $kbaseRem 0;
    }
  }

  &__help-icon {
    position: absolute;
    top: -60px;
    right: -34px;
  }

  &__body {
    padding: 0 $kbaseRem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding-top: $kbaseRem;
    border-top: $darkBorder;
  }
}

.roles-select {
  width: 200px;
}

// Add Edit Role Component
.add-edit-role {
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 0 $kbaseRem $kbaseRem;
    border-bottom: $darkBorder;

    &__title {
      font-size: $kbaseRem * 1.25;
      line-height: 1em;
      color: $white;
      font-weight: 300;
      text-transform: capitalize;
    }
  }

  &__inputs {
    display: flex;
    justify-content: space-between;
    border-bottom: $darkBorder;
    margin-bottom: $kbaseRem * 0.5;
    padding: $kbaseRem;
    .modal-form {
      width: 100%;
    }
  }

  &__permissions {
    padding: 0 $kbaseRem;
  }

  .add-edit-role-row {
    display: grid;
    border-bottom: $darkBorder;
    padding: $kbaseRem*0.5 0;
    width: 100%;
    grid-template-columns: min-content min-content 1fr;
    grid-template-areas:
      'arrow pc pt'
      '. . pd'
      '. . cw';

    &:last-child() {
      border-bottom: none;
    }

    &__arrow {
      grid-area: arrow;

      &--open {
        transform: rotate(0deg);
      }
      &--hidden {
        transform: rotate(-90deg);
      }
    }

    &__parent-checkbox {
      grid-area: pc;
      padding-left: $kbaseRem * 0.25;
    }

    &__permission-title {
      grid-area: pt;
      font-weight: 300;
      font-size: $kbaseRem;
      padding-left: $kbaseRem * 0.5;
      cursor: default;
    }

    &__permission-description {
      grid-area: pd;
      font-size: $kbaseRem * 0.75;
      font-weight: 300;
      padding: $kbaseRem * 0.25 $kbaseRem * 0.5;
      cursor: default;
    }

    &__checkboxes-container {
      grid-area: cw;
      padding-left: $kbaseRem * 0.5;
      width: 100%;

      .checkbox-wrapper {
        padding: $kbaseRem * 0.5 $kbaseRem * 0.5;
        border-bottom: $darkBorder;
        &:last-child() {
          border-bottom: none;
        }
      }

      &--visible {
        display: block;
      }

      &--hidden {
        display: none;
      }
    }
  }

  &__footer {
    border-top: $darkBorder;
    padding: $kbaseRem;
    display: flex;
    justify-content: space-between;
  }
}

// Roles List Component
.roles-list {
  .expanded-row {
    &--visible {
      .secondary-row {
        display: table-row;
      }
    }
    &--hidden {
      .secondary-row {
        display: none;
      }
    }
  }

  .main-table,
  .nested-table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    table-layout: auto;
  }

  &__header {
    border-bottom: $darkBorder;

    &__title {
      width: $kbaseRem * 5;
      white-space: nowrap;
      display: block;
      font-weight: 400;
      // font-size: $kbaseRem;
      padding: 0 $kbaseRem*0.5;
      text-overflow: ellipsis;
      overflow: hidden;
      text-transform: capitalize;
      text-align: center;
    }
  }

  &__body {
    // set the height of the rows, keeps everything lined up
    tr,
    td {
      height: $kbaseRem * 2;
    }
  }

  &__footer {
    tr,
    td {
      height: $kbaseRem * 2;
    }
    .button {
      margin: 0;
    }

    &__more-options {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.roles-list-row {
  &__arrow {
    &--open {
      transform: rotate(0deg);
    }
    &--hidden {
      transform: rotate(-90deg);
    }
  }

  .nested-table {
    border-bottom: $darkBorder;
    .secondary-row {
      border-top: $darkBorder;
    }
  }

  &__permission-title {
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-size: $kbaseRem * 0.75;
    font-weight: 400;
    cursor: default;
    user-select: none;
    text-transform: capitalize;

    .icon {
      height: 1em;
      width: 1em;
    }
  }

  &__grant-title {
    font-size: $kbaseRem * 0.75;
    font-weight: 300;
    display: block;
    padding-left: 2em;
    text-transform: capitalize;
  }

  .roles-list-checkboxes {
    &__checkbox-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__spacer {
    width: 100%;
  }
}
