.pie-chart {
    position: relative;
  
    &__total {
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      text-align: center;
      font-size: $kbaseRem * 7;
      width: 100%;
    }
  }