.page {
  position: relative;
  width: 100%;
  min-height: 100%;

  &__header {
    width: 100%;
    padding-bottom: $kbaseRem;
    display: grid;
    align-items: center;
    grid-template-columns: min-content 1fr;
    grid-template-rows: repeat(3, min-content);
    grid-template-areas:
      'back-button title'
      '. subtitle'
      'actions actions';

    &__back-button {
      grid-area: back-button;
      padding-right: $kbaseRem * 0.5;
      .button {
        margin: 0;
      }
    }

    &__title {
      grid-area: title;
      width: 100%;
      font-size: $kbaseRem * 1.25;
      line-height: 1em;
      color: $white;
      font-weight: 300;
      text-transform: capitalize;
    }

    &__subtitle {
      grid-area: subtitle;
      color: $white70;
      font-size: $kbaseRem;
      line-height: 1em;
      font-weight: normal;
      margin-top: $kbaseRem * 0.5;

      strong {
        font-weight: normal;
        color: $white;
      }
    }

    &__actions {
      grid-area: actions;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: $kbaseRem;

      .button,
      .dropdown-button,
      .input {
        margin: 0;
        width: 100%;
        .dropdown-button__list {
          width: 100%;
        }
      }
      & > *:not(:first-child) {
        margin: $kbaseRem*0.25 0 0 0;
      }
    }

    @media screen and (min-width: $mobilePlus) {
      grid-template-columns: min-content 1fr min-content;
      grid-template-rows: repeat(2, min-content);
      grid-template-areas:
        'back-button title actions'
        '. subtitle subtitle';

      .page__header__back-button {
        padding-right: $kbaseRem;
      }

      .page__header__title {
        font-size: $kbaseRem * 1.75;
      }

      .page__header__actions {
        flex-direction: row;
        margin-top: 0;
        // removes all margin-top from mobile;
        & > * {
          margin-top: 0;
        }
        // removes all Button, Dropdown, and Input margins that might break layout
        & > *:not(:last-child) {
          margin-top: 0;
          margin-bottom: 0;
          margin-left: 0;
          margin-right: $kbaseRem*0.5;
        }

        .button {
          width: initial;
        }
      }
    }
  }

  &__loader {
    width: 100px;
    text-align: center;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
