.dashboard-header {
  border-top: 0;
  padding: 20px 20px 20px 20px;
}

.dashboard-header h2 {
  margin-top: 10px;
  font-size: 26px;
}

.fist-item {
  border-top: none !important;
}

.statistic-box {
  margin-top: 40px;
}

.dashboard-header .list-group-item span.label {
  margin-right: 10px;
}

.list-group.clear-list .list-group-item {
  border-top: 1px solid $white70;
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  padding: 10px 0;
}

ul.clear-list:first-child {
  border-top: none !important;
}
