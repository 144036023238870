.img-container,
.img-preview {
  overflow: hidden;
  text-align: center;
  width: 100%;
}

.img-preview-sm {
  height: 130px;
  width: 200px;
}
