.ibox-order {
  margin-bottom: 5px;
}

.ibox-content-order,
.ibox-content-customer {
  clear: both;
  padding: 0 0 $kbaseRem*0.5;
  background-color: transparent;
  color: inherit;
  border-bottom: $darkBorder;
}

.ibox-footer {
  font-size: 12px;
  padding: 20px 15px;
}

/* END ORDER.TEMPLATE*/
.stage-list {
  margin-left: 25px;
}

.timeline-item .left-part {
  position: relative;
}

.timeline-item .left-part i {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  width: 100px;
  text-align: center;
  border: 1px solid $white30;
  border-right: none;
  color: $white70;
}

.timeline-item .left-part {
  text-align: right;
  padding-top: 30px;
  padding-left: 0px;
  padding-right: 5px;
}

.timeline-item .right-part {
  border: 1px solid $white30;
  border-bottom: none;
  padding-top: 10px;
  min-height: 100px;
  padding-right: 15px;
}

.timeline-item:last-of-type .right-part {
  border-bottom: 1px solid $white30;
}

.text-demurrage {
  width: 95%;
  border: 1px solid darkgrey;
  border-radius: 5px;
  padding: 5px;
}

.certificate-badge {
  padding: 6px;
  border-radius: 50%;
  margin-right: 10px;
}

.order-warning {
  float: left;
  font-size: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.carier-logo {
  /*width: 50px!important;*/
  height: 30px !important;
  display: inline-block;
}

.name-driver {
  font-size: 20px;
}

.contact-box .row {
  margin-top: 10px;
}

.profile-photo {
  margin: 0 auto;
  height: 80px;
  width: 70px;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 1px 1px 5px 0px #1d2332;
}

.driver-content {
  height: 275px;
  padding-left: 0px;
  padding-right: 0px;
  position: relative;
}

.driver-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.driver-footer button {
  width: 33%;
  padding: 0;
}

.icon-style {
  border: 1px solid rgba(128, 128, 128, 0.45);
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 2px;
}

.driver-right .row {
  margin-left: 0px;
}

.filter-drivers {
  display: inline-block;
}

.online-driver-status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}

.margin-title {
  margin-right: 20px !important;
  margin-top: 10px !important;
}

.tabs-container .tabs-right .tab-pane .panel-body {
  border-top: 1px solid $white70;
  border-left: none;
  /*border-bottom:none;*/
  padding: 0px;
}

.buttons-customer {
  padding-right: 15px;
}

.tabs-container .tabs-right > .nav-tabs {
  width: 10% !important;
}

.item-well {
  border-bottom: 1px solid #e9ebed;
  margin: 5px 10px 0px 0px;
}

.project-completion h4 {
  margin: 0px;
}

.line-height-zero h4 {
  line-height: 0;
}

.angular-ui-tree-handle {
  background: none repeat scroll 0 0 #f3f3f4;
  border: 1px dashed $white70;
  color: inherit;
  padding: 10px;
  font-weight: normal;
}

.angular-ui-tree-handle:hover {
  font-weight: bold;
  cursor: pointer;
}

.angular-ui-tree-placeholder {
  background: #f0f9ff;
  border: 1px dashed #bed2db;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.group-title {
  background-color: #687074 !important;
  color: $white !important;
}

.tree-node {
  background: none repeat scroll 0 0 transparent;
  padding: 10px;
  border-radius: 2px;
}

.tree-node-content {
  margin: 5px 5px 5px 0;
}

.tree-handle {
  background: none repeat scroll 0 0 #f3f3f4;
  border: 1px dashed $white70;
  color: inherit;
  padding: 10px;
}

.angular-ui-tree-handle:hover {
}

.angular-ui-tree-placeholder {
  background: #f0f9ff;
  border: 1px dashed #bed2db;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


.order-item__header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: $kbaseRem*0.25;

  @at-root #{&}--order-info {
    @include ktype(1, 0, 0);

    font-weight: 500;
  }

  @at-root #{&}--order-tags {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
  }
}

.order-item__details {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: $kbaseRem*0.5;
  border-bottom: $darkBorder;

  @at-root #{&}--info {
    width: 100%;
    padding: 0 $kbaseRem*0.5 $kbaseRem*0.25 0;

    @media screen and (min-width: $tablet) {
      width: calc(100%/3);
    }
  }
}

.unstyled {
  padding-left: 0;
  list-style: none outside none;
}

.ticket-danger {
  margin-bottom: 5px;
  line-height: 15px;
  vertical-align: middle;
  opacity: 0.9;
}

.full-width-multi {
  .input__select {
    @at-root #{&}__multi-value {
      min-width: 50% !important;
      justify-content: space-between;
    }
  }
}
