table.dataTable thead .sorting,
table.dataTable thead .sorting_asc::after,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  background: transparent;
}

.dataTables_wrapper {
  padding-bottom: 30px;
}

.dataTables_length {
  float: left;
}

body.DTTT_Print {
  background: $white;
}

button.DTTT_button,
div.DTTT_button,
a.DTTT_button {
  border: 1px solid $white70;
  background: $white;
  color: #676a6c;
  box-shadow: none;
  padding: 6px 8px;
}

button.DTTT_button:hover,
div.DTTT_button:hover,
a.DTTT_button:hover {
  border: 1px solid #d2d2d2;
  background: $white;
  color: #676a6c;
  box-shadow: none;
  padding: 6px 8px;
}

button.DTTT_button:hover:not(.DTTT_disabled),
div.DTTT_button:hover:not(.DTTT_disabled),
a.DTTT_button:hover:not(.DTTT_disabled) {
  border: 1px solid #d2d2d2;
  background: $white;
  box-shadow: none;
}
