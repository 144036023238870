@use "sass:math";

&__search {
  display: flex;
  align-items: center;
  position: relative;
  font-weight: inherit;
  border-radius: $borderRadius;
  border: 1px solid;
  border-color: transparent;
  background: $darkFive;

  &__icon {
    color: $white30;
    margin: 0 $kbaseRem * 0.5;
    width: 1em;
    height: 1em;
  }

  &:hover {
    border-color: $white30;
    transition: border-color 0.125s ease-in-out;
  }

  &:focus-within {
    border-color: $blue;
    transition: border-color 0.125s ease-in-out;
  }

  &--disabled {
    background-color: $white10;
    color: $white30;
    &:hover {
      border-color: $white10;
    }
  }

  .form-input__input {
    font-size: math.div($kbaseRem, 1.2);
    color: $white;
    background-color: transparent;
    border: none;
    height: $kbaseRem * 2;
    width: 100%;
    margin: 0;
    padding: 0;
    font-weight: inherit;
    appearance: none;
    outline: none;

    &:disabled {
      color: $white30;
    }
  }
}
