.invoice-breakdown {
  @at-root #{&}__section {
    margin-bottom: $kbaseRem;
    border-bottom: $darkBorder;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .breakdown-preview {
    width: 100%;

    @at-root #{&}--container {
      width: 100%;
      height: 70vh;
    }
  }

  .breakdown-list {
    padding: 0;
    list-style-type: none;
    line-height: $body-line-height;

    @at-root #{&}--item {
      padding: $kbaseRem*0.25 $kbaseRem*0.5;
      margin-bottom: $kbaseRem*0.5;
      border: $darkBorder;
      border-radius: $borderRadius;
      line-height: $body-line-height;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: stretch;

      @media screen and (min-width: $tablet) {
        flex-flow: row wrap;
        align-items: center;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      .action-buttons {
        width: 100%;
        margin: $kbaseRem*0.25 0;

        @media screen and (min-width: $tablet) {
          width: auto;
          margin: 0;
        }

        .button {
          margin-bottom: 0;
        }
      }

      .item--bold-white {
        color: $white;
        font-weight: 500;
      }
    }
  }
}

.rowContainerSpaceBetween {
  display: flex;
  padding: $kbaseRem*0.25 $kbaseRem*0.5;
  margin: 7px;
  border: 1.5px solid rgba(255,255,255,.5);
  border-radius: $borderRadius;
  line-height: 2;
  justify-content: space-between;

  &--red { border-color: red }
  &--green { border-color:$green }
  &--darkGreen { border-color: $darkGreen }
  &--orange { border-color:$orange }
  &--blue { border-color: blue }
  &--yellow { border-color:$yellow }
  &--purple { border-color:$purple }
  &--darkPurple { border-color:$darkPurple }
  &--default { border-color:$blue }
}

.rowContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
  padding: $kbaseRem*0.25 $kbaseRem*0.5;
  margin: 7px;
  border: 1.5px solid rgba(255,255,255,.5);
  border-radius: 10px;
  line-height: 2;

  .subcomponent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .subcomponentCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .subcomponent > div {
    margin-bottom: 4px;
  }

  &--red { border-color: red }
  &--green { border-color:$green }
  &--darkGreen { border-color: $darkGreen }
  &--orange { border-color:$orange }
  &--blue { border-color: blue }
  &--yellow { border-color:$yellow }
  &--purple { border-color:$purple }
  &--darkPurple { border-color:$darkPurple }
  &--default { border-color:$blue }
}