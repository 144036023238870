html {
  background-image: $darkGradient;
}

body {
  background-color: transparent;
}

html,
body {
  height: 100%;
}

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  min-height: 100vh;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
}

.content-area__wrapper {
  padding: $kbaseRem;
  padding-bottom: 0;
  flex: 1;
}

.no-padding {
  padding: 0;
}

.row {
  margin: initial;
}

.form-group {
  margin-bottom: 15px;
}

.success {
  color: $green;
}

.margin-auto {
  margin: 0 auto;
}

.align {
  @at-root #{&}--right {
    text-align: right;
  }

  @at-root #{&}--left {
    text-align: left;
  }

  @at-root #{&}--center {
    text-align: center;
  }
}

.max {
  @at-root #{&}-px400 {
    max-width: 400px;
    min-width: 400px;
  }

  @at-root #{&}-px600 {
    max-width: 600px;
    min-width: 600px;
  }

  @at-root #{&}-px800 {
    max-width: 800px;
    min-width: 800px;
  }

  @at-root #{&}-per80 {
    max-width: 80%;
    min-width: 80%;
  }

  @at-root #{&}-per60 {
    max-width: 60%;
    min-width: 60%;
  }

  @at-root #{&}-per50 {
    max-width: 50%;
    min-width: 50%;
  }

  @at-root #{&}-per33 {
    max-width: 100%/3;
    min-width: 100%/3;
  }

  @at-root #{&}-per25 {
    max-width: 25%;
    min-width: 25%;
  }
}

.trash-btn-sm {
  cursor: pointer;

  &:hover {
    color: $red;
  }
}