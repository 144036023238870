.invoice-list {
  .table-list__header-row,
  .table-list__body-block {
    grid-template-columns: none !important;

    @media screen and (min-width: $tablet) {
      grid-template-columns: 7% 7% 23% 23% 22% 10% 8% !important;
    }
  }

  @at-root #{&}__actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $kbaseRem;

    .button {
      margin-bottom: 0;
    }

    @at-root #{&}--buttons {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      margin-left: $kbaseRem;
    }
  }
}
