.role-switch-modal {
  @at-root #{&}__contents {
    width: 100%;

    @media screen and (min-width: $tablet) {
      width: 100%;
      margin: 0 auto;
    }

    @at-root #{&}--role-buttons {
      @media screen and (min-width: $tablet) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: stretch;

        .button--tall {
          width: 48%;
        }
      }
    }

    .button__text {
      flex-flow: column nowrap;
      white-space: initial;
    }

    @at-root #{&}--button-text-big {
      @include ktype(1, 0, 0.5, 1);
    }

    @at-root #{&}--button-text-small {
      margin-bottom: 0;
    }
  }
}
