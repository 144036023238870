.my-masonry-grid {
    display: flex;
    margin-left: -15px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 15px; /* gutter size */
    background-clip: padding-box;
  }
  .driver-grid-card {
    border: $darkBorder;
    border-radius: $borderRadius;
    margin-bottom: $kbaseRem;
  
    &__container-wrapper {
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-template-rows: auto;
      padding: $kbaseRem * 0.5;
      grid-template-areas:
        'icon head'
        'icon body';
      gap: $kbaseRem * 1, 5;
  
      &__icon-container {
        grid-area: icon;
        width: 100%;
        min-width: $kbaseRem * 4;
        margin-top: $kbaseRem;
        .icon-square {
          width: 100%;
          position: relative;
          overflow: hidden;
          &::after {
            content: '';
            display: block;
            padding-bottom: 100%;
          }
        }
        &--icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: auto;
          width: 100%;
          clip-path: circle();
        }
        &--status {
          position: absolute;
        }
        &__badges {
          margin: $kbaseRem*0.5 0;
        }
      }
  
      &__head {
        grid-area: head;
        display: grid;
        align-items: center;
        grid-template-columns: 1fr min-content;
        padding: $kbaseRem;
        padding-bottom: 0;
        grid-template-areas:
          'title'
          'subtitle';
  
        &__title {
          grid-area: title;
          font-size: 18px;
        }
  
        &__subtitle {
          grid-area: subtitle;
          margin-top: $kbaseRem;
        }
  
        &__actions {
          width: 30px;
          display: flex;
          flex-direction: column;
          top: 10px;
          position: inherit;
          right: 10px;
  
          .button,
          .dropdown-button,
          .input {
            margin: 0;
            width: 100%;
            .dropdown-button__list {
              width: 100%;
            }
          }
  
          & > *:not(:first-child) {
            margin: $kbaseRem*0.25 0 0 0;
          }
        }
      }
  
      &__body {
        grid-area: body;
        display: flex;
        padding-left: $kbaseRem;
  
        &__content-wrapper {
          display: flex;
          width: 100%;
          padding: $kbaseRem*0.5 0;
        }
  
        &__content {
          width: 100%;
          height: 100%;
          display: grid;
          grid-template-columns: 1fr;
          gap: $kbaseRem * 1.5;
  
          // Tablet
          @media screen and (min-width: $mobilePlus) {
            gap: $kbaseRem $kbaseRem * 2;
            @for $i from 1 through 15 {
              &--tablet-columns-#{$i} {
                grid-template-columns: repeat($i, auto);
              }
            }
          }
  
          // Desktop
          @media screen and (min-width: $tablet) {
            @for $i from 1 through 15 {
              &--columns-#{$i} {
                grid-template-columns: repeat($i, auto);
              }
            }
          }
        }
      }
    }
  
    &__more {
      text-align: center;
      .button {
        height: auto;
      }
    }
  
    &__spacer {
      content: '';
      width: $kbaseRem * 1.5;
      height: 100%;
      margin-right: $kbaseRem * 0.5;
    }
  
    &__toogle-body {
      display: flex;
      height: auto;
  
      &--toggle {
        overflow: hidden;
        height: 0%;
        max-height: 0px;
        transition: max-height 0.225s ease-in-out;
      }
  
      &__content-wrapper {
        display: flex;
        width: 100%;
        padding: $kbaseRem * 1.5 $kbaseRem;
        border-top: $darkBorder;
      }
  
      &__content {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
      }
    }
  }
  .open {
    display: initial;
  }