@use "sass:math";

.user-widget {
  background: $darkTwo;
  border-bottom: $darkBorder;
  padding: $kbaseRem;

  @media screen and (min-width: $tablet) {
    background: transparent;
    border-bottom: none;
    padding: $kbaseRem $kbaseRem*0.5;
    margin-right: $kbaseRem*0.5;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  @at-root #{&}__user-image-wrapper {
    width: $kbaseRem*2;
    height: $kbaseRem*2;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: $kbaseRem*0.5;

    @media screen and (min-width: $tablet) {
      margin-right: $kbaseRem*0.5;
      margin-bottom: 0;
    }
  }

  @at-root #{&}__user-image {
    width: 100%;
    max-height: 100%;
  }

  @at-root #{&}__user-info {
    position: relative;

    @at-root #{&}--name {
      font-size: 16px;
      font-weight: 500;
      color: $white;
    }

    @at-root #{&}--position {
      font-size: 12px;
    }

    @media screen and (min-width: $tablet) {
      flex-grow: 2;
      padding-right: $kbaseRem*1.5;
    }
  }

  .icon__custom--down {
    width: $kbaseRem*1.5;
    height: $kbaseRem*1.5;
    position: absolute;
    right: -$kbaseRem*0.75;
    top: $kbaseRem*0.75;
    will-change: transform;
    transform: rotate(-180deg);
    transition: transform 0.225s ease-out;
    cursor: pointer;

    @media screen and (min-width: $tablet) {
      bottom: math.div(-$kbaseRem, 6);
      right: math.div(-$kbaseRem, 6);
    }

    svg {
      fill: $white;
    }
  }

  @at-root #{&}__dropdown--hidden ~ .icon__custom--down {
    transform: rotate(0deg);
    transition: transform 0.225s ease-out;
  }

  @at-root #{&}__dropdown {
    margin: 0;
    padding: $kbaseRem 0 0;
    list-style-type: none;
    will-change: max-height, padding-top;
    height: auto;
    max-height: 200px;
    overflow: hidden;
    transition: max-height 0.225s ease-out, padding-top 0.225s ease-out, opacity 0.225s ease-out;
    pointer-events: all;

    @media screen and (min-width: $tablet) {
      will-change: opacity;
      opacity: 1;
      position: absolute;
      z-index: 3;
      top: 120%;
      left: 0;
      border-radius: 4px;
      background: $darkTwo;
      padding: $kbaseRem;
      width: $kbaseRem*10;
      box-shadow: $blockShadow;
    }

    a {
      font-weight: 500;
      color: $white70;
      cursor: pointer;

      &:hover {
        color: $blue;
      }
    }

    @at-root #{&}--item-link {
      font-weight: 500;
      color: $white70;
      cursor: pointer;

      &:hover {
        color: $blue;
      }
    }
  }

  @at-root #{&}__dropdown--hidden {
    padding-top: 0;
    max-height: 0;
    transition: max-height 0.225s ease-out, padding-top 0.225s ease-out, opacity 0.225s ease-out;
    pointer-events: none;

    @media screen and (min-width: $tablet) {
      height: auto;
      max-height: 100px;
      opacity: 0;
    }
  }
}
