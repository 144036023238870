.jobs-view-header {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  @at-root #{&}__name {
    overflow: hidden;
  }
}
