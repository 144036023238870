.label,
.badge {
  font-size: 12px;
  background-color: transparent;
  border: 1px solid;
  color: inherit;
  font-weight: 500;
  display: inline-block;
  text-shadow: none;
  margin: 0 $kbaseRem*0.25;
  border-radius: $kbaseRem;
  padding: $kbaseRem*0.2 $kbaseRem*0.5;
  min-width: 45px;
  cursor: inherit;
  text-align: center;

  &.label--sm,
  &.badge--sm {
    font-size: 11px;
    padding: 0;
    padding-left: 5px;
    padding-right: 5px;
  }

  &.label--margin-bottom,
  &.badge--margin-bottom {
    margin-bottom: $kbaseRem*0.25;
  }

  &.label--primary,
  &.badge--primary,
  &.label--blue,
  &.badge--blue {
    color: $blue;
  }

  &.label--success,
  &.badge--success,
  &.label--green,
  &.badge--green {
    color: $green;
  }

  &.label--darkGreen,
  &.badge--darkGreen {
    color: $darkGreen;
  }

  &.label--warning,
  &.badge--warning,
  &.label--orange,
  &.badge--orange {
    color: $orange;
  }

  &.label--danger,
  &.badge--danger,
  &.label--red,
  &.badge--red {
    color: $red;
  }

  &.label--info,
  &.badge--info,
  &.label--yellow,
  &.badge--yellow {
    color: $yellow;
  }

  &.label--purple,
  &.badge--purple {
    color: $purple;
  }

  &.label--grey {
    color: $lightGrey
  }

  &.label--inverse,
  &.badge--inverse {
    background-color: inherit;
    color: $white;
  }

  &.label--new-message,
  &.badge--new-message {
    background-color: $blue;
    color: $darkTwo;
    border-color: $blue;
  }

  &.label--filter {
    display: inline;
    border: none;
    background: $darkTwo;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;


    .icon--fill {
      fill: $white70;
      padding-bottom: 1px;
      margin-right: -3px;

      &:hover {
        fill: $red;
        cursor: pointer;
      }
    }
  }
}

.label {
  border-radius: 2px;
}

.label-primary,
.badge-primary {
  color: $blue;
}

.label-success,
.badge-success {
  color: $green;
}

.label-warning,
.badge-warning {
  color: $orange;
}

.label-danger,
.badge-danger {
  color: $red;
}

.label-info,
.badge-info {
  color: $yellow;
}

.label-inverse,
.badge-inverse {
  background-color: inherit;
  color: $dark;
}