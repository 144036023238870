.flex {
    display: flex;
}
.flexColumn {
    display: flex;
    flex-direction: column;
}

.flexCenter {
    display: flex;
    justify-content: center;
}
.flexStart {
    display: flex;
    justify-content: flex-start;
}
.flexEnd {
    display: flex;
    justify-content: flex-end;
}
.flexBetween {
    display: flex;
    justify-content: space-between;
}
.flexAround {
    display: flex;
    justify-content: space-around;
}
.flexEvenly {
    display: flex;
    justify-content: space-evenly;
}


.flexAlignCenter {
    display: flex;
    align-items: center;
}
.flexAlignStart {
    display: flex;
    align-items: flex-start;
}
.flexAlignEnd {
    display: flex;
    align-items: flex-end;
}
.flexAlignStretch {
    display: flex;
    align-items: stretch;
}
.flexAlignBaseline {
    display: flex;
    align-items: baseline;
}




.flexColumnBetween {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

///
.flexWrap {
    flex-wrap: wrap;
}
.flexNoWrap {
    flex-wrap: nowrap;
}
.noWhitespaceWrap {
    white-space: nowrap;
}

//text-align
.textCenter {
    text-align: center;
}
.textStart {
    text-align: start;
}
.textEnd {
    text-align: end;
}
.textLeft {
    text-align: left;
}
.textRight {
    text-align: right;
}


/////width
.width10 {
    width: 10%;
}
.width20 {
    width: 20%;
}
.width25 {
    width: 25%;
}
.width30 {
    width: 30%;
}
.width35 {
    width: 35%;
}
.width50 {
    width: 50%;
}
.width100 {
    width: 100%;
}

/////////
.flex10 {
    flex: 1 0 10%;
}
.flex20 {
    flex: 1 0 20%;
}
.flex25 {
    flex: 1 0 25%;
}
.flex30 {
    flex: 1 0 30%;
}
.flex50 {
    flex: 1 0 50%;
}
.flexBetweenBaseline {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.flexBetweenCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

//display

.displayNone {
    display: none;
}
.visHidden {
    visibility: hidden;
}
.transform0 {
    transform: scale(0);
}
.transform00 {
    transform: scale(0);
    height: 0;
    width:0;
}
